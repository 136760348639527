import React, { useState, useEffect,forwardRef,useImperativeHandle } from 'react'
import CourseItem from '../CourseItem'
import SeriesAdd from '../SeriesAdd'
import SeriesCourse from '../SeriesCourse'
import SearchCourse from '../SearchCourse'

import Exam from '../Exam'
import Examresults from '../Examresults'

import Exampack from '../Exampack'
import Examresultspack from '../Examresultspack'


import utils from '../../../../utils/index'

import './index.css'

const IndexComponent = forwardRef((props,ref) => {

  // searchCourse: 搜索课程   seriesCourse: 系列课程  seriesAdd: 系列添加  exam:考试
  let [groupChatModule, setGroupChatModule]= useState('seriesCourse')
  //let [groupChatModule, setGroupChatModule]= useState('exam')
  
  let [showexamlayer, setShowexamlayer]= useState(false)
  let [courseinfo, setCourseinfo]= useState([])
  let [packinfo, setPackinfo]= useState([])
  
  
  let [examqalist, setExamqalist]= useState([])
  let [state, setState]= useState(false)
  let [examresults, setExamresults]= useState(false)
  let [examresultspack, setExamresultspack]= useState(false)

  let [statexamstate, setStatexamstate]= useState(false)
  let [clocktimes, setClocktimes]= useState('')
  let [examtimes, setExamtimes]= useState(0)


  let [packinfodetail, setPackinfodetail]= useState({})
  let [packinfoquestion, setPackinfoquestion]= useState([])
  
 
  let timer = '';

  let [userqalist, setUserqalist]= useState({
    userqastate:0,
    userqacount:0,
  })
  

  let query = utils.getUrlParams()
  
  console.log(query)
  
  useEffect(() => {
    if(props.groupid){
      fetch(
        "https://shl-info.cn/front/groupchat/getCourseList?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        props.groupid +
        "&isallsurver=" +
        query.isallsurver  ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let packinfo = [];
            console.log()
            let datainfo =  res.data.datainfo;
              datainfo['showlayer'] = datainfo.userqastate;
              datainfo['state'] = 1
            setUserqalist(datainfo)
            // props.setUserqalist(datainfo)
            setExamqalist(res.data.questions)
            setCourseinfo(res.data.courseinfo)
            for(var i=0;i<res.data.packinfo.length;i++){
              console.log(res.data.packinfo[i])
              packinfo.push(res.data.packinfo[i])
              if(i==0){
                setPackidinfo(res.data.packinfo[i]);
              }
            }
            console.log(packinfo)
            setPackinfo(packinfo)


            props.changecourse(res.data.datainfo)
            setState(!state)
          }
        });
    }
    if(timer){
      console.log('timer')
      clearTimeout(timer)
    }
  }, [props.groupid])
 
  let [packidinfo, setPackidinfo]= useState({})
  let clickpackidinfo = (item,index) =>{
    props.changeissurclick()
    setPackidinfo(item);
    
    setGroupChatModule('seriesCourse')
    // setGroupChatModule('searchCourse')
   // setGroupChatModule('seriesCourse')
  }
  let changeagain = ()=>{
    userqalist['showlayer'] = '';
    console.log(userqalist);
    setUserqalist(userqalist)
    setState(!state)
  }
  let changeexamagin = ()=>{
    console.log(123123)
    let new_arr = examqalist
    for(var i= 0;i<new_arr.length;i++){
      new_arr[i].answer.forEach(item => {
          item.active = false;
      });
    }

    setExamqalist(new_arr)
    setExamresults(!examresults)
    userqalist['showlayer'] = '';
    console.log(userqalist);
    setUserqalist(userqalist)
    setState(!state)

    getgroupexamnew();
  }

  let getgroupexamnew = ()=>{
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseList?userid=" +
      localStorage.getItem('userid') +
      "&chatcode=" +
      props.groupid+
      "&isallsurver=" +
      query.isallsurver  ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setExamqalist(res.data.questions)
        }
      })
  }

  let handleClick = (type) =>{
    console.log(type)
    setGroupChatModule('seriesCourse');
  }
  useImperativeHandle(ref, () => ({
    handleClick
  }))

  // 修改考试
  let  changeExams = arr => {
    console.log(arr)
    setExamqalist(arr)
    setState(!state)
  };
  let changeExamspack = arr =>{
    setPackinfoquestion(arr)
    setState(!state)
  }
  let exitexam =()=>{
    console.log('exitexam')
    setStatexamstate(false)
    clearTimeout(timer);
  }
  let gostarexam = ()=>{
    console.log(userqalist.timelength)
    setExamtimes(userqalist.timelength)
    // setExamtimes(10)
    setStatexamstate(true)
  }
  let  gotoresult = ()=> {
    
    console.log(333)
    setExamresults(!examresults)
    props.changelist();
  }
  let gotoresultpack = ()=>{
    console.log(3333)
    setExamresultspack(!examresultspack)
  }

  let groupCourseSeriesItem = (item,index) => {
   
    let bi=0
    if(item.coursenum==0 || item.looknum==0 ){
      bi=0
    }else{
      bi = parseInt(item.looknum/item.coursenum*100);
    }
    return (
      <div className="groupCourseSeriesItem groupinfo" onClick={() =>clickpackidinfo(item,index) } >
        <div className="groupCourseSeriesItemTitle text-overflow">{item.packname}</div>
        <div className="groupCourseSeriesItemContent">
          <div className="groupCourseSeriesItemDetailItem">
            <img src={require('../../../../static/images/icon10.png')} alt="" className="groupCourseSeriesItemDetailItemIcon" />
            <div className="groupCourseSeriesItemDetailItemTitle">{item.coursenum}</div>
            {item.havesurver==1?<span className='groupCourseSeriesItemDetailItemstate coloritem'>{item.visitsruver==1?item.socore+"分":"考试未完成, 点击参与考试"}</span>:<span className='groupCourseSeriesItemDetailItemstate'>无考试</span>}
            
          </div>
          {/* <div className="groupCourseSeriesItemDetailItem">
            <img src={require('../../../../static/images/icon9.png')} alt="" className="groupCourseSeriesItemDetailItemIcon" />
            <div className="groupCourseSeriesItemDetailItemTitle">1个月</div>
          </div> */}
        </div>
        <div className="groupCourseSeriesItemSpeedProgressView">
          <div className="groupCourseSeriesItemSpeedPro">
            <div className="groupCourseSeriesItemSpeedProProp" style={{width:bi+"%"}}></div>
          </div>
          <div className="groupCourseSeriesItemSpeedProNum">{bi}%</div>
        </div>
      </div>
    )
  }
  

  let groupChatCourseItem = () => {
    return (
      <div className="groupChatCourseItem">
        <CourseItem>
          <div className="groupChatCourseTime">
            <img src={require('../../../../static/images/icon3.png')} alt="" className="groupChatCourseTimeIcon" />
            <div className="groupChatCourseTimeText">已观看06:26</div>
          </div>
        </CourseItem>
      </div>
    )
  }

  let changesurverylist = ()=>{
    console.log(packinfo)
    console.log(packidinfo)
    fetch(
      "https://shl-info.cn/front/groupchat/getCourseListByPackId?userid=" +
      localStorage.getItem('userid') +
      "&packcode=" +
      packidinfo.packcode ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){

          setPackinfoquestion(res.data.questions)
          // setExamqalist(res.data.questions)
        }
      })
  }
  let changepacklist = ()=>{
    console.log(1111)
    console.log(22222)

    fetch(
      "https://shl-info.cn/front/groupchat/getCourseList?userid=" +
      localStorage.getItem('userid') +
      "&chatcode=" +
      props.groupid +
      "&isallsurver=" +
      query.isallsurver ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          let packinfo = [];
          
          //setCourseinfo(res.data.courseinfo)
          for(var i=0;i<res.data.packinfo.length;i++){
            console.log(res.data.packinfo[i])
            packinfo.push(res.data.packinfo[i])
            // if(i==0){
            //   setPackidinfo(res.data.packinfo[i]);
            // }
          }
          console.log(packinfo)
          setPackinfo(packinfo)
        }
      });


  }
  let gopacksurvery = (packcode)=>{
    console.log(packcode)
    console.log(123123123)

    setGroupChatModule('packexam')
  }
  let groupChatSearchCourse = () => {
    return <SearchCourse setGroupChatModule={setGroupChatModule} history={props.history} changepacklist ={changepacklist} packidinfo={packidinfo} groupid={props.groupid}  closeFunc={() => setGroupChatModule('seriesCourse')}></SearchCourse>
  }

  
  let groupChatSeriesCourse = () => {
    return <SeriesCourse changepackinfo={changepackinfo} gopacksurvery={gopacksurvery} history={props.history} usertypes={props.usertypes} groupid={props.groupid } packidinfo={packidinfo} addCourseFunc={() => setGroupChatModule('searchCourse')}></SeriesCourse>
  }
  let groupChatSeriesAdd = () => {
    return (
      <SeriesAdd groupid={props.groupid} closeFunc={ () => seriesAddCloseFunc() }></SeriesAdd>
    )
  }

  let Examlist = () => {
    // console.log(props.groupid)
    if(examresults){
      return (
        <Examresults changeexamagin={changeexamagin} history={props.history} gotoresult= {gotoresult} change = {changeExams} userqalist={userqalist} groupid={props.groupid} examlist={examqalist} closeFunc={ () => seriesAddCloseFunc() }></Examresults>
      )
    }else{
      console.log('isexam')
      console.log(userqalist)
      return (
        <Exam exitexam={exitexam} userqalist={userqalist} changeagain={changeagain} history={props.history} userqacount={userqalist.userqacount} showlayer={userqalist.showlayer} userqastate={userqalist.userqastate} examtimes={examtimes} statexamstate = {statexamstate} gostarexam ={gostarexam} gotoresult= {gotoresult} change = {changeExams} groupid={props.groupid} examlist={examqalist} minsecond={userqalist.timelength} closeFunc={ () => seriesAddCloseFunc() }></Exam>
      )
    }
  
  }
  let changeexam = () =>{
    let questionsnew = packinfoquestion
    console.log(questionsnew)
    if(questionsnew && questionsnew.length>0){
      for(var i=0;i<questionsnew.length;i++){
          if(questionsnew[i].answer && questionsnew[i].answer.length>0){
            for(var k=0;k<questionsnew[i].answer.length;k++){
                console.log(questionsnew[i].answer[k])
                questionsnew[i].answer[k]['active'] = false
            }
          }
      }
    }
   
    setPackinfoquestion(questionsnew)
    setExamresultspack(!examresultspack)
    changesurverylist();
  }
  let Packexam = ()=>{
    if(examresultspack){
      return (
        <Examresultspack changeexam={changeexam} packid={packidinfo.packid} history={props.history} gotoresult= {gotoresult} change = {changeExams} groupid={props.groupid} examlist={examqalist} closeFunc={ () => seriesAddCloseFunc() }></Examresultspack>
      )
    }else{
      return (
        <Exampack packidinfo={packidinfo} packinfodetail={packinfodetail} history={props.history} userqacount={userqalist.userqacount} examtimes={packinfodetail} gotoresult= {gotoresultpack} change = {changeExamspack} groupid={props.groupid} examlist={packinfoquestion} closeFunc={ () => seriesAddCloseFunc() }></Exampack>
      )
    }
    
  }
  let changepackinfo = (datainfos,questions)=>{
    console.log(datainfos,questions)

   

    setPackinfodetail(datainfos)
    props.changecoursepack(datainfos)
    setPackinfoquestion(questions)
  }
  
  let seriesAddCloseFunc = () => {
    setGroupChatModule('seriesCourse')
    changepacklist();
  }

  
  let groupChatModuleComp = {
    searchCourse: groupChatSearchCourse,
    seriesCourse: groupChatSeriesCourse,
    seriesAdd: groupChatSeriesAdd,
    exam:Examlist,
    packexam:Packexam,
  }
  let certificate = ()=>{
    props.history.push("/certificategroup?type="+props.groupid );
  }
  let convertSecondsToMinutesSeconds = (totalSeconds) => {  
    var minutes = Math.floor(totalSeconds / 60);  
    var seconds = totalSeconds % 60;  
  
    minutes = minutes < 10 ? "0" + minutes : minutes;  
    seconds = seconds < 10 ? "0" + seconds : seconds;  
  
    return minutes + ":" + seconds;  
}  
  useEffect(() => {  
  
    if (examtimes > 0) {  
       timer = setTimeout(() => {  
        let Secondstime  =convertSecondsToMinutesSeconds(examtimes - 1); 
        setClocktimes(Secondstime)
        setExamtimes(examtimes - 1); 
      }, 1000);  
  
      return () => clearTimeout(timer); // 清除定时器，避免在组件卸载后继续运行  
    }else{
      return () => clearTimeout(timer); 
    }
  }, [examtimes]);  

  useEffect(() => {  
    console.log(userqalist)
    if (props.issurclick && userqalist.state==1) {  
      if(userqalist.isexam==1 && ( (userqalist.islookover==1 && userqalist.condition==1) ||  userqalist.condition==0 )){
        setGroupChatModule('exam')
        props.changeissurclick()
      }else{
        console.log(1111)
        setShowexamlayer(true);
        props.changeissurclick()
      }
      // setGroupChatModule('exam')
    }
  }, [props.issurclick,userqalist]);  
  let gochange = ()=>{
    setShowexamlayer(false);
  }
  useEffect(() => {  
    props.changegroupChatModule(groupChatModule)
  }, [groupChatModule]);  
  return (
    <div className="groupCourse">
      {showexamlayer?<div className="common-mask userlayer">
            <div className="common-maskbox " >
                <h2 className="common-masktitle">
                西影力学院
                    {/* <span
                        className="common-maskclose cursor"
                        onClick={() => closeMask()}></span> */}
                </h2>
                <div className="common-masktext-surbox">
                   <p className='userlayertext'> 按照本群组要求，请您先完成全部课程学习再参与考试</p>
                    <div className="masksurbox-btnlist">
                        <span className="changebtn" onClick={()=>gochange()}>确认</span>
                    </div>
                   
                </div>
            </div>
        </div >:null}
      <div className="groupCourseLeft scrollbar">
        <div className={props.skipnum ==6 && props.showtipslayer?"addbox":""}>
          <div className={userqalist.isexam==1 ?"groupCourseSeriesHead":"none"}>
            {userqalist.isexam==1 && ( (userqalist.islookover==1 && userqalist.condition==1) ||  userqalist.condition==0 )?<div className="groupCourseSeriesTitle cursor text-overflow"  onClick={() =>{ setGroupChatModule('exam');}} >考试{statexamstate && clocktimes?<span className="examtimes">{clocktimes}</span>:null}</div>
            :<div className="groupCourseSeriesTitle cursor active text-overflow" onClick={()=>setShowexamlayer(true)}  >考试</div>}
          </div>
        </div>
        <div className={props.skipnum ==7 && props.showtipslayer?"addbox":""}>
          <div className={userqalist.isexam==1 ?"groupCourseSeriesHead":"none"}>
          {(userqalist.userqacount>=80 && userqalist.grouptypes != 4)  || (userqalist.userqacount>=userqalist.passnum && userqalist.grouptypes == 4) ?<div className="groupCourseSeriesTitle cursor text-overflow"  onClick={() => certificate()} >证书</div>
            :<div className="groupCourseSeriesTitle cursor active text-overflow"  >证书</div>}
          </div>
        </div>
        <div className={props.skipnum ==3 && props.showtipslayer?"addbox":""}>
          <div className="groupCourseSeriesHead">
            <div className="groupCourseSeriesTitle cursor text-overflow"   onClick={() =>{ setGroupChatModule('seriesCourse');props.changeissurclick()}} >群组必修课程</div>
            {props.usertypes==1 || props.usertypes==2 ||props.usertypes==4? <img src={require('../../../../static/images/icon8.png')} alt="" className="groupCourseSeriesAdd" onClick={() => setGroupChatModule('seriesAdd')} />:null }
          
          </div>
          <div className="groupCourseSeriesBody">
            {packinfo.map((item,index)=>{
              return groupCourseSeriesItem(item,index)
            })}
            {/* { groupCourseSeriesItem() }
            { groupCourseSeriesItem() }
            { groupCourseSeriesItem() } */}
          </div>
        </div>
      </div>
      
      <div className={props.skipnum ==4 && props.showtipslayer?"groupCourseRight scrollbar addbox":props.skipnum ==5 && props.showtipslayer?"groupCourseRight scrollbar addboxcourse":"groupCourseRight scrollbar"}>
        { groupChatModuleComp[groupChatModule]() }
      </div>
    </div>
  )
});

export default IndexComponent;
