import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import MaskBox from '../maskbox';

class Coursedetail extends Component {
  constructor(props) {
    super(props)
    this.closeMask = this.closeMask.bind(this);
    this.state = {
      typedownarr: 0,
      ismask: false,
      downmask: false,
      masktext: '请选择要下载的内容',
      information:{

      }
    }
  }
  loveClick(index) {
    let new_arr = this.props.coursedata;
    new_arr[index].active = !new_arr[index].active;
    let numbers = new_arr[index].active ?
      this.state.typedownarr + 1 :
      this.state.typedownarr - 1
    this.setState({
      typedownarr: numbers
    })
    this.props.change(new_arr);
  }

  godownload = (id, item) => {
    console.log(id, item)
    let that = this
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/sendfile?userid=" +
        this.props.userinfo.userid +
        "&ids=" +
        id,
      dataType: "json",
      headers: { 'token': this.props.userinfo.token },
      cache: false,
      success: res => {
        if (res && res.status !== 2) {
          that.setState({
            downmask: true
          });
        }
      }
    });
  }
  gotoDown() {
    if (this.state.typedownarr < 1) {
      this.setState({
        ismask: true,
        masktext: "请选择要下载的内容"
      });
    } else {
      if (!this.filefalg) {
        return false;
      }
      this.filefalg = false;
      let values = [];
      let new_arr = this.props.coursedata;
      new_arr.forEach(item => {
        if (item.active) {
          values.push(item.id);
          item.active = false;
        }
      });
      $.ajax({
        type: "GET",
        url:
          "https://shl-info.cn/front/frontc/sendfile?userid=" +
          this.props.userinfo.userid +
          "&ids=" +
          values.join(","),
        dataType: "json",
        headers: { 'token': this.props.userinfo.token },
        cache: false
      }).then(res => {
        if (res && res.status !== 2) {
          this.setState({
            ismask: true,
            masktext: "资料已经发送到您邮箱，请查收！",
            typedownarr: 0
          });
          this.props.hidevideo(true);
          this.props.change(new_arr);
          this.filefalg = true;
        }
      });
    }
  }
  closeMask() {
    this.setState({
      ismask: false,
      downmask: false,
    })
  }
  componentDidMount() {
    this.filefalg = true;
    let nums = 0;
  
    this.setState({
      typedownarr: nums
    })
  }
  componentWillUnmount() {

  }
  render() {
    console.log(this.props.information)
    return (
      <div className="coursedetail-scroll">
        <div className="wrap">
          <h4 className="com-course-title">
            {this.props.information.title}
              
          </h4>

          {this.props.information?this.props.information.tips?this.props.information.tips.length > 0 ? 
            <ul class="classlist-label com-classlist-label">
            <li>核磁共振</li>
            {this.props.information.tips.map((item,index)=>{
              return   <li key={"informationtip"+index}>{item.text}</li>
            })}
            </ul>:null:null:null}
            <div className="overflow com-course-textlist">
              <p className="course-time com-course-text">{this.props.information.time}</p>
             {this.props.information.teacher?<p className="course-teacher com-course-text">{this.props.information.teacher}{this.props.information.location?"-"+this.props.information.location:""}</p>:null}
             {this.props.information.teachercompany?<p className="course-type com-course-text">{this.props.information.teachercompany}</p>:""}


             
             {this.props.information.en ?<p className="course-lang com-course-text">{this.props.information.en}</p>:null}

                
            {this.props.information.isscorm?<div className="course-tips-text icon-computer_pep">电脑端建议使用Chrome浏览器进行学习</div>:null}

            {this.props.information.isscorm?<div className="course-tips-text icon-phone_pep">手机端仅支持安卓手机的Chrome浏览器学习</div>:null}       
             
            </div>

            <div className='detail_contentbox'>
              <h5>课程介绍</h5>
              <div dangerouslySetInnerHTML={{ __html: this.props.information.value }} className='newp'></div>
            </div>
            
        </div>
      </div>
    )
  }
}

export default Coursedetail;
