import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Swiper from 'swiper'

import 'swiper/css/swiper.min.css'

import './index.css'

import CLassLabel from '../../components/classlabel'

class LayoutClassmore extends Component {
    constructor() {
        super()

        this.state = {
            swiper: null
        }
    }
    swiperInit() {
        let that = this;
        that.setState({
            swiper: new Swiper('.swiper-class', {
                loop: false,  //循环
                speed: 500,
                resistanceRatio: 0,
                autoplay: {   //滑动后继续播放
                    disableOnInteraction: false,
                }
            })
        })
    }
    componentDidUpdate() {
        if (!this.state.swiper) { return false }
        this.state.swiper.update()
    }
    componentDidMount() {
        console.log(this.props.title)
        console.log("this.props.title")
        //console.log(this.props)
        if (!this.props.swiper) { return false }
        this.swiperInit();
    }
    componentWillUnmount() {
        if (!this.props.swiper) { return false }
        if (this.state.swiper.length > 0) {
            for (var i = 0; i < this.state.swiper.length; i++) {
                this.state.swiper[i].destroy();
            }
        } else {
            this.state.swiper.destroy();
        }
    }
    render(){
     
        console.log(this.props.morebtn)
        // 截取轮播课程
        let new_list = [];
        for (var i = 0; i < this.props.list.length; i++){
            if(i%3 === 0){
                new_list.push([]);
            }
            new_list[new_list.length - 1].push(this.props.list[i])
        }
        let newhtml=null;
        console.log(this.props.list)
        if (this.props.list){
            console.log(123123)
            console.log(this.props._item)
            console.log(this.props.list)
            newhtml = this.props.list.map((item, index) => {
                console.log(item)
                console.log(444444444)
                let typenums = '';
                if(this.props._item.types==1){
                    typenums =6
                }
                if(this.props._item.types==2){
                    typenums =7
                }
                if(this.props._item.types==3){
                    typenums =8
                }
                if(this.props._item.types==4){
                    typenums =9
                }
                console.log(typenums,this.props._item.types)
                let morebtn = null;
                let gourl = "/homeback?secindex=&series="+item.id+"&thistypenums="+typenums
                if (this.props.morebtn) {
                    morebtn = (
                        <div className="common-more addnews1">
                            <Link to={gourl}>
                                查看更多
                    <img src={require('../../static/images/moreIcon.png')} alt="" />
                            </Link>
                        </div>
                    )
                }
                let newsechtml = null
                if(item.seriescourselist){
                     newsechtml = item.seriescourselist.map((item2, index2) => {
                        return(
                                <CLassLabel
                                    key={'class' + index2}
                                    _item={item2}></CLassLabel>
                        )
                    })
                }
                if(item.seriescourselist){
                    if(item.seriescourselist.length>0){
                        return (
                            <div key={index} className='morlayerclass' >
                                <h3 className="common-smalltitle">{item.name}</h3>
                                <div className="common-floatsmall">
                                        <div className="swiper-class addnewclass">
                                            <div className="swiper-wrapper">
                                            <div key={index} className="swiper-slide">
                                                {newsechtml}
                                            </div>
                                            </div>
                                        </div>
                                    {morebtn}   
                                </div>
                            </div>
                        ) 
                    }
                }
                
            })
        }
        console.log(this.props.new_list)
        return (
            <section className="common-action">
                <div className="wrap">
                    <h3 className="common-title">{this.props.title}</h3>
                    {newhtml}
                    {/* <h3 className="common-smalltitle">{this.props.title}</h3>
                    <div className="common-floatsmall">
                        {
                            this.props.swiper ? (
                                <div className="swiper-class">
                                    <div className="swiper-wrapper">
                                        {
                                            new_list.map((item, index) => {
                                                return (
                                                    <div key={index} className="swiper-slide">
                                                        { item }
                                                    </div>
                                                ) 
                                            })
                                        }
                                    </div>
                                </div>
                            ) : this.props.list
                        }
                    </div> */}
                    {/* {morebtn} */}
                </div>
            </section>
        )
    }
}

export default LayoutClassmore
