import React from 'react'

import './index.css'
import { Link } from 'react-router-dom'

import {withRouter} from 'react-router-dom'
import groupDetail from '../../routes/groupDetail'


function MaskBox(props) {
    console.log(props)
    const godetail = (id) =>{
        console.log(id)
        if(id==1){
            props.closeMask()
            props.history.push('/addaccount?type=1')
        }
        if(id==3){
            props.closeMask()
            props.history.push('/homeperson')
        }
    }
    return (
        <div className="common-mask">
            <div className={props.info.type == 'signfirst' ? "common-maskbox addtextmore addtextmoretext" :props.info.goglinsstate?"common-maskbox  goglinsstate":props.isshowtipslayer?"common-maskbox addtextmore": "common-maskbox "}>
                <h2 className="common-masktitle">
                    {props.info.title}
                    <span
                        className="common-maskclose cursor"
                        onClick={() => props.closeMask()}></span>
                </h2>

                <div className="common-masktext">

                    {props.info.type == 'surver' ? (
                        <p className='surverbtnbox' onClick={() => props.gotosurverurl()}>{props.info.text}</p>
                    ) : props.info.type == 'sign' ? (
                        <div className='addtextnewbox'>
                            <p><span>{props.info.textnew}</span> {props.info.text}</p>
                            {/* <div className='addtextnewbox_title'>完成课程赚积分 赢好礼</div> */}
                        </div>
                    ) : props.info.type == 'signfirst' ? (
                        <div className='addtextnewbox '>
                            <div className='addtextnewbox_title'>{props.info.text}</div>
                            <ul className='addlist newscore'>
                                <li onClick={()=>godetail(1)}>
                                    {/* <div className='addlist_icon'><img src={require('../../static/images/mall/mall_layer.png')} /></div>
                                    <div className='addlist_title'>课程完成</div>
                                    <div className='addlist_text'>观看直播/录播可领积分</div>
                                    <div className='addlist_nums'>+2</div> */}

                                    <div className='addlist_title'>注册积分并信息完善积分</div>
                                    <div className='addlist_text'>完善个人邮箱手机等身份信息</div>
                                    <div className='addlist_nums'>+20</div>
                                    <div className="addlist_btnnew">去完成{">"}</div>
                                </li>
                                <li>
                                    {/* <div className='addlist_icon'><img src={require('../../static/images/mall/mall_layer.png')} /></div>

                                    <div className='addlist_title'>完成考试</div>
                                    <div className='addlist_text'>完成考试即可领积分</div>
                                    <div className='addlist_nums'>+2</div> */}



                                    <div className='addlist_title'>邀请新注册用户（仅微信环境）</div>
                                    <div className='addlist_text'>成功邀请好友注册获取积分</div>
                                    <div className='addlist_nums'>+100</div>
                                </li>
                                <li onClick={()=>godetail(3)}>
                                    {/* <div className='addlist_icon'><img src={require('../../static/images/mall/mall_layer.png')} /></div>

                                    <div className='addlist_title'>完成必修课程</div>
                                    <div className='addlist_text'>完成必修课程即可领积分/录播可领积分</div>
                                    <div className='addlist_nums'>+2</div> */}


                                    <div className='addlist_title'>每日签到</div>
                                    <div className='addlist_text'>完成每日签到即可领积分</div>
                                    <div className='addlist_nums'>+1</div>
                                    <div className="addlist_btnnew">去完成{">"}</div>
                                </li>
                            </ul>
                            {/* <div className='addtextnewbox_title'>完成课程赚积分 赢好礼</div> */}
                        </div>
                    ) : props.isshowtipslayer?(
                       <>
                        <div className='showprvcodebox'>
                            <div className='showprvcodeboxtitle'>请使用手机扫下方二维码进行查看</div>
                            <div className='showprvcodeboxcode'><img src={require('../../static/images/privatehospitalcode.png')} /></div>
                        </div>
                        </>
                    ):(
                           <p style={{ whiteSpace: 'pre-wrap' }}> {props.info.text}
                           
                            {props.info.goglinsstate ?<div className="gojumpurls goglinsstate" ><span className="goreg" onClick={()=>props.goreg()}>注册</span><span   className="gologin" onClick={()=>props.gologin()}>登录</span></div>:""}

                             {props.info.golivestate ?<div className="gojumpurls" onClick={()=>props.gotoliveurl()}><span>点击跳转</span></div>:""}
                           
                           </p>
                                    
                              )
                    }

                </div>
            </div>
        </div >
    )
}

export default withRouter(MaskBox)
