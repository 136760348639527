import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import $ from 'jquery'
import './index.css'
import { encrypt, decrypt } from '../../utils/crypto.ts'

import  fetchStream  from './fetchStream.ts'
import  handleUint8Array  from './handleUint8Array.ts'
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// import 'github-markdown-css';
// import markdownit from 'markdown-it'


import ReactMarkdown from 'react-markdown'
// import  textAnimation  from './TextAnimation.tsx'

// import ReactMarkdown from 'react-markdown';


// import MarkdownRenderer from '../../components/markdown';

// let HyperDown = require('hyperdown');

// var parser = new HyperDown;

var text='';
class WatchHistory extends Component {
  constructor() {
    super()
    // this.autoReturn = this.autoReturn.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.valueChangetext = this.valueChangetext.bind(this);
    
    this.state = {
      showlanding:localStorage.getItem('showlanding'),
      isclick:-1,
      checkGrade:false,

      iscroll: null,
      grouplist:[],
      seachinput:'',
      asklist:[],
      cancreate:false,
      startanswer:true,
      errornum:0,
      hoveenum:'',
      userchangetype:0,
      surverycontent:'',

      messageids:0,
      chatDataRef:{
        current:{
         source_documents:[],
          content:'',
        }
      },

      talklist:[{
        userid:'0',
        username:'西影力学院智能助手',
        headimg:'',
        message:"您好，欢迎来到西影力学院，我是智能助手，很高兴为您服务，您可以这样提问：\r\n<p class='ainfo' info='推荐肝癌相关课程'>推荐肝癌相关课程</p><p class='ainfo' info='推荐卒中相关课程'>推荐卒中相关课程</p><p class='ainfo' info='推荐肺癌相关课程'>推荐肺癌相关课程</p>",
        // message:"您好，欢迎来到西影力学院，我是智能助手，很高兴为您服务，您可以这样提问：\r\n<p class='ainfo' info='推荐肝癌相关课程'>推荐肝癌相关课程</p><p class='ainfo' info='推荐卒中相关课程'>推荐卒中相关课程</p><p class='ainfo' info='推荐肺癌相关课程'>推荐肺癌相关课程</p><p class='ainfo' info='猜我喜欢'>猜我喜欢</p>",
        source_documents:[],
        type:2,
      }],
      loadingstate:false,
      answerover:true,
    }
  }
  
  godetail = (id)=>{
    //console.log(123123123)
  }
  valueChangetext(e){
    this.setState({
      surverycontent:e.target.value
    })
    //console.log(e.target.value)
  }
  valueChange(e){
    if(this.state.loadingstate){
      return false;
    }
    this.setState({
      seachinput:e.target.value
    })
    //console.log(e.target.value)
  }
  refresh() {
    let that = this
    // setTimeout(() => { 
    //   //console.log(123123123)
    //   $('.aitalk_talkbox')[0].scrollTo({top:999999, left:0})
    // }, 1000)
  }
  // 每次数据更新刷新iscroll
  componentDidUpdate() {
    this.refresh()
  }
  componentDidMount() {
    // this.props.change_active_two('watchhistory')
    // this.props.change_person_historydata()
    
    $('.aicontent').css('height',($(window).height()-260) +"px")
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    if (userid && token) {
      // 验证是否过期
      $.ajax({
          type: "GET",
          url: "https://shl-info.cn/front/user/checkuser?userid=" +
              userid ,
          dataType: "json",
          headers:{'token':token},
          success: function (res) {
            if(res.list){
              localStorage.setItem('headimg', res.pic)
              localStorage.setItem('names', decrypt(res.name))
              localStorage.setItem('name', decrypt(res.name))
            }else{
              localStorage.setItem('refurl', window.location.href);
              this.props.history.push('./loginnew')
            }
          }
      });
      let that=this
     
      // $.ajax({
      //   type: "GET",
      //   url: "https://shl-info.cn/front/Smartremember/my_role?userid=" +
      //     userid ,
      //   dataType: "json",
      //   headers:{'token':token},
      //   success: function (res) {
      //     //console.log(res)
      //     if(!res.data || res.data.user_type !=1){
      //       that.props.history.push('./home')
      //     }
      //   }
      // });

      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/ai/checkGrade?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          //console.log(res)
          if(res.isgrade==1){
            that.setState({
              checkGrade:true
            })
          }
        }
      });


    }else{
      localStorage.setItem('refurl', window.location.href);
      this.props.history.push('./loginlist')
    }

    //console.log(this)
    let that = this
    $('.ainfo').click(function(){
      //console.log($(this).index())
      let thisindex = $(this).index()
      if(thisindex>2){
        thisindex=-1
      }
      that.setState({
        seachinput:$(this).attr('info'),
        isclick:thisindex,
      },()=>{
        that.sendmessage();
      })
      // //console.log($(this).index())
    })
    //this.getliumessage();
    // this.fetchChat();
  }
  // onMessage = (data: any) => {
  //   //console.log(11111)
  // }
  // onDone() {
  //  //console.log(22222)
  // }
  // onError() {
  //  //console.log(33333)
  // }

  // fetchChat =   () => {
  //   const response  =  fetch(`https://api.lookstar.com.cn/tenant/ai/gpt/conversation`,
  //     {
  //       method: 'POST',
  //       body: JSON.stringify({"message":"介绍一下你自己","chat_history":[],"streaming":true,"project_id":"77","openid":""}),
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'X-Tenant': localStorage.getItem('tenant'),
  //       },
  //     });

  //   if (response.status < 200 || response.status >= 300) {
  //     //console.log(111111)
  //     return;
  //   };

  //   const reader = response.body.getReader();
  //   while (true) {
  //     const { value, done } =  reader.read();

  //     if (done) {
       
  //     //console.log(333333)
  //       break;
  //     };
  //     //console.log(222222)
  //   };
  // };

  // fetchStream = (url: string, options?: any) => {
  //   url = "https://api.lookstar.com.cn/tenant/ai/gpt/conversation";
  //   let data = {"message":"介绍一下你自己","chat_history":[],"streaming":true,"project_id":"77","openid":""};
  //   options={
  //     data:data
  //   }
  //   return new Promise<void>(async (resolve, reject) => {
  //     //console.log(options)
  //     //console.log(3333)
  //     const { data, body, ...currentOptions} = options;
  //     const response: any = await fetch(url,
  //       {
  //         method: 'POST',
  //         body: JSON.stringify(data) || JSON.stringify(body),
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'X-Tenant': "8945f594-8402-4039-879b-523c85bf3ca1",
  //         },
  //         ...currentOptions,
  //       });
  
  //     if (response.status < 200 || response.status >= 300) {
  //       // onError?.();
  //       reject();
  //       return;
  //     };
  
  //     const reader = response.body.getReader();
  //     while (true) {
  //       const { value, done } = await reader.read();
  
  //       if (done) {
  //         // onDone?.();
  //         resolve();
  //         break;
  //       };
  //       //console.log(new TextDecoder().decode(value))
  //       // onMessage?.(new TextDecoder().decode(value));
  //     };
  //   });
  // }

  // onMessage = (data: any) = > {
  //   let  data = {
  //     content: `${chatDataRef.current?.content ? chatDataRef.current?.content + ',' : ''}${data.result || ''}`,
  //     source_documents: data.source_documents,
  //   };
  // }
  changeids = (key,item) =>{
    console.log(key,item)
    if(this.state.talklist[key]['message'].indexOf('无法回答这个问题')>=0 ){
      // if(this.state.talklist[key]['message'].indexOf('无法回答这个问题')>=0 || this.state.talklist[key]['message'].indexOf('抱歉')>=0){
      return ;
    }

    

    // let ids = '';
    // for(var i=0;i<item.length;i++){
    //   // if(ids.indexOf(item[i]['metadata']['id'])<0){
    //   //   ids = ids+item[i]['metadata']['id']+',';
    //   // }
    //   if(ids.indexOf(item[i]['data_id'])<0){
    //     ids = ids+item[i]['data_id']+',';
    //   }
    // }
    // ids = ids.substring(0, ids.length - 1)

    let ids = [];
    for(var i=0;i<item.length;i++){
      // if(ids.indexOf(item[i]['metadata']['id'])<0){
      //   ids = ids+item[i]['metadata']['id']+',';
      // }
      let tmpids = {
        id:item[i]['data_id'],
        type:item[i]['data_type']
      }
      ids.push(tmpids)
      // if(ids.indexOf(item[i]['data_id'])<0){
      //   ids = ids+item[i]['data_id']+',';
      // }
    }
    // ids = ids.substring(0, ids.length - 1)
    //console.log(this.state.isclick,ids) 
    if(this.state.isclick>=0 && ids){
      // ids = this.state.isclick==0?'3355,3373,3374':this.state.isclick==1?"2795,2908,3375":"1986,1989,2023";

      console.log(3333)
      ids = this.state.isclick==0?[{id:2931,type:"course"},{id:3011,type:"course"},{id:2600,type:"course"}]:this.state.isclick==1?[{id:2439,type:"course"},{id:2556,type:"course"},{id:2664,type:"course"}]:[{id:1568,type:"course"},{id:1565,type:"course"},{id:1602,type:"course"}];

      // //console.log(ids)
    }
    if(!ids){
      return
    }
    console.log(ids)
    // //console.log(ids) 
    fetch(
      "https://shl-info.cn/front/ai/check_source?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          ids:ids,
          // isclick:this.state.isclick,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      //console.log(res)
      if(res.data.length>0){
        let tmp = [];
        // for(var i=0;i<item.length;i++){
        //   for(var k=0;k<res.data.length;k++){
        //     if(res.data[k]==item[i]['metadata']['id']){
        //       tmp.push(item[i])
        //     }
        //   }
        // }
        // if(tmp.length>0){
          let talklist = this.state.talklist
          talklist[key]['source_documents'] = res.data
          this.setState({
            talklist,
            isclick:-1,
          },()=>{
            //console.log(this.state.talklist)
            setTimeout(() => { 
              $('.aitalkbg')[0].scrollTo({top:999999, left:0})
            }, 100)
          })
        // }
      }
    })
  }

   jsonParse = (val, defaultVal) => {
    if (!val || typeof val !== 'string') {
      console.warn('JSON.parse need string param');
      return defaultVal;
    }
    try {
      return JSON.parse(val);
    } catch (e) {
      return defaultVal;
    }
  }
  getliumessage = () =>{
    this.setState({
      startanswer:false,
    })
    //console.log(this.state.asklist)
    let asklist = this.state.asklist
    asklist = asklist.slice(0, -1); 
    //console.log(asklist)
   // let data = {"message":"介绍一下你自己","chat_history":[],"streaming":true,"project_id":"77","openid":""};
    //fetchStream("https://api.lookstar.com.cn/tenant/ai/gpt/conversation",{
    fetchStream("https://shl-info.cn/front/Ai/message?userid="+localStorage.getItem("userid"),{
      data:{
        message:this.state.seachinput?this.state.seachinput.replace(/\n/g,""):"",
        chat_history:asklist,
        userid:localStorage.getItem('userid'),
      },
      //  data:data,
    },(res)=>{
      //console.log(res)
      //console.log(11111)
      
      if(text){
        text = text+','+res.result

        
        let infos = handleUint8Array(text);
        
       let newtext =infos.text.replace(",]","]")
       //console.log(newtext)
       //console.log(this.jsonParse(newtext))
       let showtext = ''
       
       let talklist = this.state.talklist
       if(this.jsonParse(newtext)){
          showtext = ''
          let newtextarr = this.jsonParse(newtext)
          for(var i=0;i<newtextarr.length;i++){
              //console.log(newtextarr[i])
              if(newtextarr[i]['role']=="assistant"){
                showtext = showtext+newtextarr[i]['content']
              }
              if(newtextarr[i]['code']=="2000"){
                // console.log(newtextarr[i]['data'])
                this.setState({
                  messageids:newtextarr[i]['data']
                })
              }

             
          }

          
          showtext = showtext.replace(/,,/g,"\n")
          talklist[talklist.length-1]['message'] = showtext.substring(0, showtext.length - 1)
          talklist[talklist.length-1]['source_documents'] =   []
       }

        // let showtext = infos.text
     
        
        if(infos.list.length>0  || this.state.isclick>=0){
          this.changeids(talklist.length-1,infos.list);
        }
        ////console.log(talklist)
        this.setState({
          talklist:talklist,
          loadingstate:true,
          answerover:false,
          errornum:0,
        },()=>{
          setTimeout(() => { 
            $('.aitalkbg')[0].scrollTo({top:999999, left:0})
          }, 100)
        })
      }else{
        text = text+res.result
        let infos = handleUint8Array(text);
        
        //console.log(infos)
        // let showtext = infos.text


        let newtext =infos.text.replace(",]","]")

        let showtext = "";
        let talkinfo={
          userid:'0',
          username:'西影力学院智能助手',
          headimg:'',
          message:showtext,
          source_documents:[],
          type:2,
        }
        ////console.log(talkinfo)
        let talklist = this.state.talklist
        talklist.push(talkinfo)
        if(infos.list.length>0 || this.state.isclick>=0){
          this.changeids(talklist.length-1,infos.list);
        }
        this.setState({
          talklist:talklist,
          loadingstate:true,
          answerover:false,
          errornum:0,
        })
      }
      setTimeout(() => { 
        $('.aitalkbg')[0].scrollTo({top:999999, left:0})
      }, 100)
      ////console.log(handleUint8Array(text))


    }).then((res)=>{
      console.log(res)
      console.log(22222)
      let infos = handleUint8Array(text);


      let showtext = infos.text
      let talklist = this.state.talklist

      let newtext =infos.text.replace(",]","]")
      console.log(newtext)
      console.log(this.jsonParse(newtext))
    
      let asklistlast = this.state.asklist
      if(this.jsonParse(newtext)){
       
         let newtextarr = this.jsonParse(newtext)
         //console.log(newtextarr)
         showtext = ''
         let filelist = []

         let tool_calls = '';
         let sql_db_query = '';
         let oldsql_db_query =[];
         let retriever_docs = "";

         let postdate = [];
         for(var i=0;i<newtextarr.length;i++){
             //console.log(newtextarr[i])

            if(newtextarr[i]['role']=="assistant"){
              showtext = showtext+newtextarr[i]['content']
            }else{
              let text = newtextarr[i]['role'];
              let tcall ='';
              if(text=="tool_calls"){
                tcall ={
                  [text] : newtextarr[i]['tool_calls'],
                }
              }else{
                tcall ={
                  [text] : newtextarr[i]['content'],
                }
              }
              postdate.push(tcall)
            } 
            if(newtextarr[i]['code']=="2000"){
                // console.log(newtextarr[i]['data'])
                this.setState({
                  messageids:newtextarr[i]['data']
                })
              }
            
            if(newtextarr[i]['role']=="tool"){
              // tool_calls = newtextarr[i]['content']
              if(newtextarr[i]['content'] && newtextarr[i]['content'].length>0){
                if(this.isValidJSON(newtextarr[i]['content'])){
                  let filetmp = JSON.parse(newtextarr[i]['content'])

                  if(filetmp.length>0 && filetmp[0].metadata){
                    for(var kk=0;kk<filetmp.length;kk++){
                      filelist.push(filetmp[kk].metadata)
                    }
                  }else{    
                    for(var kk=0;kk<filetmp.length;kk++){
                      filelist.push(filetmp[kk])
                    }
                    // filelist = filetmp
                  }
                  
                   console.log(filelist)
                }
                
              }
            }


            // if(newtextarr[i]['role']=="retriever_docs"){
            //   retriever_docs = newtextarr[i]['data']
            //   let filetmp = this.jsonParse(newtextarr[i]['data'])
            //   filetmp = JSON.parse(newtextarr[i]['data']);
            //   let filelistnew = []
            //   for(var kk=0;kk<filetmp.length;kk++){
            //     let fjson =filetmp[kk]['metadata']
            //     filelistnew.push(fjson)
            //   }
            //   filelist = filelistnew
              
            //   console.log(filelist)
            // }
            // if(newtextarr[i]['role']=="tool_calls"){
            //   tool_calls = newtextarr[i]['tool_calls']
            //   let tcall ={
            //     "tool_calls": newtextarr[i]['tool_calls'],
            //   }
            //   // postdate.push(tcall)
            // }
            // if(newtextarr[i]['event']=="sql_db_query"){
            //   sql_db_query = newtextarr[i]['tool_calls']

            //   let aaa = newtextarr[i]['data']

            //   let tcall ={
            //     "sql_db_query": newtextarr[i]['data'],
            //   }
            //   // postdate.push(tcall)
            //   console.log(aaa)
            //   oldsql_db_query.push(aaa);
            //   console.log(oldsql_db_query)
            //   // let filetmp = this.parse(newtextarr[i]['data'])
            //   // console.log(JSON.parse(sql_db_query))
            //   if(sql_db_query.indexOf("create_time")>=0){
            //     let regex = /datetime\.datetime\([^)]*\)/g;  
            //     //  sql_db_query = sql_db_query.replace(regex, '""');     
            //     //  sql_db_query = sql_db_query.replace(/'/g, '"');
            //     //  sql_db_query = sql_db_query.replace(/\n/g, "\\n");

            //     let obj = JSON.parse(sql_db_query);
            //     filelist = obj
            //     console.log(obj)
            //   }
            //   console.log(oldsql_db_query)
            // }
            
            //  showtext = showtext+newtextarr[i]['data']
         }
         //console.log(filelist)
         //console.log(showtext)
         
         showtext = showtext.replace(/,,/g,"\n")
         talklist[talklist.length-1]['message'] = showtext
         console.log(filelist)
         if(filelist){
          console.log(333)
          this.changeids(talklist.length-1,filelist);
          // talklist[talklist.length-1]['source_documents'] =   filelist
         }else{
          talklist[talklist.length-1]['source_documents'] =   []
         }
       
         asklistlast[asklistlast.length-1].push(showtext)
         asklistlast[asklistlast.length-1].push(tool_calls)
         asklistlast[asklistlast.length-1].push(retriever_docs)
         asklistlast[asklistlast.length-1].push(oldsql_db_query)
         asklistlast[asklistlast.length-1].push(postdate)
         
      
      }
      // talklist[talklist.length-1]['message'] = showtext

    

      text = '';
      this.setState({
        loadingstate:false,
        seachinput:'',
        answerover:true,
        talklist:talklist,
        errornum:0,
        asklist:asklistlast,
      })

    }).catch(error=>{
      console.log(error)
      // let talklist = this.state.talklist
       
      // this.setState({
      //   errornum:this.state.errornum+1,
      //   seachinput:talklist[talklist.length-1]['message']
      // },()=>{
      //   if(this.state.errornum>3){
          let talkinfo={
            userid:'0',
            username:'西影力学院智能助手',
            headimg:'',
            message:"很抱歉，我无法回答这个问题。请您刷新后重试 ",
            source_documents:[],
            type:2,
          }
          ////console.log(talkinfo)
          let talklist = this.state.talklist
          talklist.push(talkinfo)

          this.setState({
            loadingstate:false,
            seachinput:'',
            answerover:true,
            talklist:talklist,
            errornum:0,
          })

          setTimeout(() => { 
            $('.aitalkbg')[0].scrollTo({top:999999, left:0})
          }, 100)
        // }else{
        //   setTimeout(()=>{
        //     // this.getliumessage();
        //   },1000)
        // }
      // })

    })
   
  }

   isValidJSON = (text) => {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }


}


  addtext = () =>{

  }
  componentWillUnmount() {
    
  }
  sendmessage = ()=>{
    let newmessage= this.state.seachinput.replace(/ /g,"");
    if(this.state.seachinput && newmessage !=''){
      let asklist = this.state.asklist
      let asknew = []
      asknew.push(newmessage)
      //console.log(asknew)
      //console.log(asklist)
      asklist.push(asknew)
      let talkinfo={
        userid:localStorage.getItem('userid'),
        username:localStorage.getItem('name'),
        headimg:localStorage.getItem('headimg'),
        message:this.state.seachinput,
        type:1,
      }
      let talklist = this.state.talklist
        talklist.push(talkinfo)
        this.setState({
          asklist:asklist,
          talklist:talklist,
          loadingstate:true,
          seachinput:'',
        })
        setTimeout(() => { 
          $('.aitalkbg')[0].scrollTo({top:999999, left:0})
        }, 100)
        this.getliumessage()
        return
      fetch(
        "https://shl-info.cn/front/ai/conversation_message?userid="+localStorage.getItem('userid'),{
          method: 'POST',
          body: JSON.stringify({
            message:this.state.seachinput,
            userid:localStorage.getItem('userid'),
          }),
          headers:{
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
          header: {
            'content-type': 'application/json',
            'token':localStorage.getItem('token')
          },
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
          if(res.code==2000){

            let talkinfo={
              userid:'0',
              username:'西影力学院智能助手',
              headimg:'',
              message:res.data.answer,
              source_documents:res.data.source_documents,
              type:2,
            }
            let talklist = this.state.talklist
            talklist.push(talkinfo)
            this.setState({
              talklist:talklist,
              loadingstate:false,
              seachinput:'',
            })

            setTimeout(() => { 
              $('.aitalkbg')[0].scrollTo({top:999999, left:0})
            }, 100)
          }else{
            this.setState({
              loadingstate:false,
              seachinput:'',
            })
          }
          //console.log(res)
      })
    }
    //console.log(this.state.seachinput)
  }
  
  getpdf = (item)=>{
    //console.log(item)
    if(item.metadata.type=="document"){
      if(item.metadata.source){
        this.props.history.push("/aipdf?pdf="+item.metadata.source+"&isaicontent=1"+"&aiid="+this.state.messageids)
      }
    }
    
    if(item.metadata.type=="course"){
      //console.log("course")
      if(item.metadata.source){
        this.props.history.push("/coursedetails?type="+item.metadata.source+"&isaicontent=1"+"&aiid="+this.state.messageids)
      }
    }
    
    
    if(item.metadata.type=="article"){
      //console.log("article")
      if(item.metadata.source){
        //console.log(this.props.history)
        this.props.history.push("/postDetail?id="+item.metadata.source+"&isaicontent=1"+"&aiid="+this.state.messageids)
      }
    }
    
  }
  handleKeyDown =(e) =>{
    if (e.key === 'Enter') {
      this.sendmessage();
      e.preventDefault()
    }
  } 
  changelanding = () =>{
    this.setState({
      showlanding:true
    })
    localStorage.setItem('showlanding',true)
  }
  changenum = (num) =>{
    //console.log(num)
    this.setState({
      hoveenum:num
    })
  }
  subsurvery = () =>{
    if(this.state.hoveenum>=1){
      //console.log('提交评分')

      fetch(
        "https://shl-info.cn/front/ai/grade?userid=" +
        localStorage.getItem('userid') +
        "&score=" +
        this.state.hoveenum ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        this.closechangetype()
      })
    }
  }
  subcontent = () =>{
    if(!this.isOnlySpaces(this.state.surverycontent) && this.state.surverycontent){
      //提交
      fetch(
        "https://shl-info.cn/front/ai/comment?userid=" +
        localStorage.getItem('userid') +
        "&comment=" +
        this.state.surverycontent ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        this.closechangetype()
      })
    }
  }
  closechangetype = ()=>{
    this.setState({
      hoveenum:'',
      userchangetype:0,
      surverycontent:'',
    })
  }
  openlayer = (i)=>{
    if(i==1&& this.state.checkGrade){
      return
    }
    this.setState({
      userchangetype:i,
    })
  }
  isOnlySpaces = (input) => {  
    return /^[ ]*$/.test(input);  
  }  

  render() {
    
    return (
      <div className='ailistbox'>
        
        <Header active='4' isai={true}></Header>
        {this.state.userchangetype==1? <div className='ai_surveryboxlayer'>
          <div className='ai_surverybox'>
            <div className='ai_surverybox_title'>我要评分
              <span className='ai_surverybox_close'  onClick={()=>this.closechangetype()}></span>
            </div>
            <div className='ai_surverycontent'>
              <div className='ai_surverycontenttitle'>请您为西影力学院智能助手进行评分</div>
              <div className='ai_surverycontentnumblist'>
                <span  className={this.state.hoveenum==1?'hover':''} onClick={()=>this.changenum(1)}>1</span>
                <span  className={this.state.hoveenum==2?'hover':''} onClick={()=>this.changenum(2)}>2</span>
                <span  className={this.state.hoveenum==3?'hover':''} onClick={()=>this.changenum(3)}>3</span>
                <span  className={this.state.hoveenum==4?'hover':''} onClick={()=>this.changenum(4)}>4</span>
                <span  className={this.state.hoveenum==5?'hover':''} onClick={()=>this.changenum(5)}>5</span>
              </div>
            </div>
            <div className={this.state.hoveenum>=1?"ai_surverybtn":"ai_surverybtn ai_surverybtnnocontent"} onClick={()=>this.subsurvery()}>提交</div>
          </div>
        </div>:null}
        {this.state.userchangetype==2? <div className='ai_surveryboxlayer'>
          <div className='ai_surverybox'>
            <div className='ai_surverybox_title'>我要反馈
              <span className='ai_surverybox_close'  onClick={()=>this.closechangetype()}></span>
            </div>
            <div className='ai_surverycontent'>
              <div className='ai_surverycontenttitle'>请把您对西影力学院智能助手的宝贵建议写在这里:</div>
              <div className='ai_surverycontenttextarea'>
                <textarea placeholder='请输入文字:' 
              onChange={this.valueChangetext}
              value={this.state.surverycontent}
              ></textarea>
              </div>
            </div>
            <div className={!this.isOnlySpaces(this.state.surverycontent) && this.state.surverycontent?"ai_textareabtn":"ai_textareabtn ai_textareabtnnotext"} onClick={()=>this.subcontent()}>提交</div>
          </div>
        </div>:null}
       
       {this.state.showlanding?null:<div className="ailandinglayer">
          <div className="ailandinglayer_line"></div>
          <div className="ailandinglayer_text">开启全新对话体验</div>
          <div className="ailandinglayer_btn"><span  onClick={()=>this.changelanding()}>开始</span></div>
        </div>} 
        {this.state.loadingstate?<div className="loading_tips">
          {!this.state.answerover?null:<span>解析中...</span>}
        </div>:null}
        <div className='aiaddbtnlist'>
          <span className='aiaddbtnshow'  onClick={()=>this.openlayer(2)}>我要反馈</span>
          <span className={this.state.checkGrade?"aiaddbtnshow canotsub":"aiaddbtnshow"}  onClick={()=>this.openlayer(1)}>{this.state.checkGrade?"已评分":"我要评分"}</span>
        </div>
        <div className="aicontent aitalkbg">
          
          <div className="aicontentbox aitalk_talkbox">

          <div className="aitalk_showtitle">本智能助手为内部员工测试版本，仅供回答与西影力学院推荐查询相关的问题，并持续优化中。如遇到小助手回答的质量不佳，希望可以获得您的反馈，帮助小助手继续成长，谢谢您的支持</div>




          {this.state.talklist.length>0?
              this.state.talklist.map((item,index)=>{
                if(item.type==1){

                  return <div className="aichatRecordItem right" key={"talklist2"+index} >
                  <div className="chatRecordItemContent">
                    <div className="chatRecordItemUserName">{item.username}</div>
                    <div className="chatRecordItemUserData">
                    {item.message}
                    </div>
                  </div>
                  <img className="chatRecordItemUserAvatar" src={item.headimg?item.headimg:require('../../static/images/ai/peopleheadimg.png')}></img>
                </div>

                
                //   return <div className="aichatRecordItem right" key={"list"+index} >
                //   <div className="chatRecordItemContent">
                //     <div className="chatRecordItemUserName">{item.username}</div>
                //       <div className="chatRecordItemUserData">{item.message}</div>
                //   </div>
                //   <img className="chatRecordItemUserAvatar" src={item.headimg}></img>
                // </div>
    
                }else{
                  
                  return  <div className="aichatRecordItem left" key={"talklist"+index} >
                  <img className="chatRecordItemUserAvatar" src={require('../../static/images/ai/aiheadimg.png')}></img>
                  <div className="chatRecordItemContent">
    
                    <div className="chatRecordItemUserName">{item.username}</div>
                    <div className="chatRecordItemUserData nopadding">
                      
                      {index==0?<div dangerouslySetInnerHTML={{ __html: item.message }} ></div> :<ReactMarkdown>{item.message}</ReactMarkdown>}
                    
                      {/* <div dangerouslySetInnerHTML={{ __html: item.message }} ></div> */}

                      
                    {/* {
                      parser.makeHtml(item.message)
                    }
                      
                      123123123
                     */}
                      {item.source_documents?item.source_documents.length>0? <div className="pdflist" >
                        {item.source_documents.map((pdfitem,pdfindex)=>{
                          return  <div className="pdfname"  onClick={()=>this.getpdf(pdfitem)}  key={"pdflist"+pdfindex}><span className={pdfitem.metadata['type']=='course'?"icon course":pdfitem.metadata['type']=='article'?"icon interact":"icon pdf"}></span>{pdfitem.metadata['title']}</div>
                         
                          })
                        }
                         </div>
                      :null:null}
                    </div>
                  </div>
                </div>
                //   return <div className="aichatRecordItem right" key={"talklist2"+index} >
                //   <div className="chatRecordItemContent">
                //     <div className="chatRecordItemUserName">{item.username}</div>
                //     <div className="chatRecordItemUserData">
                //     {item.message}
                //     {item.source_documents?item.source_documents.length>0? <div className="pdflist" >
                //         {item.source_documents.map((pdfitem,pdfindex)=>{
                //           return  <div className="pdfname"  onClick={()=>this.getpdf(pdfitem)}  key={"pdflist"+pdfindex}><span className="icon"></span>{pdfitem.metadata['title']}</div>
                         
                //           })
                //         }
                //          </div>
                //       :null:null}
                //     </div>
                //   </div>
                //   <img className="chatRecordItemUserAvatar" src={require('../../static/images/ai/aiheadimg.png') }></img>
                // </div>
    
                //   return   <div className="aichatRecordItem left"  key={"list"+index} >
                //   <img className="chatRecordItemUserAvatar" src={require('../../static/images/ai/aiheadimg.png') }></img>
                //   <div className="chatRecordItemContent">
    
                //     <div className="chatRecordItemUserName">{item.username}</div>
                //     <div className="chatRecordItemUserData">
                //       {item.message}
                //       {item.source_documents?item.source_documents.length>0? <div className="pdflist" >
                //         {item.source_documents.map((pdfitem,pdfindex)=>{
                //           return  <div className="pdfname"  onClick={()=>this.getpdf(pdfitem)}  key={"pdflist"+pdfindex}><span className="icon"></span>{pdfitem.metadata['title']}</div>
                         
                //           })
                //         }
                //          </div>
                //       :null:null}
                     
                  
                //     </div>
                //   </div>
                // </div>
                }
              })
            :null}


            {/* <div className="aichatRecordItem right" >
              <div className="chatRecordItemContent">
                <div className="chatRecordItemUserName">test111111</div>
                <div className="chatRecordItemUserData">123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312</div>
              </div>
              <img className="chatRecordItemUserAvatar" src={require('../../static/images/ai/aiheadimg.png') }></img>
            </div>

            <div className="aichatRecordItem left" >
              <img className="chatRecordItemUserAvatar" src={require('../../static/images/ai/peopleheadimg.png') }></img>
              <div className="chatRecordItemContent">

                <div className="chatRecordItemUserName">test1111</div>
                <div className="chatRecordItemUserData">123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312</div>
              </div>
            </div>
            <div className="aichatRecordItem right" >
              <div className="chatRecordItemContent">
                <div className="chatRecordItemUserName">test111111</div>
                <div className="chatRecordItemUserData">123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312</div>
              </div>
              <img className="chatRecordItemUserAvatar" src={require('../../static/images/ai/aiheadimg.png') }></img>
            </div>

            <div className="aichatRecordItem left" >
              <img className="chatRecordItemUserAvatar" src={require('../../static/images/ai/peopleheadimg.png') }></img>
              <div className="chatRecordItemContent">

                <div className="chatRecordItemUserName">test1111</div>
                <div className="chatRecordItemUserData">123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312123123123121231231231212312312312</div>
              </div>
            </div> */}
            
          </div>
        </div>
        <div className="aitalk_bottombox">
          <div className="aitalk_inputbox">
            <textarea className="inputbox" id="demo"
               onKeyDown={this.handleKeyDown}
              onChange={this.valueChange}
              value={this.state.seachinput}
            ></textarea>
          </div>
          <div className="aitalk_send" onClick={()=>this.sendmessage()}></div>
        </div>
        {/* <Footer></Footer> */}
      </div>
    )
  }
}

export default WatchHistory
