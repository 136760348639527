import React, { Component } from 'react';
import './index.css';

class Onlinetalk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      talkvalue: ""
    };
  }
  valueChange(e) {
    this.setState({
      talkvalue: e.target.value
    });
  }
  talksubmit() {
    if (this.state.talkvalue) {
      this.props.change({
        messagetype: "message",
        user_id: this.props.userinfo.userid,
        type_id: "1", //类型 :自己
        username: this.props.userinfo.info.name, // 姓名
        text: this.state.talkvalue,
        course_id: this.props.classid,
        avatar_url: this.props.userinfo.pic
      });
      this.setState({
        talkvalue: ""
      });
    }
  }
  refresh() {
    // setTimeout(() => {
    //   if (document.getElementsByClassName(
    //     "iscroll-padding"
    //   )[0]) {
    //   document.getElementsByClassName(
    //     "iscroll-padding"
    //   )[0].scrollTop = document.getElementsByClassName(
    //     "iscroll-padding"
    //   )[0].scrollHeight;
    //   }
    // }, 100);
  }
  componentDidUpdate() {
    this.refresh()
  }
  componentDidMount() {
    this.refresh();
  }
  componentWillUnmount() {}
  render() {
    let onlinelist = this.props.onlinelist.map((item, index) => {
      let box = null;
      if (item.type_id === "4") {
        box = (
          <div key={index} className="online-time">
            <span>{item.time}</span>
          </div>
        );
      } else {
        box = (
          <div
            key={index}
            className={
              item.type_id === "3"
                ? "onlinebox style-right"
                : "onlinebox style-left"
            }
          >
            <span
              className={
                item.type_id === "2" ? "online-img teach-imgs" : "online-img"
              }
            >
              <img src={item.avatar_url} alt="" />
            </span>
            <span className="online-name">{item.username}</span>
            <div
              className={
                item.type_id === "1"
                  ? "online-other"
                  : item.type_id === "2"
                  ? "online-teach"
                  : "online-my"
              }
            >
              {item.text}
            </div>
          </div>
        );
      }
      return box;
    });
    if(this.props.onlinelist.length<=0){
      onlinelist =<div className='nocontent'>暂无内容</div>
    }
    return (
      <div
        className={
          this.props.islive ? "online-scroll " : "online-scroll"
        }
      >
        <div className="wrap">
          <div className="iscroll-padding">{onlinelist}</div>
          <div className="online-submit">
            <textarea
              value={this.state.talkvalue}
              className="online-input"
              placeholder='请输入'
              onChange={e => this.valueChange(e)}
            />
            <div
              className="submit-talk touchCss"
              onClick={e => this.talksubmit()}
            >
              发送
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Onlinetalk;
