import React, { Component } from 'react';
import $ from 'jquery'
import './index.css';
import Maskbox from '../maskbox';

class Internalpointsdetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masktext: "提交成功",
      submitmask: false,
      score:0,
      mynum:'',
      topuserlist:[],
      isloadingover:false
    };
  }
  componentDidMount() {
    this.getpointlist();
  }
  getpointlist = ()=>{
    fetch(
      "https://shl-info.cn/front/shortvideo/getusersalepointsdetail?userid=" +
      localStorage.getItem('userid'),{
        headers:{
          'token':localStorage.getItem('token')
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        this.setState({
          isloadingover:true
        })
        if(res && res.code==2000){
          this.setState({
            topuserlist:res.data.details,
          })
        }else{
          
          // this.props.history.push('/homeperson')
        }
      })

   
  }
  
  formatdate = (timestamp)=>{
    const date = new Date(timestamp * 1000); // 转换Unix时间戳从秒到毫秒
    return date.getFullYear() + '.' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '.' +
        ('0' + date.getDate()).slice(-2) + ' ' +
        ('0' + date.getHours()).slice(-2) + ':' +
        ('0' + date.getMinutes()).slice(-2);
  }
  componentWillUnmount() { }
  render() {
    
    return (
      <div className="scorelayer2023 ">
      <div className="scorelayer2023box internalpointslayer center">
          <div className="scorelayer2023box_title">
              积分详情
              <div className="scorelayer2023box_title_close" onClick={()=>this.props.closepointstatelayer()}></div>
          </div>
          
          <div className="internalpointsdetaillayerbox">
           
         {this.state.isloadingover?this.state.topuserlist.length>0?'': <div className='internalpointsdetail_no'>暂无内容</div>: <div className='internalpointsdetail_no'>加载中</div>}
          {this.state.topuserlist.length>0?this.state.topuserlist.map((item,index)=>{
            return  <div className='internalpointsdetail_li'   key={"pointdetail"+index}>
            <div className='internalpointsdetail_li_name'>{item.pointtype==2?"观看短视频":item.type}</div>
            <div className='internalpointsdetail_li_date'>{this.formatdate(item.addtime)}</div>
            <div className='internalpointsdetail_li_num'><span></span>{item.pointtype==2?1:item.point}</div>
          </div>
          }):null}
           
            
            
          </div>
          
          
      </div>
  </div >
    );
  }
}

export default Internalpointsdetail;
