import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import ClassList from '../../components/classlist'

import Aicontent from '../../components/aicontent'
import './index.css'

import  fetchStream  from '../ai/fetchStream.ts'
import  handleUint8Array  from '../ai/handleUint8Array.ts'

var text='';

class SearchPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      allnum: 0,
      
      seachkeyword:this.props.common_search,
      page:1,
      isstate:1,
      //isstate:this.props.location.search.indexOf('source=files') > -1?2:1,
      isover:false,
      countcourse:0,

      
      showai:false,
      aibacktext:'',
      surverycontent:'',
      showsurvery:false,
      surverytype:0,
      showtips:false,
      messageids:0,

    }
  }
  searchinit(labellist, text,type='') {
    console.log(text,type)
    if (!this.ajaxfalg) {
      return false;
    }
    this.ajaxfalg = false;
    let labelarr = [];
    labellist.forEach((item) => {
      item.list.forEach((list) => {
        if (list.active) {
          labelarr.push(list.id)
        }
      })
    })
    let that = this;

    let showtype = false
    console.log(type,text)
    if( text ){
      showtype=true
      
    } 
    // if(type==1 && text){
    //   showtype=true
      
    // }
    
    // showtype=false
    this.setState({
      seachkeyword:text,
      showai:showtype,
    },()=>{
      // if(type==1 && text){
      //   that.getaicontent(text)
      // }
      if(text){
        that.getaicontent(text)
      }
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/frontc/searchcourse?userid=" +
          that.props.userinfo.userid +"&page="+that.state.page,
        data: JSON.stringify({
         // title: text,
          title: that.state.seachkeyword,
          tags: labelarr
        }),
        dataType: "json",
        headers:{'token':that.props.userinfo.token},
        cache: false
      }).then(res => {
        that.ajaxfalg = true;
        if (res && res.status !== 2) {
          let searchcourselist=[];
            if(res.searchcourselist){
              if(that.state.page==1){
                searchcourselist = res.searchcourselist
              }else{
                searchcourselist = that.state.list.concat(res.searchcourselist)
              }
            }
            
            let isstate=false
            if(res.searchcourselist.length<=0 &&  that.state.isstate==1){
              isstate =true;
            }
  
            
            let hotattachlist=[];
            if(res.hotattachlist.length<=0 &&  that.state.isstate==2){
              isstate =true;
            }
            if(res.hotattachlist){
              
              if(that.state.page==1){
                hotattachlist = res.hotattachlist
              }else{
                hotattachlist = that.state.filelist.concat(res.hotattachlist)
              }
            }
            that.setState({
              list: searchcourselist,
              filelist: hotattachlist,
              countcourse:res.coursecount,
              allnum:res.coursecount,
             // ajaxfalg: true,
              isover:isstate,
            })
  
  
          // that.setState({
          //   list: res.searchcourselist,
          //   allnum: res.searchcourselist.length
          // });
          that.ajaxfalg = true;
        }
      });
    })
    
    
    
  }
  changeLabel = (arr) => {
    // 筛选
    this.searchinit(arr, this.props.common_search)
  }
  searchCollback = (text,type) => {
    // 搜索
    console.log("seach")
    console.log(text)
    console.log(type)
    this.setState({
      page:1,
      aibacktext:"",
      showtips:false,
      showai:false,
      isover:false,
      filelist:[],
      list:[],
    },()=>{
      this.searchinit(this.props.labellist, text,type)
    })
    //this.searchinit(this.props.labellist, text)
  }
  checkScrollEnd = () => {
    console.log(this.state.page)

    if (!this.ajaxfalg || this.state.isover) {
      return false;
    }
    this.setState({
      page:this.state.page+1,
    },()=>{
      this.searchinit(this.props.labellist, this.state.seachkeyword)
    })
  }
  isScrollBottom = () => {
    var scrollElement = $('body')[0]; // 或者 document.body，根据实际情况选择滚动的元素
    var scrollTop = scrollElement.scrollTop; // 滚动的距离
    var clientHeight = scrollElement.clientHeight; // 可视区域高度
    var scrollHeight = scrollElement.scrollHeight; // 滚动内容的总高度
    console.log(scrollTop + clientHeight)
    console.log(scrollHeight)
    if(scrollTop + clientHeight >=scrollHeight-50){
      this.checkScrollEnd();
    }
  }
  // is
  componentDidMount() {
    this.ajaxfalg = true;
    console.log()
    console.log("loadingsearchpage")
    console.log(window.location.href.indexOf('isai=1'))
    if(window.location.href.indexOf('isai=1') > -1){
      console.log(11111)
      this.searchinit(this.props.labellist, this.props.common_search,1)
    }else{
      console.log(22222)
      this.searchinit(this.props.labellist, this.props.common_search,1)
    }

    $('body')[0].addEventListener('scroll', this.isScrollBottom)
   

  }

  jsonParse = (val, defaultVal) => {
    if (!val || typeof val !== 'string') {
      console.warn('JSON.parse need string param');
      return defaultVal;
    }
    try {
      return JSON.parse(val);
    } catch (e) {
      return defaultVal;
    }
  }

  isValidJSON = (text) => {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }


}

  getaicontent = (inputtext) => {
    console.log(inputtext)
    fetchStream("https://shl-info.cn/front/Ai/message?userid="+localStorage.getItem("userid"),{
      data:{
        message:inputtext?inputtext.replace(/\n/g,""):"",
        userid:localStorage.getItem('userid'),
        issearch:1,
        chat_history:[],
      },
      // data
    },(res)=>{
      ////console.log(res)
      
      if(text){
        text = text+','+res.result

        
        let infos = handleUint8Array(text);
        let newtext =infos.text.replace(",]","]")
        let showtext = ''
        
        if(this.jsonParse(newtext)){
          showtext = ''
          let newtextarr = this.jsonParse(newtext)
          for(var i=0;i<newtextarr.length;i++){
              if(newtextarr[i]['role']=="assistant"){
                showtext = showtext+newtextarr[i]['content']
              }

              if(newtextarr[i]['code']=="2000"){
                // console.log(newtextarr[i]['data'])
                this.setState({
                  messageids:newtextarr[i]['data']
                })
              }
          }
          
         showtext = showtext.replace(/,,/g,"\n")
         
        console.log(showtext)
          this.setState({
            aibacktext:showtext.substring(0, showtext.length - 1)
          })
         
  
       }

        
       
      }else{
        text = text+res.result
      }
    }).then((res)=>{

      let infos = handleUint8Array(text);


      // let showtext = infos.text
      
      // let talklist = this.state.talklist
      // talklist[talklist.length-1]['message'] = showtext

      // let asklistlast = this.state.asklist
      // asklistlast[asklistlast.length-1].push(showtext)
      // //console.log(asklistlast)
      let showtext = infos.text

      let newtext =infos.text.replace(",]","]")


      


      if(this.jsonParse(newtext)){
        let newtextarr = this.jsonParse(newtext)
        showtext = ''
        let filelist = []

        let tool_calls = '';
        let retriever_docs = "";
        
        let sql_db_query = '';
        // let oldsql_db_query = "";
        
        let oldsql_db_query =[];
        
        let postdate = [];

        for(var i=0;i<newtextarr.length;i++){
            //console.log(newtextarr[i])
            if(newtextarr[i]['role']=="assistant"){
             showtext = showtext+newtextarr[i]['content']
           }else{
            // let text = newtextarr[i]['event'];
            // let tcall ={
            //   [text] : newtextarr[i]['data'],
            // }
            // oldsql_db_query.push(tcall)
            let text = newtextarr[i]['role'];
              let tcall ='';
              if(text=="tool_calls"){
                tcall ={
                  [text] : newtextarr[i]['tool_calls'],
                }
              }else{
                tcall ={
                  [text] : newtextarr[i]['content'],
                }
              }
              postdate.push(tcall)
            }
            if(newtextarr[i]['role']=="tool"){
              // tool_calls = newtextarr[i]['content']
              if(newtextarr[i]['content'] && newtextarr[i]['content'].length>0){
                if(this.isValidJSON(newtextarr[i]['content'])){
                  let filetmp = JSON.parse(newtextarr[i]['content'])

                  if(filetmp.length>0 && filetmp[0].metadata){
                    for(var kk=0;kk<filetmp.length;kk++){
                      filelist.push(filetmp[kk].metadata)
                    }
                  }else{    
                    for(var kk=0;kk<filetmp.length;kk++){
                      filelist.push(filetmp[kk])
                    }
                    // filelist = filetmp
                  }
                 console.log(filelist)
                }
              }
            }
        }

        showtext = showtext.replace(/,,/g,"\n")
       
        console.log(showtext)
        console.log(filelist)
        if(filelist){
         this.changeids(filelist,showtext)

          
         
          // for(var i=0;i<filelist.length;i++){
          //   if(filelist[i]['data_type']=="course"){
          //     showtext = showtext.replace("《"+filelist[i]['title']+"》","![课程]("+require('../../static/images/ai/icon-course.png')+")[《"+filelist[i]['title']+"》](/coursedetails?type="+filelist[i]['data_id']+")")
          //   }
            
          //   if(filelist[i]['data_type']=="article"){
          //     showtext = showtext.replace("《"+filelist[i]['title']+"》","![医医互动]("+require('../../static/images/ai/icon-interact.png')+")[《"+filelist[i]['title']+"》](/interactionpostcontent?id="+filelist[i]['data_id']+")")
          //   }
            
          //   if(filelist[i]['data_type']=="document"){
          //     showtext = showtext.replace("《"+filelist[i]['title']+"》","![document]("+require('../../static/images/ai/icon-pdf.png')+")[《"+filelist[i]['title']+"》](/aipdf?pdf="+filelist[i]['data_id']+")")
          //   }
          //   // showtext = showtext.replace("《"+filelist[i]['title']+"》","![课程]("+require('../../static/images/ai/icon-course.png')+")[《"+filelist[i]['title']+"》](/coursedetails?type="+filelist[i]['data_id']+")")
          // }
        }else{
          this.setState({
            aibacktext:showtext
          })
        }
        this.setState({
          showtips:true
        })

      
       
      
     
     }

      text = '';
      
    }).catch(error=>{
      console.log(error)
          let talkinfo={
            userid:'0',
            username:'西影力学院智能助手',
            headimg:'',
            message:"很抱歉，我无法回答这个问题。请您刷新后重试",
            source_documents:[],
            type:2,
          }
          ////console.log(talkinfo)
          // let talklist = this.state.talklist
          // talklist.push(talkinfo)

          // this.setState({
          //   loadingstate:false,
          //   seachinput:'',
          //   answerover:true,
          //   errornum:0,
          // })

    })
    
  }

  changeids = (item,showtext) =>{
 
    let ids = [];
    for(var i=0;i<item.length;i++){
     
      let tmpids = {
        id:item[i]['data_id'],
        type:item[i]['data_type']
      }
      ids.push(tmpids)
    
    }
    fetch(
      "https://shl-info.cn/front/ai/check_source?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          ids:ids,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
     
        if(res.data.length>0 ){
  
          // if(item.metadata.type=="document"){
          //   if(item.metadata.source){
          //     this.props.history.push("/aipdf?pdf="+item.metadata.source)
          //   }
          // }
          
          // if(item.metadata.type=="course"){
          //   //console.log("course")
          //   if(item.metadata.source){
          //     this.props.history.push("/coursedetails?type="+item.metadata.source)
          //   }
          // }
          
          
          // if(item.metadata.type=="article"){
          //   //console.log("article")
          //   //console.log(item.metadata)
          //   if(item.metadata.source){
          //     this.props.history.push("/interactionpostcontent?id="+item.metadata.source)
          //   }
          // }
         
          let filelist = res.data
           for(var i=0;i<filelist.length;i++){
            console.log(filelist[i])
              if(filelist[i]['metadata']['type']=="course"){
                showtext = showtext.replace("《"+filelist[i]['metadata']['title']+"》","![课程]("+require('../../static/images/ai/icon-course.png')+")[《"+filelist[i]['metadata']['title']+"》](/website/coursedetails?type="+filelist[i]['metadata']['source']+"&isaicontent=1"+"&aiid="+this.state.messageids+")")
              }
              
              if(filelist[i]['metadata']['type']=="article"){
                showtext = showtext.replace("《"+filelist[i]['metadata']['title']+"》","![医医互动]("+require('../../static/images/ai/icon-interact.png')+")[《"+filelist[i]['metadata']['title']+"》](/website/postDetail?id="+filelist[i]['metadata']['source']+"&isaicontent=1"+"&aiid="+this.state.messageids+")")
              }
              
              if(filelist[i]['metadata']['type']=="document"){
                showtext = showtext.replace("《"+filelist[i]['metadata']['title']+"》","![document]("+require('../../static/images/ai/icon-pdf.png')+")[《"+filelist[i]['metadata']['title']+"》](/website/aipdf?pdf="+filelist[i]['metadata']['source']+"&isaicontent=1"+"&aiid="+this.state.messageids+")")
              }
              // showtext = showtext.replace("《"+filelist[i]['title']+"》","![课程]("+require('../../static/images/ai/icon-course.png')+")[《"+filelist[i]['title']+"》](/coursedetails?type="+filelist[i]['data_id']+")")
            }
            
          console.log(showtext)
            this.setState({
              aibacktext:showtext
            })
         
        }else{
          this.setState({
            aibacktext:showtext
          })
        }
        console.log(showtext)
        // return showtext
        // //console.log(tmp)
      // }
    })
  }
  isOnlySpaces = (input) => {  
    return /^[ ]*$/.test(input);  
  }  
  valueChangetext =(e)=>{
    this.setState({
      surverycontent:e.target.value
    })
    //console.log(e.target.value)
  }
  subcontent = ()=>{
    if(!this.isOnlySpaces(this.state.surverycontent) && this.state.surverycontent){
      //提交
      fetch(
        "https://shl-info.cn/front/ai/comment?userid=" +
        localStorage.getItem('userid') +
        "&comment=" +
        this.state.surverycontent ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        this.setState({
          showsurvery:false,
          surverycontent:'',
        })
      })
    }
  }
  closechangetype = (type )=>{
    if(type==1){
      this.setState({
        showsurvery:true,
      })
    }else{
      this.setState({
        showsurvery:false,
      })
    }
  }
  changesurvery = (type)=>{

    fetch(
      "https://shl-info.cn/front/Ai/addFeedbak?userid=" +
      localStorage.getItem('userid') +
      "&type=" +
      type,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
    })

    
    this.setState({
      surverytype:type,
    })
    if(type==2){
      this.setState({
        showsurvery:true,
      })
    }
  }
  componentWillUnmount() {

  }
  render() {
    let list = this.state.list.map((item) => {
      return (
        <ClassList key={item.id} _item={item}></ClassList>
      )
    })
    return (
      <div className='personpage'>
        <Header
          active={this.props.active}
          searchCollback={this.searchCollback}
          changeLabel={this.changeLabel}></Header>
          
        <div className="wrap personal-margin personal-marginai">
          
        {this.state.showai? <Aicontent changesurvery={this.changesurvery} surverytype={this.state.surverytype} valueChangetext={this.valueChangetext} closechangetype={this.closechangetype} showtips={this.state.showtips} showsurvery={this.state.showsurvery}  subcontent={this.subcontent} surverycontent={this.state.surverycontent} aibacktext={this.state.aibacktext}></Aicontent>:""}


          <div className="seachnumb">为您找到相关结果{this.state.allnum}个</div>
          

          {list}
          


        </div>
        
        {!this.ajaxfalg && !this.state.isover?<div className="ajaxloading">加载中...</div>:""}
        {this.state.isover?<div className="ajaxloading">已全部加载...</div>:""}
        <Footer></Footer>
      </div>
    )
  }
}

export default SearchPage
