import React, { Component } from 'react'
import $ from 'jquery'
import ReconnectingWebSocket from 'reconnecting-websocket'

// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import Maskbox from "../../components/maskbox";


import Maskboxplay from "../../components/maskboxplay";
import Coursedetail from "../../components/coursedetail";

import Onlinetalk from '../../components/onlinetalk';//讨论区
import Coursedata from '../../components/coursedata';//资料下载
import Onlinexam from '../../components/onlinexam';//考试
import ExamResults from '../../components/examresults/index.jsx';//考试结果
import Asklist from '../../components/asklist';//提问列表
import Swiper from 'swiper'

import ClassList from '../../components/classlist'
import { encrypt, decrypt } from '../../utils/crypto.ts'



import Scorelayer from '../../components/scorelayer2023';

import './index.css'

class BroadCast extends Component {
  constructor(props) {
    super(props);
    this.set_examcomplete = this.set_examcomplete.bind(this);
    this.wechetseeBigIMg = this.wechetseeBigIMg.bind(this);
    //this.touchStart = this.touchStart.bind(this);

    this.goingplay = this.goingplay.bind(this);
    this.restartplay = this.restartplay.bind(this);

    


    this.gotocertificate = this.gotocertificate.bind(this);
    // this.state = {
    //   askactive: false,
    //   active: "1",
    //   video: null,
    //   videoplay: false,
    //   currentTime: "--:--",
    //   duration: "--:--",
    //   duralltime: 0,
    //   precent: 0,
    //   lovepeople: 546, //点赞人数
    //   isclicklove: false, //是否点赞
    //   islove: false, //是否收藏
    //   title: "",
    //   documentimg: "",
    //   // 在线互动列表
    //   onlinelist: [],
    //   // 课程资料
    //   coursedata: [],
    //   // 在线考试
    //   examlist: [],
    //   // 提问列表
    //   videoquestion: [],
    //   videopointactive: 0,
    //   vdeopointact: false,
    //   examresults: false, // 是否显示考试结果
    //   examcomplete: ""
    // };
    this.state = {
      
      starttimenum:100,  //判断提前进入的时间还有多少秒
      starttime: 0,
      
      thisuserstate:false,
      thisreg:false,
      contactlist:[],
      chatime:0,
      issale:0,
      isloadingover:false,
      type: 3, //课程类型 2:直播 3： 录播
      iscroll: null,
      iscrolltext: null,
      isfirstlook:false,

      userstate: false,
      userinfo: null,

      touchx:0,
      touchy:0,
      isdrag:false,
      minoverwidth:1,
      thisvideotime:0,
      thislooktimes:0,
      thisvideostate:false,
      firststate:true,
      playstate:false,
      touchstate:false,
      maxlooktime:10,
      isstop:false,
      canclose:false,

      clearaddplaytime:'',



      subaddsore: true,
      allvideotime: 99990,

      videotitlelayer: false,//显示添加积分
      isscore:0,
      iskaoshi: false,




      attachmentcount: 0,
      prevstate: false,
      nextstate: false,
      thisover: false,
      isreloadvideo: 0,
      userid: '',

      pptactive: 1,
      fourtext: '0,',
      askactive: false, //提问列表展示
      active: "11", //导航切换
      lovepeople: 0, //点赞人数
      isclicklove: false, //是否点赞
      islove: false, //是否收藏
      title: "",
      alluserlooktime: 0,
      playvidelayer: false,
      iszhibo: 0,
      userqastate: 0,
      teacher_videopic:"",
      isvideostate: false,
      userqacount: 0,
      minlooktime: 0,
      ismasknew: false,
      lookstate: false,
      swiper: null,
      layerstate: false,
      usersubstate: true,
      thisname: '',
      thisvideolooktime: 0,
      
      videolooktime:0,


      thisnum: 0,
      isagreed: false,
      isconf: 0,
      thistextsubstate: false,
      addplaytime: null,
      thisvideoplaytime: 0,
      thisvideopptnames: [],
      changeexamstate: false,

      iswailayermast: false,//外部用户出现弹层判断
      surverinfostate: 0,

      layerimglist: [

      ],
      videostatetext: "正在直播中",
      documentimg: '',
      documentimgnew: '',
      documentimglist: '',
      // 在线互动列表
      onlinelist: [],
      // 课程资料
      coursedata: [

      ],
      // 在线考试
      examlist: [

      ],
      // 提问列表
      videoquestion: [
      ],
      // 课程详情
      information: {
      },
      // 打点
      videopoint: [],
      videopointactive: -1,
      vdeopointact: false,
      vdeopointstate: false,
      videohide: false,
      doc_screen: false,
      ismask: false,

      isifram:false,//判断是否为内嵌页面
      iframurl:'',//iframe链接


      
      scorelsyerstate:false,
      scorelsyerlist:{
        score:"",
        content:"",
      },

    }
  }
  set_examcomplete(data) {
    this.setState({
      examcomplete: data
    });
  }


  // 跳转海报页面
  gotocertificate() {
    this.props.history.push("/certificatedata" + this.props.location.search);
  }
  // 点赞
  clickLove() {
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/addlove?userid=" +
        this.props.userinfo.userid +
        "&courseid=" +
        this.classidcode +
        "&type=1"+"&iscs="+this.iscs,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
    }).then(res => {
      if (res.status === 1) {
        this.setState({
          lovepeople: !this.state.isclicklove
            ? this.state.lovepeople + 1
            : this.state.lovepeople - 1,
          isclicklove: !this.state.isclicklove
        });
      }
    });
  }
  //加入课程
  clickMylove() {
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/addlearningplan?userid=" +
        this.props.userinfo.userid +
        "&courseid=" +
        this.classidcode +
        "&type=1"+"&iscs="+this.iscs,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
    }).then(res => {
      if (res.status === 1) {
        this.setState({
          islove: !this.state.islove
        });
      }
    });
  }


  wechetseeBigIMg() {
    //console.log(this.state.documentimglist.length)
    var newa = [];
    if (this.state.documentimglist.length > 0) {
      for (var i = 0; i < this.state.documentimglist.length; i++) {
        newa.push({
          img: this.state.documentimglist[i],
        })
      }
    }
    this.setState({
      layerimglist: newa,
      layerstate: true,
      prevstate: true,
      attachmentcount: this.state.documentimglist.length,

    })
    //console.log(this.state.documentimglist)
  }
  // 打点跳转
  playvideo = () => {
    fetch(
      "https://shl-info.cn/front/frontc/addlogs?userid=" +
      localStorage.getItem('userid') +
      "&urlstr=" +
      encodeURI(window.location.href) +
      "&title=" +
      document.title +
      "&keywords=播放视频" +
      "&types=playvideo" +
      "&typeids=" +this.classid+
      "&isspecial="+localStorage.getItem('special')+
      "&sourcechannel="+localStorage.getItem('sourcechannel')
    )
    //console.log(this)
    if(!this.state.isloadingover){
      return
    }
    
    this.addusertags();
    console.log()
    let coursemask = null;
    if (!this.state.isagreed && this.state.isconf === 1 && this.state.type == 3) {
      //console.log(11111)
     
      this.setState({
        ismasknew: true,
      })
      return false;
    }
    //console.log(this.props.userinfo.info)

    fetch(
      "https://shl-info.cn/front/frontc/addzhiqulogs?userid=" +
      this.props.userinfo.userid +
      "&coursetitle=" +
      this.state.title+
      "&usertel=" +
      this.props.userinfo.info.phone+
      "&types=" +
      this.props.userinfo.info.type,{
        headers:{
          'token':this.props.userinfo.token
        }
      }
    )
   
    //console.log(this.vhallplayer)
    if (this.vhallplayer) {
      this.vhallplayer.play();
      
      console.log()
      console.log()
      this.setState({
        thisvideotime:this.vhallplayer.getDuration(),
        thislooktimes:this.vhallplayer.getCurrentTime(),
      })

      $('.vhallPlayer-container').show();
      // let outhtml="<div class='vhallPlayer-container'>"+$('.vhallPlayer-container').html()+"</div>"
      // $(".video-right-top").append(outhtml);
      // console.log(this.state.teacher_videopic)
      // console.log(this.state.iszhibo)
      // console.log(this.state.type)
      if(this.state.teacher_videopic !="" && this.state.teacher_videopic !="https://shl-info.cn/"  && this.state.teacher_videopic !="null" && this.state.iszhibo ==1 && (this.state.type == 3 || this.state.type == 2) ){
        $('.videoimgback').show()
        //$('.playvide_layer').css("background","none")
      }else{
        $('.videoimgback').hide()
        //$('.playvide_layer').css("background","none")
      }
      // $('.videoimgback').show();
      //console.log(33333)
      this.addplaytime();
      this.ajaxuserlooktime();

      
      //添加新积分
      this.addscorenew();

      
      this.setState({
        playvidelayer: true,
      })
    }
  }


  // 提问
  showAsk() {
    this.setState({
      askactive: !this.state.askactive,
      vdeopointact: false,
    });
  }
  // 切换导航
  changeVideoNav(id, aindex) {
    //console.log('查看ID', id, aindex)
    if (aindex == 'yes') {
      this.setState({
        active: id,
      });
    } else {
      this.setState({
        active: id,
        thisover: false,
      });
    }

  }
  // 切换屏幕
  switchScreen = () => {
    this.setState({
      doc_screen: !this.state.doc_screen
    });
  };
  // 显示考试结果
  gotoresult() {
    this.setState({
      examresults: !this.state.examresults
    });
  }
  // 修改考试
  changeExams = arr => {
    this.setState({
      examlist: arr
    });
  };
  // 修改资料
  changeCoursedata = arr => {
    this.setState({
      coursedata: arr
    });
  };


  showvideopoint() {
    //console.log()
    if (this.state.videopoint.length > 0) {
      this.setState(
        {
          vdeopointact: !this.state.vdeopointact,
          askactive: false,
        }
      );

    } else {
      this.setState(
        {
          //vdeopointstate: true,
        }
      );
    }

  }
  // 修改聊天
  changeTalk = arr => {
    // 发送消息
    this.socket.send(JSON.stringify(arr));
  };
  asksuccess = () => {
    this.setState({
      ismask: true
    });
  };
  closeMask = () => {
    this.setState({
      ismask: false,
      thistextsubstate: false,
      ismasknew: false,
      iswailayermast: false,
      isreloadvideo: 0,
      playstate:false,
      touchstate:false,
    });
  };
  
  closeMaskvideo = () => {
    if(!this.state.canclose){
      return
    }
    this.setState({
      ismask: false,
      thistextsubstate: false,
      ismasknew: false,
      iswailayermast: false,
      isreloadvideo: 0,
      playstate:false,
      touchstate:false,
      canclose:false,
    });
    
    this.vhallplayer.setCurrentTime(this.state.thisvideoplaytime);
    this.vhallplayer.play();
    
    $('.vhallPlayer-progress-box').show();
  };
  gotosurverurl = () => {
    window.open('https://jinshuju.net/f/jTEorO')
  }

  swiperInit() {
    let that = this;
    that.setState({
      swiper: new Swiper('.swiper-container', {
        observer: true,//修改swiper自己或子元素时，自动初始化swiper 
        observeParents: false,//修改swiper的父元素时，自动初始化swiper 
        onSlideChangeEnd: function () {
          that.state.swiper.update();
        }
      })
    })
  }
  recoreseenum = (id, item) => {
    //console.log(item)



    window.addreadlogsfiles(item.title, id)

    let that = this
    var thisnum = item.attachmentcount;

    var thisname = item.attachmentname;
    var newa = [];
    if (thisnum > 0) {
      for (var i = 0; i < thisnum; i++) {
        newa.push({
          img: 'https://shlinfo.oss-cn-shanghai.aliyuncs.com/static/upload/pptpic/' + thisname + '__' + (i + 1) + '.png?1',
        })
      }
    }
    //
    // nextEl: '.swiper-button-next',
    // prevEl: '.swiper-button-prev',
    this.setState({
      layerimglist: newa,
      layerstate: true,
      prevstate: true,
      attachmentcount: thisnum,

    })

    this.state.swiper.update();
    //console.log(newa)

    // $.ajax({
    //   type: "GET",
    //   url:
    //     "https://shl-info.cn/front/frontc/addattachcount?userid=" +
    //     this.props.userinfo.userid +
    //     "&token=" +
    //     this.props.userinfo.token +
    //     "&attachid=" +
    //     id
    // });
  }
  //添加视频播放时长
  ajaxuserlooktime() {
    let that = this
    ////console.log(that)
    if (this.vhallplayer) {
      let videostate = that.vhallplayer.getIsPause();
      console.log(videostate)
      console.log(that.vhallplayer.getCurrentTime())
      console.log("getCurrentTime")
      if (that.props.userinfo.userid) {
        let aplay = setTimeout(function () {
          if(!videostate){
            fetch(
              "https://shl-info.cn/front/frontc/addvideolooktime?userid=" +
              localStorage.getItem('userid') +
              "&courseid=" +
              that.classid +
              "&userlooktime=" +
              that.state.thisvideolooktime+
              "&userthislooktime=" +
              that.state.thisvideoplaytime
              // that.vhallplayer.getCurrentTime()
              ,{
                headers:{
                  'token':localStorage.getItem('token')
                }
              }
            )
          }
          that.ajaxuserlooktime();
        }, 5000)
        that.setState({
          addplaytime: aplay,
        })
      }
    }
    
  }

  closescorelayer = () =>{
    this.setState({
      scorelsyerstate:false
    })
  }
  addscorenew = ()=>{
    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Scoreshop/setScore?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      data: JSON.stringify({
        userid: localStorage.getItem('userid'),
        type: 3,
        courseid:this.classid,
        attachid:'',
        bbsid:'',
      }),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          this.setState({
            scorelsyerlist:res.data,
            scorelsyerstate:true,
          })
        }
        
        //console.log(res)
      });
  }


  addplaytime = () => {
    let that = this
    //  //console.log(11111111111)
    if (this.vhallplayer) {
      
     
      let videostate = this.vhallplayer.getIsPause();
      console.log(videostate)
      console.log("videostate")

      var failure = function (event) {
        //console.log(1);
      };
      let aaa = this.vhallplayer.getCurrentTime(failure);
      console.log(aaa)
      console.log(that.state.thisvideoplaytime)
      console.log(that.state.maxlooktime)
      console.log(aaa-that.state.thisvideoplaytime)
      console.log(aaa-that.state.thisvideoplaytime)

      console.log(that.state.isstop)
      let time =  this.state.allvideotime - this.state.maxlooktime 
      this.setState({
        chatime:time
      })
      console.log(this.state.maxlooktime)
      console.log(this.state.allvideotime)
      console.log(time)
      if( this.state.issale==1 && this.state.maxlooktime < this.state.allvideotime  ){
        console.log(1111)
        
        if(this.state.isstop || !this.state.isfirstlook){
          aaa = that.state.thisvideoplaytime
        }
        this.setState({
          isfirstlook:true,
        })
        // $('.vhallPlayer-progress-box').show();
        if(!videostate && aaa>10){
          if($('.vhallPlayer-progress-box').css('display') == 'none'){
            $('.vhallPlayer-progress-box').show();
          }
          
          console.log(aaa)
          if((aaa-that.state.maxlooktime)>6){
            
            this.vhallplayer.setCurrentTime(that.state.thisvideoplaytime+1);
            this.vhallplayer.pause();
            this.vhallplayer.setCurrentTime(that.state.thisvideoplaytime+1);
            
            console.log(this.vhallplayer.getCurrentTime())
            console.log('拖动了1')
            this.setState({
              touchstate:true,
              isstop:true,
            })
            setTimeout(()=>{
              this.setState({
                canclose:true
              })
            })
          } else if ((aaa - that.state.thisvideoplaytime) > 6 && aaa > (that.state.maxlooktime + 6)) {
              // 如果当前时间超过最大观看时间超过6秒，并且超过上次播放时间超过6秒，进行以下操作
              this.vhallplayer.setCurrentTime(that.state.thisvideoplaytime + 1); // 设置播放器时间
              this.vhallplayer.pause(); // 暂停播放器
              this.vhallplayer.setCurrentTime(that.state.thisvideoplaytime + 1); // 再次设置播放器时间，确保时间更新
              
              console.log('拖动了'); // 控制台输出拖动操作
              this.setState({
                touchstate: true, // 设置触摸状态为true
                isstop: true, // 设置停止状态为true
              });
              this.setState({
                canclose: true // 设置可以关闭状态为true
              });
          } else {
              // 如果当前时间没有超过最大观看时间或者没有超过上次播放时间超过6秒
              if (aaa > that.state.maxlooktime) {
                // 如果当前时间大于最大观看时间，更新播放时间和最大观看时间
                that.setState({
                  thisvideoplaytime: parseInt(aaa),
                  maxlooktime: parseInt(aaa),
                  isstop: false, // 设置停止状态为false
                });
              } else {
                // 如果当前时间不大于最大观看时间，只更新播放时间
                that.setState({
                  thisvideoplaytime: parseInt(aaa),
                  isstop: false, // 设置停止状态为false
                });
              }
          }
        } else {
          if(aaa<10){
            if(videostate){
              $('.vhallPlayer-progress-box').hide();
            }else{
              $('.vhallPlayer-progress-box').show();
            }
            that.setState({
              thisvideoplaytime: parseInt(aaa),
            })
            if(aaa>that.state.maxlooktime){
              that.setState({
                maxlooktime: parseInt(aaa),
              })
            }
          }else{
            $('.vhallPlayer-progress-box').hide();
          }
          that.setState({
            // thisvideoplaytime: parseInt(aaa),
            isstop:false,

          })
        }

      }else{
        that.setState({
          thisvideoplaytime: parseInt(aaa),
        })
      }



      // console.log("addplaytime")
      
      ////console.log(that.state.thisvideopptnames.length)
      for (var i = 0; i < that.state.thisvideopptnames.length; i++) {
        ////console.log(that.state.thisvideopptnames[i].times, aaa)
        if (that.state.thisvideoplaytime >= that.state.thisvideopptnames[i].times) {
          that.setState({
            documentimgnew: that.state.thisvideopptnames[i].pptnames,
            //thisvideoplaytime: that.state.thisvideopptnames[i].pptnames,
          })
        }
      }
      //console.log(that.state.thisvideopptnames)
      if (videostate) {
        //console.log(222)
        that.setState({
          thisvideostate:false
        })
      } else {
        
        that.setState({
          thisvideostate:true
        })
        var useralluserlooktime = that.state.alluserlooktime + 1;
        //console.log('添加播放时间', useralluserlooktime)
        // useralluserlooktime = 0;
        //console.log(this.state.userinfo)
        if (useralluserlooktime % 300 === 0 && useralluserlooktime != 0 && this.state.surverinfostate == 1) {
          ////console.log('300的倍数测试出现弹层')
          // //console.log('添加播放时间', useralluserlooktime)
          // //console.log(this.state.userinfo)
          if (this.state.userinfo) {
            if ((this.state.userinfo.type == 1 || this.state.userinfo.type == 2 || this.state.userinfo.type == 3 || this.state.userinfo.type == 5) && this.state.userinfo.id > 0) {
              that.setState({
                iswailayermast: true,
              })
            }
          }

        };
        that.setState({
          alluserlooktime: useralluserlooktime,
        })

        //添加播放时间
       //倍数 console.log()
       let playbackrate = this.vhallplayer.vhallplayer.getPlaybackRate()
       console.log(11111)
        that.setState({
          thisvideolooktime: this.state.thisvideolooktime + 1,
          videolooktime: this.state.videolooktime + 1,
        })
        //console.log('视频观看时长', this.state.thisvideolooktime)
        ////console.log(this.state.userinfo)
        // console.log(this.state.thisvideolooktime,that.state.allvideotime,this.state.subaddsore)
        // console.log("this.state.thisvideolooktime,that.state.allvideotime")
        //判断播放时间为一半后抛送记录，需要判断返回值。并且只抛送一次 ，并且显示弹层 
        if ((that.state.allvideotime / 2 <= this.state.thisvideolooktime && this.state.subaddsore) || (that.state.iszhibo == 1 && this.state.subaddsore)) {
          //allvideotime
          that.setState({
            subaddsore: false,
          })
          console.log(1111111111111)
          if(that.state.isscore!=1){
            that.setState({
              videotitlelayer: true,
            })
            setTimeout(function () {
              that.setState({
                videotitlelayer: false,
              })
            }, 2000)
          }
          //询问是否会又有选修，又是直播的存在
          var type = 2;
          if (that.state.iscomp == 1) {
            type = 3;
          }
          if (that.state.iszhibo == 1) {
            type = 4;
          }
          // $.ajax({
          //   type: "POST",
          //   url:
          //     "https://shl-info.cn/front/score/addlog?userid=" +
          //     localStorage.getItem('userid') +
          //     "&usertype=" +
          //     localStorage.getItem('usertype') +
          //     "&iscomp=" +
          //     that.state.iscomp,
          //   data: JSON.stringify({
          //     scoreid: type,
          //     userid: localStorage.getItem('userid'),
          //     usertype: localStorage.getItem('usertype'),
          //     courseid: that.classid
          //   }),
          //   dataType: "json",
            
          //   headers:{'token':localStorage.getItem('token')},
          //   cache: false
          // })
          //   .catch(error => {
          //     //console.log(error);
          //   })
          //   .then(res => {
          //     //console.log(res)
          //   });
        }
        //添加该视频观看完一半添加积分
        //allvideotime
        // $.ajax({
        //   type: "POST",
        //   url:
        //     "https://shl-info.cn/front/score/addlog?userid=" +
        //     localStorage.getItem('userid') +
        //     "&token=" +
        //     localStorage.getItem('token') +
        //     "&usertype=" +
        //     localStorage.getItem('usertype'),
        //   data: JSON.stringify({
        //     scoreid: 1,
        //     userid: localStorage.getItem('userid'),
        //     usertype: localStorage.getItem('usertype')
        //   }),
        //   dataType: "json",
        //   cache: false
        // })
        //   .catch(error => {

        //     //console.log(error);
        //   })
        //   .then(res => {
        //     //console.log(res)
        //   });


        if(this.state.issale==1 ){
          if( this.state.maxlooktime >= this.state.allvideotime-6){
            if(!that.state.lookstate){
              fetch(
                "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
                  method: 'POST',
                  body: JSON.stringify({
                    coursecode:this.classidcode,
                    userid:localStorage.getItem('userid'),
                    clicktype:3,
                    iscert:1,
                  }),
                  headers:{
                    'content-type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                  header: {
                    'content-type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                  console.log(res)
              })
            }
    
            that.setState({
              lookstate: true,
            })
            if (!this.state.isvideostate) {
              if (this.state.examlist.length > 0) {
                this.changeVideoNav("3")
              }
              this.setState({
                isvideostate: true,
              })
            }
          }
        }else{
          if (this.state.thisvideolooktime >= this.state.minlooktime) {
            console.log(that.state.lookstate)
            if(!that.state.lookstate){
              fetch(
                "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
                  method: 'POST',
                  body: JSON.stringify({
                    coursecode:this.classidcode,
                    userid:localStorage.getItem('userid'),
                    clicktype:3,
                    iscert:1,
                  }),
                  headers:{
                    'Content-Type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                  header: {
                    'Content-Type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                  console.log(res)
              })
            }
            that.setState({
              lookstate: true,
            })
            if (!this.state.isvideostate) {
              if (this.state.examlist.length > 0) {
                this.changeVideoNav("3")
              }
              this.setState({
                isvideostate: true,
              })
            }
          }
        }
        //console.log(this.state.thisvideolooktime)
      }
    }

   let clearaddplaytimeinfo =  setTimeout(function () {
      that.addplaytime();
    }, 1000)
    that.setState({
      clearaddplaytime:clearaddplaytimeinfo
    })
  }
  // 初始化视频容器
  getvideo = target => {
    let self = this;
    //console.log(target)
    //console.log(22222222222222222222222)
    if (target.type === 3) {
      //播放视频列表视频
      let opt = {
        appId: "b24f7dc9", // 应用ID，必填
        accountId: target.userid, // 第三方用户ID，必填
        token: target.vhall_token, // access_token，必填
        type: "vod", // live 直播  vod 点播  必填
        videoNode: "video", // 播放器的容器， div的id 必填
        poster: target.teacher_videopic?target.teacher_videopic:"", // 封面地址  仅支持.jpg
        vodOption: {
          recordId: target.videoid // 回放 ID
        }
      };
      // 成功回调
      let success = function (event) {
        // //console.log(event);
        self.vhallplayer = event.vhallplayer;
        self.vhallplayer.pause();
        self.vhallplayer.setCurrentTime(0);

        self.vhallplayer.on(window.VhallPlayer.LOADED, function () {
          console.log(3333,'LOADED')
          var video = document.getElementsByTagName("video")[0];
          console.log(video)
          video.currentTime = 0;
          self.vhallplayer.setCurrentTime(0);
        })
        //console.log("成功后不自动播放");
        self.vhallplayer.on(window.VhallPlayer.ENDED, function () {
          console.log('播放完成')
          self.vhallplayer.pause();
          $.ajax({
            type: "GET",
            url:
              "https://shl-info.cn/front/frontc/updtecoursestatus?userid=" +
              self.props.userinfo.userid +
              "&courseid=" +
              self.classid +
              "&type=1"+"&iscs="+this.iscs,
            dataType: "json",
            headers:{'token':self.props.userinfo.token},
          })
          if(self.state.issale==1){
            self.setState({
              lookstate: true,
              maxlooktime: self.state.allvideotime,
            })
            // let looktime = self.state.thisvideolooktime
            // if(looktime<self.state.minlooktime){
            //   looktime = self.state.thisvideolooktime
            // }
            fetch(
              "https://shl-info.cn/front/frontc/addvideolooktime?userid=" +
              self.props.userinfo.userid +
              "&courseid=" +
              self.classid +
              "&userlooktime=" +
              self.state.thisvideolooktime+
              "&userthislooktime=" +
              self.state.allvideotime
              // self.vhallplayer.getCurrentTime()
              ,{
                headers:{
                  'token':localStorage.getItem('token')
                }
              }
            )

          }

        })
      };
      // 失败回调
      let failure = function (event) {
        //console.log(event);
      };
      // 创建实例
      if (window.VhallPlayer) {
        window.VhallPlayer.createInstance(opt, success, failure);
      }
    } else {
      $('.video-right-top-lines').hide();
      let opt = {
        appId: "b24f7dc9", // 应用ID，必填
        accountId: target.userid, // 第三方用户ID，必填
        token: target.vhall_token, // access_token，必填
        type: "live", // live 直播  vod 点播  必填
        videoNode: "video", // 播放器的容器， div的id 必填
        poster: target.teacher_videopic?target.teacher_videopic:"", // 封面地址  仅支持.jpg
        liveOption: {
          type: "hls", // 直播播放类型  hls | flv
          roomId: target.roomid // 直播房间ID
        }
      };
      //console.log(opt)
      // 成功回调
      let success = function (event) {
        self.vhallplayer = event.vhallplayer;
        self.vhallplayer.pause();
        // //console.log(event);
      };
      // 失败回调
      let failure = function (event) {
        //console.log(event);
      };
      // 创建实例
      if (window.VhallPlayer) {
        window.VhallPlayer.createInstance(opt, success, failure);
      }
    }
  };
  // socket
  getsocket = () => {
    this.socket = new ReconnectingWebSocket(
      "wss://shl-info.cn/webwstwo/"
    );
    this.socket.onopen = () => {
      //console.log("open");
      this.socket.send(
        JSON.stringify({
          messagetype: "link",
          user_id: this.props.userinfo.userid,
          type_id: "1",
          username: this.props.userinfo.info.name,
          course_id: this.classid,
          avatar_url: this.props.userinfo.pic
        })
      );
    };
    this.socket.onmessage = message => {
      //console.log("message");
      //console.log(message);
      let talkarr = this.state.onlinelist;
      let newdata = JSON.parse(message.data);
      if (newdata.type === "message") {
        if (newdata.user_id === this.props.userinfo.userid) {
          newdata.type_id = "3";
        }
        talkarr.push(newdata);
        this.setState({
          onlinelist: talkarr
        });
      } else if (
        newdata.type === "document" &&
        newdata.course_id === this.classid
      ) {
        this.setState({
          documentimg: newdata.pic
        });
      }
    };
    this.socket.onclose = () => {
      //console.log("close");
    };
  };

  touchStartbegin(e) {
    let thisallwidth=$(".video-right-top").width();
    let thissec=thisallwidth/this.state.thisvideotime;
    let nowthis=e.clientX-$('.video-right-top-lines')[0].getBoundingClientRect().left;
    let gowidth = nowthis/thisallwidth*100;
    let gowidthbi = nowthis/thisallwidth;

    let playtime=this.state.thisvideotime*gowidthbi
    //设置播放时间
    this.vhallplayer.setCurrentTime(playtime);

    if(gowidth>=100){
      gowidth=100
    }
    this.setState({
      minoverwidth:gowidth
    })
    console.log(this.state.isdrag)
    this.setState({
      touchx:e.clientX,
      touchy:e.clientY,
      isdrag:true,
    })
    console.log(e.clientX)
    // console.log(e.clientY)
    // let x = e.touches[0].pageX;
    // let y = e.touches[0].pageX;
  }
  touchmovebegin = (e) => {
    let x=this.state.touchx
    let y=this.state.touchy


    let nowx=e.clientX
    //console.log(e.clientX)
    let nowy=e.clientY
    // console.log(x,y)
    // console.log(nowx,nowy)
    
    
    if (this.state.isdrag) {
      let thisallwidth=$(".video-right-top").width();
      let thissec=thisallwidth/this.state.thisvideotime;
      let nowthis=e.clientX-$('.video-right-top-lines')[0].getBoundingClientRect().left;
      let gowidth = nowthis/thisallwidth*100;
      let gowidthbi = nowthis/thisallwidth;

      let playtime=this.state.thisvideotime*gowidthbi
      //设置播放时间
      this.vhallplayer.setCurrentTime(playtime);
      if(gowidth>=100){
        gowidth=100
      }
      this.setState({
        minoverwidth:gowidth
      })
    }
  }
  touchendbegin = (e) => {
    console.log(3333)
    this.setState({
      isdrag:false,
    })
  }


  componentDidMount() {
    this.classid = this.props.location.search.split("&")[0].slice(6);
    let thisover = this.props.location.search.indexOf('viewresults=1');
    //console.log(thisover)
    this.iscs = 0;
    if(window.location.href.indexOf('iscs=1')>=0){
      this.iscs = 1
    }

    // $('.video-right-top-icon').each(function (i) {
    //   console.log(11122223333)
    //   $('.video-right-top-icon')[i].addEventListener('touchstart', this.touchStart);
    //   $('.video-right-top-icon')[i].addEventListener('touchmove', this.touchMove);
    //   $('.video-right-top-icon')[i].addEventListener('touchend', this.touchend);
    // });

    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    this.setState({
      userid: userid,
    })
    let that = this

    let thisuserid = userid;
    if (userid && token) {
      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          //console.log(res)
          //console.log(22222222222)
          if (res.status === 1) {

            var userlist = res.list;
                        if(userlist.name){
                            userlist.name = decrypt(userlist.name);
                        }
                        if(userlist.email){
                            userlist.email = decrypt(userlist.email);
                        }
                        if(userlist.phone){
                            userlist.phone = decrypt(userlist.phone);
                        }
                        res.list = userlist
            that.setState({
              userstate: true,
              userinfo: res.list,
            })
            // localStorage.setItem("userid", userid);
            // localStorage.setItem("token", res.token);
            // stateProps.change_userinfo({
            //   token: res.token,
            //   pic: res.pic,
            //   userid: userid,
            //   info: res.list ? res.list : stateProps.userinfo.info
            // });
          } else {
            //this.history.push("/");
          }
        }
      });
    }
    


    fetch(
    "https://shl-info.cn/front/course/getDetailInfo?courseid=" +
    this.classid ,{
      headers:{
        'token':this.props.userinfo.token
      }
    }
  )
    .then(data => {
      return data.json();
    })
    .then(res => {
        console.log(res)
        this.setState({
          contactlist:res
        })
    })
    if(!userid){
      if(!localStorage.getItem('visitor_num')){
        let user_medical_num="visitor_"+ Math.floor(Math.random()*10000000000);
        localStorage.setItem("visitor_num",user_medical_num)
        userid=user_medical_num
      }else{
        userid=localStorage.getItem('visitor_num')
      }
    }
      // 获取数据
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/coursedetail?watch=1&userid=" +
        userid +
        "&courseid=" +
        this.classid+
        "&iscs=" +
        this.iscs,
      dataType: "json",
      headers:{'token':token},
      cache: false
    }).then(res => {
      console.log(res)
      console.log("res1111")
      //return false;
      // this.setState({
      //   loadingstate:true,
      // })
      if (res.code != 200) {
       
        console.log(2222222)
        //localStorage.setItem('userid','');

        this.setState({
          isloadingover:true,
        })
        if (res.isout == 1) {
          //console.log(111111)
          //this.props.history.push('/');
        } else {
          //console.log(33333333)
          this.props.history.push('/');
        }
        //return false;
      }
      if (res && res.status !== 2 ) {
        //res.minlooktime=10;

        //新加判断529
        console.log(that.state.userinfo)
        //console.log(res)
        let usertype = '';
        if (that.state.userinfo) {
          usertype = that.state.userinfo.type
        }

        let userid = localStorage.getItem('userid');
        //console.log(usertype)
        //console.log(userid)
        //console.log(res.isout)
        //console.log(res.isvip)
        //console.log(res.isregover)
        // return false;
        //已判断直接跳走
        // if (this.state.information.isout!=1 && !userid){
        //   this.setState({
        //     thisuserstateadd: true,
        //   })
        // }
        if (usertype == 1 && (res.isregover == 1 || res.isvip == 1 || res.isout != 1)  && this.iscs!=1) {
          that.setState({
            thisuserstateadd: true,
          })

          that.props.history.push('/');
          return false;
        }
        if (usertype == 2 && (res.isregover == 1 || res.isvip == 1 || res.isout != 1)  && this.iscs!=1) {
          that.setState({
            thisuserstateadd: true,
          })
          that.props.history.push('/');
          return false;
        }
        if ((usertype == 2 || usertype == 5) && (res.isvip == 1 || res.isout != 1)  && this.iscs!=1) {
          that.setState({
            thisuserstateadd: true,
          })
          that.props.history.push('/');
          return false;
        }




        if ((usertype == 1 || usertype == 2 || usertype == 3 || usertype == 5) && (res.isout != 1)  && this.iscs!=1) {
          that.setState({
            thisuserstateadd: true,
          })
          //alert('暂没有权限 ');
          that.props.history.push('/');
          return false;
        }

        var aaa = 111;
       
        if (!usertype && usertype !== 0 && (res.isvip == 1 || res.isregover == 1 || res.isout != 1 || res.isreg == 1)  && this.iscs!=1) {
          console.log('没有身份')
          this.setState({
            thisuserstateadd: true,
          })
          that.props.history.push('/');
          return false;
        }

        
        if(!usertype && usertype!=0  && this.iscs==1){
          console.log("test"+111111)
          this.setState({
            thisuserstateadd: true,
          })
          that.props.history.push('/');
          return false;
        }
        console.log(res)
       
        if(this.iscs==1 && res.coursepay ==2 && res.islock !=1){
          
          console.log("test"+222222)
          this.setState({
            thisuserstateadd: true,
          })
          that.props.history.push('/');
          return false;
        }


        //console.log('新加判断结束529')
        //新加判断结束529

        //console.log(22222)
        //console.log(res.teacher_videopic)
        //console.log(33333)
          console.log(111222)
          if (res.code == 200) {
            console.log(111222)
            that.setState({
              thisuserstate: true,
            })
          }
        that.classidcode= res.coursecode
        that.setState({
          
          starttimenum:res.starttimenum,
          thisuserstate: true,
          thisreg: res.signup,
          information: res,
          isloadingover:true,
          type: res.type,
          lovepeople: res.lovepeople,
          isconf: res.isconf,
          isclicklove: res.isclicklove, //是否点赞
          islove: res.islove, //是否收藏
          title: res.title,
          coursedata: res.coursedata,
          videoquestion: res.videoquestion,
          examlist: res.examlist,
          onlinelist: res.chatroom,
          isreloadvideo: res.isreloadvideo,
          userqastate: res.userqastate,
          userqacount: res.userqacount,
          documentimg: res.pptnum,
          teacher_videopic: res.teacher_videopic,
          documentimglist: res.pptlist,
          videopoint: res.videopoint,
          isagreed: res.agreed,
          iscomp: res.iscomp,
          fourtext: res.userselectlist,
          
          allvideotime: res.allvideotime,
          minlooktime: res.minlooktime,
          thisvideopptnames: res.videopptnames,
          iszhibo: res.iszhibo,
          thisvideolooktime: res.userlooktime,
          thisvideoplaytime:res.userthislooktime,
          // maxlooktime:res.userlooktime>res.userthislooktime?res.userlooktime:res.userthislooktime,
          // maxlooktime:res.userlooktime>res.userthislooktime?res.userlooktime:res.userthislooktime,
          maxlooktime:res.usermaxlooktime?res.usermaxlooktime:0,
          alluserlooktime: res.alluserlooktime,
          surverinfostate: res.surverinfostate,
          videolooktime:res.userthislooktime,
          learnlabel:res.learnlabel,
          isscore:res.isscore,
          issale:res.issale,
        },()=>{
          this.timeId = setInterval(() => {
            this.setTick();
          }, 1000);
        });
        console.log(res)
        if(res.userthislooktime>0 && res.userthislooktime){
          that.setState({
            playstate:true,
          })
        }
        if(res.isifram==1 && res.iframurl!=''){
          let iframurl=res.iframurl +"?userId="+localStorage.getItem('userid')+"&courseId=1"
          that.setState({
            isifram:true,
            iframurl:iframurl,
          })
        }
        //console.log(3333)
        //console.log(res)
        //console.log(4444)
        ////console.log('iskaoshi', res.course_type_list.indexOf('1'))

        // 注释掉新加判断是否有考试功能
        // if (res.course_type_list.indexOf('1') > -1) {
        //   that.setState({
        //     iskaoshi: true,
        //   })
        // }

        
        
        if (thisover > -1) {
          //console.log(66666)
          that.setState({
            thisover: true,
            active:"3",
          })
          // setTimeout(function () {
          //   that.changeVideoNav("3", "yes")
          // }, 400)
        } else {

          //console.log(7777)
          // if (res.coursedata.length > 0 && (res.isconf != 1 || res.type != 3)) {
          //   setTimeout(function () {
          //     that.changeVideoNav("2")
          //   }, 400)
          // }
          // if (res.type == 3 && res.isconf == 1) {
          //   setTimeout(function () {
          //     that.changeVideoNav("1")
          //   }, 400)
          // }
          // //console.log('changeVideoNav', 1111)
          // if (res.coursedata.length <= 0 && (res.isconf != 1 || res.type != 3) && res.examlist.length > 0) {
          //   //console.log('changeVideoNav', 3)
          //   setTimeout(function () {
          //     that.changeVideoNav("3")
          //   }, 400)
          // }
          // //console.log(res.coursedata.length, res.isconf ,)
          // if (res.coursedata.length <= 0 && (res.isconf != 1) && res.examlist.length <= 0) {
          //   setTimeout(function () {
          //     that.changeVideoNav("4")
          //   }, 400)
          // }

        }
        if(res.issale==1){
          console.log(5555)
          if( this.state.maxlooktime >= this.state.allvideotime-6){
            if(res.minlooktime==0 && localStorage.getItem('userid')){
              fetch(
                "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
                  method: 'POST',
                  body: JSON.stringify({
                    coursecode:that.classidcode,
                    userid:localStorage.getItem('userid'),
                    clicktype:3,
                    iscert:1,
                  }),
                  headers:{
                    'Content-Type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                  header: {
                    'Content-Type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                  console.log(res)
              })
            }
            this.setState({
              lookstate: true,
            })
          }
        }
        else{
          console.log(6666)
          console.log(res.userlooktime,res.minlooktime,12222)
          if (res.userlooktime >= res.minlooktime) {
            if(res.minlooktime==0 && localStorage.getItem('userid')){
              fetch(
                "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
                  method: 'POST',
                  body: JSON.stringify({
                    coursecode:that.classidcode,
                    userid:localStorage.getItem('userid'),
                    clicktype:3,
                    iscert:1,
                  }),
                  headers:{
                    'Content-Type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                  header: {
                    'Content-Type': 'application/json',
                    'token':localStorage.getItem('token')
                  },
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                  console.log(res)
              })
            }
            this.setState({
              lookstate: true,
            })
          }
        }
         
        
        if (res.agreed) {
          // this.setState({
          //   fourtext: "0,1,2,3,4,5,",
          // })
        }

        if (res.type == 2) {
          this.setState({
            doc_screen: !this.state.doc_screen
          });
        }
        //设置没有视频的课程直接切换PPT
        if(this.classid==1031){
          // this.setState({
          //   doc_screen: !this.state.doc_screen
          // });
        }

        if (res.type == 3) {
          this.setState({
            videostatetext: "正在回放中",
          })
          if (res.pptlist.length > 0) {

            this.setState({
              documentimg: res.pptlist[0],
            })
          }
        }

        // if (thisover > -1) {
        //   that.setState({
        //     thisover: true,
        //   })
        //   setTimeout(function () {
        //     that.changeVideoNav("3")
        //   }, 400)
        // }
        //console.log('初始化视频容器')
        // 初始化视频容器
        this.getvideo({ ...res.videoinfo, type: res.type });

      }
    })
    .fail(function(error) {
      // 请求失败的处理逻辑
      that.setState({
        isloadingover:true,
      })
    });;
    setTimeout(function () {
      //that.addplaytime();

      //请求后台循环添加播放时间
      // that.ajaxuserlooktime();
    }, 1000)

    this.swiperInit();
    // socket
    this.getsocket();
  }
  componentWillUnmount() {
    // this.socket.close();
    // if (this.vhallplayer){
    //   this.vhallplayer.destroy();
    // }
    
    this.setState({
      iscroll: null,
    });
    clearTimeout(this.state.addplaytime)
    clearTimeout(this.state.clearaddplaytime)
    console.log(222222,'销毁')
    this.socket.close();
    console.log(11111,'销毁')
    if (this.vhallplayer) {

      //解决mata标签不兼容注释
      //this.vhallplayer.destroy();
      var video = document.getElementsByTagName("video")[0];
      video.currentTime = 0;
      this.vhallplayer.setCurrentTime(0);
      // this.vhallplayer.destroy();
    }
  }
  stopvideoplay = ()=>{
    if(!this.state.firststate){
      let videostate = this.vhallplayer.getIsPause();
      console.log(videostate)
      if (videostate) {
        this.vhallplayer.play();
      } else {
        this.vhallplayer.pause();
      }
    }else{
      this.playvideo()
      this.setState({
        firststate:false
      })
    }
    
    
  }

  nextswi = () => {
    let thisswiindex = (this.state.swiper.activeIndex)
    //console.log(this.state.swiper)
    //console.log(thisswiindex)
    //console.log(this.state.attachmentcount)
    if (thisswiindex + 2 >= this.state.attachmentcount) {
      this.setState({
        nextstate: true,
      })
    }
    this.setState({
      prevstate: false,
    })
    this.state.swiper.slideNext();
  }
  prevswi = () => {
    let thisswiindex = (this.state.swiper.activeIndex)
    if (thisswiindex - 1 <= 0) {
      this.setState({
        prevstate: true,
      })
    }
    //console.log(thisswiindex)
    this.setState({
      nextstate: false,
    })
    this.state.swiper.slidePrev();
  }
  closelayer = () => {
    let that = this
    this.setState({
      layerstate: false,
      nextstate: false,
      prevstate: true,
    })
    //console.log(1111)
    this.state.swiper.slideTo(0, 100, false);
    // this.state.swiper.destroy();
  }
  changeexamstate = () => {
    this.setState({
      changeexamstate: true,
    })
  }
  subnewtext = () => {
    //提交用户点击已知晓确认按钮
    //console.log(this.state.fourtext.indexOf('1,'));
    //console.log(this.state.fourtext.indexOf('2,'));
    //console.log(this.state.fourtext.indexOf('3,'));
    //console.log(this.state.fourtext.indexOf('4,'));
    //let userselectlist="";
    if (this.state.fourtext.indexOf('1,') >= 0 || this.state.fourtext.indexOf('2,') >= 0 || this.state.fourtext.indexOf('3,') >= 0 || this.state.fourtext.indexOf('4,') >= 0) {
      if (!this.state.isagreed) {
        fetch(
          "https://shl-info.cn/front/frontc/useragreed?userid=" +
          this.props.userinfo.userid +
          "&courseid=" +
          this.classid +
          "&userselectlist=" +
          this.state.fourtext,{
            headers:{
              'token':this.props.userinfo.token
            }
          }
        )
          .then(data => {
            return data.json();
          })
          .then(res => {
            if (res['status'] == 1) {
              this.setState({
                isagreed: true,
              });
            }
          });
      }
    } else {
      this.setState({
        thistextsubstate: true,
      });

    }
    //console.log(22222)
  }
  fouractive = (e) => {
    let thistext = this.state.fourtext;
    thistext = thistext.toString();
    //console.log(e)
    if (this.state.isagreed) {
      return false;
    }
    ////console.log()
    if (thistext.indexOf(e) >= 0) {
      thistext = thistext.replace((e + ','), "");
    } else {
      thistext = thistext + e + ','
    }
    //console.log(thistext)
    this.setState({
      fourtext: thistext,
    });
  }


  videojump(time, index) {
    if (this.vhallplayer) {
      if (!this.vhallplayer.getDuration()) {
        this.vhallplayer.play()
      } else {
        this.vhallplayer.setCurrentTime(time)
        this.setState({
          videopointactive: index
        });
      }
    }
  }

  //给用户添加标签
  addusertags = ()=>{
    let that = this
    fetch(
      "https://shl-info.cn/front/frontc/addUserTaintLabelLog?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          labelid:this.state.learnlabel,
          userid:localStorage.getItem('userid'),
          courseid:this.classid,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
        console.log(res)
    })
  }

  restartplay (){
    console.log(2222)

    
    if( this.vhallplayer ){
      this.vhallplayer.setCurrentTime(0);
    }
    this.playvideo();
    this.setState({
      playstate:false,
      videolooktime:0,
    })
    // this.addusertags();
  }
  goingplay(){

    fetch(
      "https://shl-info.cn/front/frontc/addlogs?userid=" +
      localStorage.getItem('userid') +
      "&urlstr=" +
      encodeURI(window.location.href) +
      "&title=" +
      document.title +
      "&keywords=播放视频" +
      "&types=playvideo" +
      "&typeids=" +this.classid+
      "&isspecial="+localStorage.getItem('special')+
      "&sourcechannel="+localStorage.getItem('sourcechannel')
    )

    
    console.log(1111,this.state.videolooktime)
    this.setState({
      playvidelayer: true,
      playstate:false,
    })
    this.vhallplayer.setCurrentTime(this.state.videolooktime);
    this.vhallplayer.play();
    this.addplaytime();
    
      //添加新积分
      this.addscorenew();

    this.ajaxuserlooktime();
    $('.vhallPlayer-container').show();
    this.addusertags();
  }
  goshowdetail = () =>{
    this.setState({
      examresults:true
    });
  }


  gonewcourse = (item)=>{
    console.log(item)
    window.location.href="/website/broadcast?type="+item.id;
  }
  
  gobackgroup = ()=>{
    let groupcode = localStorage.getItem('jumpgroupcode');
    localStorage.setItem('jumpgroupcode','');
    console.log("/groupDetailinfo?groupids="+groupcode)
   this.props.history.push('/groupDetailinfo?groupids=' + groupcode);
  }

  adduserlooklive = () =>{
    fetch(
      "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          coursecode:this.state.information.coursecode,
          userid:localStorage.getItem('userid'),
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      
    })
  }
  gotoVideo = () => {

    console.log(this.state.userinfo)
    // console.log(this.state.information)
    // console.log(this.iscs)
    // console.log(this.state.information.type)
    let usertype = this.state.userinfo.type

    let userid = localStorage.getItem('userid');
    // console.log(usertype)
    // console.log(this.state.information.isout)
    // console.log(this.state.information.isvip)
    // console.log(this.state.information.isreg)
    // console.log(this.state.information.isregover)
    //已判断直接跳走
    // if (this.state.information.isout!=1 && !userid){
    //   this.setState({
    //     thisuserstateadd: true,
    //   })
    // }
    // console.log(this.state.information.type)
    window.addreadlogsfiles(this.state.information.title+"-按钮点击-"+this.state.information.type, '')
    this.adduserlooklive()


    //  this.setState({
    //   thisuserstateadd: true,
    //   thisuserstateaddtext: '未登录',
    // })
    // {this.state.information.type !== 3
    //   ? this.state.information.type !== 1
    //     ? (this.state.information.type !== 2 ? "" : "正在直播")
    //     : (this.state.thisreg ? "已报名" : "我要报名")
    //   : "进入课程"}

    if (usertype == 1 && this.iscs!=1 && (this.state.information.isregover == 1 || this.state.information.isvip == 1)) {
      
      console.log(112000000)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }
    if (usertype == 2 && this.iscs!=1 && (this.state.information.isregover == 1 || this.state.information.isvip == 1)) {
      console.log(1111111111)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }
    if ((usertype == 2 || usertype == 5)  && (this.state.information.isvip == 1)  && this.iscs!=1) {
      console.log(222222222)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }


    if (!usertype && usertype !== 0 && this.iscs!=1 && (this.state.information.isregover == 1 || this.state.information.isvip == 1 || this.state.information.isreg == 1)) {
      console.log(33333)
      this.setState({
        thisuserstateadd: true,
      })
      //alert('暂没有权限 ');
      return false;
    }


    if ((usertype == 1 || usertype == 2 || usertype == 3 || usertype == 5) && (this.state.information.isout != 1)  && this.iscs!=1) {
      console.log(44444)
      this.setState({
        thisuserstateadd: true,
      })
      //alert('暂没有权限 ');
      return false;
    }


    if (this.state.information.isvip != 1 && this.state.information.isregover != 1 && this.state.information.isout == 1  && this.iscs==1) {

    } else {
      
      console.log(55555)
      console.log(this.state.thisuserstate)
      if( !localStorage.getItem('userid') && this.state.information.iszhibo==1){
        this.setState({
          thisuserstateadd: true,
        })
        //alert('暂没有权限 ');
        return false;
      }else  if (!this.state.thisuserstate ) {
        this.setState({
          thisuserstateadd: true,
        })
        //alert('暂没有权限 ');
        return false;
      }
    }
    console.log(this.state.information,userid,this.iscs)
    if (!userid && this.state.information.type == 2  && this.iscs!=1 ) {
      console.log('usertype')
      
      console.log(6666)
      //没有登录
      this.setState({
        thisuserstateadd: true,
        thisuserstateaddtext: '未登录',
      })
      //alert('暂没有权限 ');
      return false;
    }
    console.log("测试状态1")
   // return false;

    if (this.state.information.type === 1) {
      if (this.state.thisreg) {
        //return false;
        if(this.state.information.beforetype!=1){
          if(this.state.information.beforetype ==2){
            fetch(
              "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
                method: 'POST',
                body: JSON.stringify({
                  coursecode:this.state.information.coursecode,
                  userid:localStorage.getItem('userid'),
                  clicktype:2,
                  iscert:1,
                }),
                headers:{
                  'content-type': 'application/json',
                  'token':localStorage.getItem('token')
                },
                header: {
                  'content-type': 'application/json',
                  'token':localStorage.getItem('token')
                },
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
                console.log(res)
            })
            this.adduserlooklive()

             //添加新积分
             this.addscorenew()
           }

           if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
            console.log('ismudu')
            //新加判断跳转旧目睹也需要手机号
            if(!encrypt(localStorage.getItem('phone'))){
              console.log(222)
              this.setState({
                isnophone:true,
              })
              return false;
            }
            fetch(
              'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                headers:{
                  'token':localStorage.getItem('token')
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              
            })
            fetch(
              "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
              localStorage.getItem('userid')+"&eventid="+ this.classid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              console.log(res)
              if(res.code==2000){
                let muduurl =res.data
                window.location.href=muduurl;
              }
            });
          } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
            console.log('ismudu')
            fetch(
              'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                headers:{
                  'token':localStorage.getItem('token')
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              
            })
            fetch(
              "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
              localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              console.log(res)
              if(res.code==2000){
                let muduurl =res.data
                window.location.href=muduurl;
              }
            });
          }else if(this.state.information.muduurl!=''  && this.state.information.muduurl!='null'  &&  this.state.information.muduurl!=null){
            window.open(this.state.information.muduurl)
          }else if(this.state.information.coursetype==1){
            window.open(this.state.information.courseurl)
          }else if (this.state.information.zhibotype ==1){
            if(!encrypt(localStorage.getItem('phone'))){
              console.log(222)
              this.setState({
                isnophone:true,
              })
              return false;
            }

            fetch(
              "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
              localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(reszhiqu => {
              if(reszhiqu.data){
               // let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+reszhiqu.data
                let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+reszhiqu.data
                //window.location.href=zhiquurls
                window.open(zhiquurls)
              }
              console.log(reszhiqu)
            });

          }else{

            if(this.state.information.videoinfo['videoid']){
              // this.props.history.push('/broadcast' + this.props.location.search);

              if(this.iscs !=1){
                // this.props.history.push('/broadcast' + this.props.location.search);
                this.playvideo();
              }else if((this.state.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
              //}else if((this.state.information.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
                // this.props.history.push('/broadcast' + this.props.location.search);
                this.playvideo();
              }else{
                
                this.gotounlock()
              }
            }
           
          }
         
        }
      } else {
        // 报名
        if (this.state.information.zhibotype ==1){
          if(!encrypt(localStorage.getItem('phone'))){
            console.log(222)
            this.setState({
              isnophone:true,
            })
            return false;
          }
        }
        console.log(this.state.information.title)
        //抛送致趣日志
        fetch(
          "https://shl-info.cn/front/frontc/addzhiqusignlogs?userid=" +
          this.props.userinfo.userid +
          "&coursetitle=" +
          this.state.information.title+
          "&usertel=" +
          this.props.userinfo.info.phone+
          "&types=" +
          this.props.userinfo.info.type
          ,{
            headers:{
              'token':this.props.userinfo.token 
            }
          }
        )

        //return false
        $.ajax({
          type: "GET",
          url:
            "https://shl-info.cn/front/frontc/coursesignup?userid=" +
            this.props.userinfo.userid  +
            "&courseid=" +
            this.classid,
          dataType: "json",
          
          headers:{'token':this.props.userinfo.token},
          cache: false
        }).then(res => {
          if (res && res.status !== 2) {
            
            
             if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
              console.log('ismudu')
              //新加判断跳转旧目睹也需要手机号
              if(!encrypt(localStorage.getItem('phone'))){
                console.log(222)
                this.setState({
                  isnophone:true,
                })
                return false;
              }
              fetch(
                'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                  headers:{
                    'token':localStorage.getItem('token')
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                
              })
              fetch(
                "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
                localStorage.getItem('userid')+"&eventid="+ this.classid,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                console.log(res)
                if(res.code==2000){
                  let muduurl =res.data
                  window.location.href=muduurl;
                }
              });
            } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
              console.log('ismudu')
              fetch(
                'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                  headers:{
                    'token':localStorage.getItem('token')
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                
              })
              fetch(
                "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
                localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                console.log(res)
                if(res.code==2000){
                  let muduurl =res.data
                  window.location.href=muduurl;
                }
              });
            }else if (this.state.information.zhibotype ==1){
              if(!encrypt(localStorage.getItem('phone'))){
                console.log(222)
                this.setState({
                  isnophone:true,
                })
                return false;
              }

              fetch(
                "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
                localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(reszhiqu => {
                if(reszhiqu.data){
                 // let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+reszhiqu.data
                  let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+reszhiqu.data
                  //window.location.href=zhiquurls
                  window.open(zhiquurls)
                }
                console.log(reszhiqu)
              });
            }else{
              this.setState({
                maskfalg: true,
                thisreg: true,
              });
            }
            
          }
        });



      }
    } else {

      if(this.state.information.beforetype ==2){
        fetch(
          "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
            method: 'POST',
            body: JSON.stringify({
              coursecode:this.state.information.coursecode,
              userid:localStorage.getItem('userid'),
              clicktype:2,
              iscert:1,
            }),
            headers:{
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
            header: {
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
            console.log(res)
        })
       }

        //添加新积分
        this.addscorenew()
       
        if(this.state.information.type==3 && !this.state.information.videoinfo['videoid']){
          
          if(this.state.information.isscorm==1 && this.state.information.scormpath){

            if(!localStorage.getItem('userid')){

              this.setState({
                thisuserstateadd: true,
                thisuserstateaddtext: '未登录',
              })
              return 
            }
            // /front/scormcourse/setUserClickScorm
            fetch(
              "https://shl-info.cn/front/scormcourse/setUserClickScorm?userid=" +
              localStorage.getItem('userid')+"&token="+localStorage.getItem('token') +"&courseid="+this.classid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              window.location.href="/scorm/index.html?url="+this.state.information.scormpath+"&courseid="+this.classid
            })
            return false;
            //window.location.href="/scorm/index.html?url="+this.state.information.scormpath+"&courseid="+this.classid
          }
          if(this.state.information.coursetype==1 && this.state.information.courseurl){
            window.location.href=this.state.information.courseurl
          }
          


          return;
        }


      console.log(this.state.information.muduurl)
      if(this.state.information.videoinfo['videoid'] && this.state.information.type==3){
        console.log("type33333")
        if(this.iscs !=1){
          // this.props.history.push('/broadcast' + this.props.location.search);
          console.log("type333331")
          this.playvideo();
        }else if((this.state.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
          // this.props.history.push('/broadcast' + this.props.location.search);
          console.log("type333332")
          this.playvideo();
        }else{
          console.log("type333334")
          this.gotounlock()
        }
      }else if(this.state.information.muduurl!=''  && this.state.information.muduurl!='null'  &&  this.state.information.muduurl!=null){
        console.log(1111)
        console.log("type22222")
        //window.location.href=this.state.information.muduurl;
        
        window.open(this.state.information.muduurl)
      }else if(this.state.information.coursetype==1){
        console.log("type11111")
        //window.location.href=this.state.information.courseurl;
        window.open(this.state.information.courseurl)
      }else if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
        console.log('ismudu',encrypt(localStorage.getItem('phone')))

        //新加判断跳转旧目睹也需要手机号
        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }

        // return false
        

        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+ this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
        console.log('ismudu')
        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      }else if (this.state.information.zhibotype ==1){
        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }
        fetch(
          "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
          localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          if(res.data){
           // let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+res.data
            let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+res.data
            //window.location.href=zhiquurls
            window.open(zhiquurls)
          }
          console.log(res)
        });
        console.log(1234)
      }else{

        console.log("type0000")
        if(this.state.information.videoinfo['videoid']){
          if(this.iscs !=1){
            this.props.history.push('/broadcast' + this.props.location.search);
          }else if((this.state.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
            this.props.history.push('/broadcast' + this.props.location.search);
          }else{
            this.gotounlock()
          }
        }
      }
    }
  }
  setTick() {
    let time = new Date(this.state.information.starttime).getTime() - new Date().getTime();
    if (time <= 0) {
      time = 0;
      clearInterval(this.timeId);
      if(this.state.istimestate){
        this.setState({
          showtimeoverstate:true,
        })
      }
      return false;
    }
    //计算出相差天数
    let days = Math.floor(time / (24 * 3600 * 1000));
    //计算出小时数
    let leave1 = time % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000));
    //计算相差分钟数
    let leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000));
    //计算相差秒数
    let leave3 = leave2 % (60 * 1000);      //计算分钟数后剩余的毫秒数
    let seconds = Math.round(leave3 / 1000);
    let starttimenum = this.state.starttimenum - 1
    // if(starttimenum>=0){
     
      if(starttimenum==0){
       let list =  this.state.information
           list.beforetype = 2
        this.setState({
          //showtimeoverstate:true,
          information:list
        })
      }
    // }
    this.setState({
      starttime: days + '天' + hours + '小时' + minutes + "分钟" + seconds + "秒",
      starttimenum:starttimenum,
    })
  }

  render() {
    let onlinelist = null;
    //console.log(this.state.active)
    if (this.state.active === "1") {
      if (this.state.type == 3 && this.state.isconf == 1) {
        // onlinelist = (
        //   <Onlinetalk
        //     onlinelist={this.state.onlinelist}
        //     islive={this.state.type === 3}
        //     change={this.changeTalk}
        //     classid={this.classid}
        //     userinfo={this.props.userinfo}
        //   ></Onlinetalk>
        // );
        onlinelist = (
          <div className="newtext text-scroll">
            <div className="exam-list">
              <div className="newtextactive ">我是本公司指定的负责西门子医疗业务的人员，现代表我司参加并完成西门子医疗的在线培训课程。我将认真参加本次线上培训并将全部内容及时完整向我司人员传达，以遵守贵司内部政策和流程要求。<br></br><br></br></div>
              <div class="checklinew" onClick={() => this.fouractive(1)} >总经理<span class={this.state.fourtext.indexOf('1,') >= 0 ? "spannew active" : "spannew "}><span></span></span></div>
              <div class="checklinew" onClick={() => this.fouractive(4)} >合规官<span class={this.state.fourtext.indexOf('4,') >= 0 ? "spannew active" : "spannew"}><span></span></span></div>
              <div class="checklinew" onClick={() => this.fouractive(3)} >财务人员<span class={this.state.fourtext.indexOf('3,') >= 0 ? "spannew active" : "spannew"}><span></span></span></div>
              <div class="checklinew" onClick={() => this.fouractive(2)} >销售人员<span class={this.state.fourtext.indexOf('2,') >= 0 ? "spannew active" : "spannew"}><span></span></span></div>
              {/* <div class="checklinew" onClick={() => this.fouractive(5)} >其他<span class={this.state.fourtext.indexOf('5,') >= 0 ? "spannew active" : "spannew"}><span></span></span></div> */}
            </div>
            <div class="down-footer"><div class={this.state.isagreed ? "exam-submit touchCss newtextsub newtextsubover" : "exam-submit touchCss newtextsub"} onClick={() => this.subnewtext()}>{this.state.isagreed ? "已确认" : "确认"}</div></div>
          </div>
        )
      } else {
        //console.log(this.state.thisover)
        if (!this.state.thisover) {
          // onlinelist = (
          //   <Onlinetalk
          //     onlinelist={this.state.onlinelist}
          //     islive={this.state.type === 3}
          //     change={this.changeTalk}
          //     classid={this.classid}
          //     userinfo={this.props.userinfo}
          //   ></Onlinetalk>
          // );
        }
      }
    } else if (this.state.active === "2") {
      onlinelist = (
        <Coursedata
          coursedata={this.state.coursedata}
          change={this.changeCoursedata}
          userinfo={this.props.userinfo}
          recoreseenum={this.recoreseenum}
        ></Coursedata>
      );
    } else if (this.state.active === "4") {
      //console.log(1231231231)
      if (!this.state.thisover) {
        onlinelist = (
          <Onlinetalk
            onlinelist={this.state.onlinelist}
            islive="1"
            change={this.changeTalk}
            classid={this.classid}
            userinfo={this.props.userinfo}
          ></Onlinetalk>
        );
      }

    } else if (this.state.active === "11") {
      //console.log(1231231231)
      onlinelist = (
       <div className='signup-btnmedicalbox'>
        
        <Coursedetail information={this.state.information} ></Coursedetail>
        
        {!this.state.isloadingover?"":<div className="signup-btn medicalnew" onClick={() => this.gotoVideo()}>
           {this.state.information.type !== 3
            ? this.state.information.type !== 1
              ? (this.state.information.type !== 2 ? "" : this.state.showtimeoverstate?"正在直播":"进入直播")
              : (this.state.thisreg ?this.state.starttimenum<=0?this.state.showtimeoverstate?"正在直播":"进入直播": "已报名": "我要报名")
            : this.state.information.videoinfo['videoid']||this.state.information.coursetype==1|| this.state.information.isscorm==1?"进入课程":<span className='gray'>直播结束</span>}
        </div>}
        </div> 
      )

    }else if (this.state.active === "12") {
      //console.log(1231231231)
      onlinelist = (
       <div className='love_talkbox'>
          <div className={this.state.userid ? 'video-label ' : 'video-label  none'}>
              <span
                className={
                  this.state.isclicklove ? "love-people active" : "love-people"
                }
                onClick={() => this.clickLove()}
              >
                {this.state.lovepeople}人已点赞
              </span>
              <span
                className={
                  this.state.islove ? "islove-btn active" : "islove-btn"
                }
                onClick={() => this.clickMylove()}
              >
                {this.state.islove ? "已加入" : "加入学习计划"}
              </span>

              <span className={this.state.videopoint.length > 0 ? "video-print" : "video-print none"} onClick={() => this.showvideopoint()}>
                <img src={this.state.vdeopointact ? require("../../static/images/print_close.png") : require("../../static/images/print_icon_1.png")} />
              </span>
            </div>
            <div className='love_talkbox_title'>提问互动</div>
            <Onlinetalk
            onlinelist={this.state.onlinelist}
            islive="1"
            change={this.changeTalk}
            classid={this.classid}
            userinfo={this.props.userinfo}
          ></Onlinetalk>
       </div>
      )

    }else if (this.state.active === "13") {
      console.log(this.state.contactlist)
      onlinelist = (
        <div className='contactlist'>
              {this.state.contactlist.length>0?this.state.contactlist.map((item,index)=>{
                return <div className='contactlistbox'>
                <ClassList isiscourse={true} gonewcourse={this.gonewcourse} key={'contactlistbox' + index} _item={item}></ClassList> 
                </div>
              }):<div className='contactlistnone'>暂无相关推荐</div>}


              
        </div>
        // <div>
        //   12312312
        // </div>
      )

    } else {
     
      //this.state.examresults = true;
      //console.log("123123123123123examresults",this.state.examresults)
      //console.log(this.state.title)
      !this.state.examresults
        ? (onlinelist = (
          <Onlinexam
            iscourse={true}
            issale={this.state.issale}
            goshowdetail={this.goshowdetail}
            examlist={this.state.examlist}
            chatime={this.state.chatime}
            maxlooktime={this.state.maxlooktime}
            allvideotime={this.state.allvideotime}
            gotoresult={() => this.gotoresult()}
            gotocertificate={() => this.gotocertificate()}
            change={this.changeExams}
            classid={this.classid}
            userinfo={this.props.userinfo}
            coursetitle={this.state.title}
            lookstate={this.state.lookstate}
            usercount={this.state.userqacount}
            userqastate={this.state.userqastate}
            changeexamstate={this.state.changeexamstate}
            iscs={this.iscs}
          ></Onlinexam>
        ))
        : (onlinelist = (
          <ExamResults
            examlist={this.state.examlist}
            gotoresult={() => this.gotoresult()}
            changeexamstate={() => this.changeexamstate()}
            userinfo={this.props.userinfo}
            classid={this.classid}
            change={this.changeExams}
            iskaoshi={this.state.iskaoshi}
          ></ExamResults>
        ));
        

        

        if (this.state.thisover) {
          onlinelist = (
            <ExamResults
              examlist={this.state.examlist}
              gotoresult={() => this.gotoresult()}
              changeexamstate={() => this.changeexamstate()}
              userinfo={this.props.userinfo}
              classid={this.classid}
              change={this.changeExams}
            ></ExamResults>
          )
        }
    }
    if (this.state.thisover) {
      
      // onlinelist = (
      //   <ExamResults
      //     examlist={this.state.examlist}
      //     gotoresult={() => this.gotoresult()}
      //     changeexamstate={() => this.changeexamstate()}
      //     userinfo={this.props.userinfo}
      //     classid={this.classid}
      //     change={this.changeExams}
      //   ></ExamResults>
      // )
    }
    let exammask = null;
    if (this.state.ismask) {
      exammask = (
        <Maskbox
          info={{
            title: "提问",
            text: "提交成功，等待审核"
          }}
          closeMask={this.closeMask}
        ></Maskbox>
      );
    }

    if (this.state.ismasknew) {
      exammask = (
        <Maskbox
          info={{
            title: "播放失败",
            text: "请先勾选本次培训的参与人员并确认"
          }}
          closeMask={this.closeMask}
        ></Maskbox>

      );
    }

    if (this.state.iswailayermast) {
      exammask = (
        <Maskbox
          info={{
            title: "调研",
            text: "点击参与调研问卷",
            type: 'surver',
          }}
          gotosurverurl={this.gotosurverurl}
          closeMask={this.closeMask}
        ></Maskbox>

      );
    }


    if (this.state.isreloadvideo == 1 && this.state.thisvideolooktime == 0 && this.state.minlooktime > 0) {
      exammask = (
        <Maskbox
          info={{
            title: "",
            text: "您已三次没有通过考试，请重新观看视频"
          }}
          closeMask={this.closeMask}
        ></Maskbox>

      );
    }
    if (this.state.thistextsubstate) {
      exammask = (
        <Maskbox
          info={{
            title: "提交失败",
            text: "请先勾选所有选项"
          }}
          closeMask={this.closeMask}
        ></Maskbox>

      );
    }
    if (this.state.vdeopointstate) {
      exammask = (
        <Maskbox
          info={{
            title: "",
            text: "此视频没有打点信息"
          }}
          closeMask={this.closeMask}
        ></Maskbox>

      );
    }
    if (this.state.touchstate) {
      exammask = (
        <Maskbox
          info={{
            title: "温馨提示",
            text: "禁止向后拖拽视频"
          }}
          closeMask={this.closeMaskvideo}
        ></Maskbox>

      );
    }
    let videopoint = null
    videopoint = this.state.videopoint.map((item, index) => (
      <li
        className={this.state.videopointactive === index ? "active" : ""}
        key={index}
        onClick={e => this.videojump(item.videotime, index, e)}
      >
        <div className="print_list_li_title">{item.title}</div>
        <div className="print_list_li_text">{item.text}</div>
      </li>
    ));


    let pagenums = 0;
    if (this.state.swiper) {
      pagenums = this.state.swiper.activeIndex + 1;
    }
    let exammaskplay=null;

    if (this.state.playstate) {
      exammaskplay = (
        <Maskboxplay
          info={{
            title: "课程详情",
            text: "请先勾选所有选项",
            videolooktime:this.state.videolooktime,
          }}
          goingplay={this.goingplay}
          restartplay={this.restartplay}
          closeMask={this.closeMask}
        ></Maskboxplay>

      );
    }


    
    
    let scorelsyerstatehtml = null
    if(this.state.scorelsyerstate){
      scorelsyerstatehtml= <Scorelayer closescorelayer={this.closescorelayer} scorelsyerlist={this.state.scorelsyerlist}></Scorelayer> 
    }

    let time = null;
    if (this.state.information.type === 1) {
      time = (
        <span className="start-time">
          距离课程开始还有{this.state.starttime}
          
        </span>
      )
    }
    

    return (
      <div className="videopage medical-newbg">
        {!this.state.isloadingover?<div className="isloadingstate"><span>加载中</span></div>:''}
        {scorelsyerstatehtml}
        {this.classid ? <Header active={this.props.active} typeids={this.classid}></Header> : null}
      
        <section
          className={[
            "video-container havenotppt",
            // ((this.state.iszhibo === 1 && this.state.type === 3) || this.state.type === 2) ? null : "havenotppt",
            // ( this.state.type === 2) ? null : "havenotppt",
            // this.state.doc_screen ? "doc_screen_class" : null
          ].join(" ")}
        >
          <div className="wrap">
            <h2 className="video-title">
              {this.state.title}
              <div className={this.state.videotitlelayer ? "video-title-layer none" : "video-title-layer none"}>观看视频达到时长<br></br>恭喜获得2积分</div>


              {localStorage.getItem('jumpgroupcode') && this.state.isloadingover? <div className='gobackgroupplay' onClick={()=>this.gobackgroup()}>返回群组</div> :null}
            </h2>

            {!this.state.isifram?<div className="video-playerwrap">
              <div className="video-player ">
                <div id="video" className={this.state.playvidelayer ? "  " : " nostate"}>
                  {
                    !window.VhallPlayer ? (
                      <div className="videonoscriptext">
                        <p>请更换浏览器</p>
                      </div>
                    ) : null
                  }
                </div>
                {/* <div className={this.state.playvidelayer ? "playvide_layer none " : "playvide_layer "} style={{background:this.state.teacher_videopic?"url("+this.state.teacher_videopic+") top left/100% 100%" :"#000"}}> */}
                <div className={this.state.playvidelayer? "playvide_layer none " : "playvide_layer "} >
                  <div className='playvideolayerimgtmp'><img src={this.state.information.src} />
                    {time}
                  
                  </div>


                  {this.state.information.type !== 3 ? this.state.information.type !== 1 ? (this.state.information.type !== 2 ?<br></br>: this.state.showtimeoverstate?"":"") : (this.state.thisreg ?this.state.starttimenum<=0?this.state.showtimeoverstate?"":"": "" : "") : this.state.information.videoinfo['videoid']||this.state.information.coursetype==1 || this.state.information.isscorm==1? this.state.isloadingover? <div className="play_icon" onClick={() => this.playvideo()} ><img src={require("../../static/images/playvideoicon.png")} /></div>:null :""}
                  {/* {this.state.isloadingover? <div className="play_icon" onClick={() => this.playvideo()} ><img src={require("../../static/images/playvideoicon.png")} /></div>:null }  */}
                 
                </div>
                {this.state.issale==1 ?   <div className='onlysaletips'>仅供西门子医疗内部员工培训使用，禁止翻录</div>:null}

                <div className="videoimgback"><img src={this.state.teacher_videopic} /></div>
              </div>
              <div className="video-rightlabel">
                <div className="video-centers">
                  {this.state.videostatetext}
                  <span className="video-center-01"></span>
                  <span className="video-center-02"></span>
                  <span className="video-center-03"></span>
                  <span className="video-center-04"></span>
                  <span className="video-center-05"></span>
                  <span className="video-center-06"></span>
                </div>
                {/* <ul className="videoshare-list">
                  <li>分享到</li>
                  <li className="share-href wb">
                    <a href="/"> </a>
                  </li>
                  <li className="share-href wx">
                    <a href="/"> </a>
                  </li>
                  <li className="share-href dz">
                    <a href="/"> </a>
                  </li>
                  <li className="share-href qq">
                    <a href="/"> </a>
                  </li>
                </ul> */}
              </div>
              <div className="video-right-top">
                <div className="video-right-top-div" onClick={this.wechetseeBigIMg}>
                  <img src={this.state.documentimgnew ? this.state.documentimgnew : this.state.documentimg} alt="" />
                </div>
                <div className="video-right-top-bar">
                  <div className="video-right-top-lines" onMouseDown={this.touchStartbegin.bind(this)}  onMouseUp={this.touchendbegin.bind(this)}  onMouseMove={this.touchmovebegin.bind(this)} >
                    <div className="video-right-top-hover" style={{width:this.state.minoverwidth+"%"}}></div>
                    <div className="video-right-top-icon"  style={{left:this.state.minoverwidth+"%"}}  >
                      <i></i>
                    </div>
                  </div>
                  <div className={this.state.thisvideostate?"stopvideo":"playvideo"} onClick={this.stopvideoplay}></div>
                </div>
                <span
                  className="playerSwitch"
                  onClick={this.switchScreen}
                ></span>
              </div>
            </div>:
            <div className="isiframbox" >
              <iframe src={this.state.iframurl} className="iframbox">
                <p>Your browser does not support iframes.</p>
              </iframe>
            </div>}
            
          </div>
        </section>
        {/* 具体内容 */}
        <section className="video-nav com-video-nav">
          <div className="wrap">
            {/* <div className={this.state.userid ? 'video-label ' : 'video-label  none'}>
              <span
                className={
                  this.state.isclicklove ? "love-people active" : "love-people"
                }
                onClick={() => this.clickLove()}
              >
                {this.state.lovepeople}人已点赞
              </span>
              <span
                className={
                  this.state.islove ? "islove-btn active" : "islove-btn"
                }
                onClick={() => this.clickMylove()}
              >
                {this.state.islove ? "已加入" : "加入学习计划"}
              </span>

              <span className={this.state.videopoint.length > 0 ? "video-print" : "video-print none"} onClick={() => this.showvideopoint()}>
                <img src={this.state.vdeopointact ? require("../../static/images/print_close.png") : require("../../static/images/print_icon_1.png")} />
              </span>
            </div> */}
            <ul
              className={
                this.state.askactive
                  ? "video-navlist pagehide"
                  : "video-navlist"
              }
            >

              <li
                className={this.state.active === "11" ? "active" : ""}
                onClick={e => this.changeVideoNav("11", e)}
              >
                课程详情
              </li>
              {this.state.userid ?  <li
                className={this.state.active === "12" ? "active" : ""}
                onClick={e => this.changeVideoNav("12", e)}
              >
                点赞评论
              </li>:null}
              {this.state.contactlist.length>0?  <li
                className={this.state.active === "13" ? "active" : ""}
                onClick={e => this.changeVideoNav("13", e)}
              >
                推荐课程
              </li>:""}
             

              {this.state.type == 3 ? this.state.isconf === 1 ? (
                <li
                  className={this.state.active === "1" ? "active" : ""}
                  onClick={e => this.changeVideoNav("1", e)}
                >
                  学习确认
                </li>
              ) : null
                : null
                //  (
                //   <li
                //     className={this.state.active === "1" ? "active" : ""}
                //     onClick={e => this.changeVideoNav("1", e)}
                //   >
                //     {this.state.type === 3 ? "互动回顾" : "提问互动"}
                //   </li>
                // )
              }
              {
                this.state.examlist.length > 0 ? (
                  <li
                    className={this.state.active === "3" ? "active" : ""}
                    onClick={e => this.changeVideoNav("3", e)}
                  >
                    在线考试
                  </li>
                ) : null
              }
              {
                this.state.coursedata.length > 0 ? (
                  <li
                    className={this.state.active === "2" ? "active" : ""}
                    onClick={e => this.changeVideoNav("2", e)}
                  >
                    课程资料
                  </li>
                ) : null
              }
             
              {/* {
                this.state.userid ? (
                  <li
                    className={this.state.active === "4" ? "active" : ""}
                    onClick={e => this.changeVideoNav("4", e)}
                  >
                    {this.state.type === 3 ? "提问互动" : "提问互动"}
                  </li>
                ) : null
              } */}
            </ul>
            {/* 提问部分 */}
            <div
              className={
                this.state.askactive ? "asktitle" : "asktitle pagehide"
              }
            >
              全部提问
              <span className="askarrow" onClick={() => this.showAsk()}></span>
            </div>
            {/* 打点部分 */}

          </div>
        </section>
        <div
          className={
            this.state.askactive ? "video-select pagehide" : "video-select"
          }
        >
          {onlinelist}


          <div className={this.state.vdeopointact ? "video_print_list" : "video_print_list none"}>
            <div className="pring_list_bg">
              <div className="pring_list_box">
                <div className="print_list_icon"></div>
                <ul className="print_list_ul">
                  {videopoint}
                  {/* <li>
                    <div className="print_list_li_title">第一章</div>
                    <div className="print_list_li_text">体检设备如何安装及规范使用安安范使用安装及规范使用体检设备如何安装及规范使用安安范使用安装及规范使用</div>
                  </li>
                  <li>
                    <div className="print_list_li_title">第一章</div>
                    <div className="print_list_li_text">体检设备如何安装及规范使用安安范使用安装及规范使用体检设备如何安装及规范使用安安范使用安装及规范使用</div>
                  </li>
                  <li>
                    <div className="print_list_li_title">第一章</div>
                    <div className="print_list_li_text">体检设备如何安装及规范使用安安范使用安装及规范使用体检设备如何安装及规范使用安安范使用安装及规范使用</div>
                  </li>
                  <li>
                    <div className="print_list_li_title">第一章</div>
                    <div className="print_list_li_text">体检设备如何安装及规范使用安安范使用安装及规范使用体检设备如何安装及规范使用安安范使用安装及规范使用</div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

        </div>
        {this.state.askactive ? (
          <Asklist
            active={this.state.askactive}
            videoquestion={this.state.videoquestion}
            close={() => this.closeAsk()}
            classid={this.classid}
            success={this.asksuccess}
            userinfo={this.props.userinfo}
          ></Asklist>
        ) : null}
        {exammask}
        {exammaskplay}


        <div className={this.state.layerstate ? "imglayer groupinfo" : "imglayer groupinfo none"}>
          <div className="pagenums">{pagenums}/{this.state.layerimglist.length}</div>
          <div className="close_layer" onClick={(e) => this.closelayer()}></div>
          <div className="swiper-container imglist">
            <div className="swiper-wrapper imglist">
              {
                (this.state.layerimglist.length == 0)
                  ? null
                  : this.state.layerimglist.map((item1, index1) => {
                    return (
                      <div className="swiper-slide" key={index1}><img src={item1.img}></img></div>
                    )
                  })
              }
              {/* <div class="swiper-slide"><img src={require('../../static/images/poster_01.jpg')}></img></div>
              <div class="swiper-slide"><img src={require('../../static/images/poster_02.jpg')}></img></div> */}
            </div>
            <div className={this.state.prevstate ? "swiper-button-prev none" : "swiper-button-prev"} onClick={(e) => this.prevswi()}></div>
            <div className={this.state.nextstate ? "swiper-button-next none" : "swiper-button-next"} onClick={(e) => this.nextswi()}></div>
            {/* <div className="tsnum">{this.state.active}/{this.state.layerimglist.length}</div> */}
            {/* <div class="swiper-pagination"></div> */}
          </div>
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default BroadCast
