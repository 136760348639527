import React from 'react'

import ReactMarkdown from 'react-markdown'
import './index.css'
import { Link } from 'react-router-dom'
let  isOnlySpaces = (input) => {  
    return /^[ ]*$/.test(input);  
  }  
function Aicontent(props) {
   
    return (
        <div className="aicontentboxinfo">
            <div className='aicontentboxinfo_title'>AI智能助教：</div>
            <div className='aicontentboxinfo_boxtext'>
            {props.aibacktext?<ReactMarkdown>{props.aibacktext}</ReactMarkdown>:<div className='loadingaidemo'>深入分析参考资料<div className='loadingicon'></div></div>}
            </div>
            {props.showtips?<div className='ai_survery_contentbox'>
                <div className={props.surverytype==1?'ai_survery_contentbox_left active':'ai_survery_contentbox_left '} onClick={()=>props.changesurvery(1)}>有帮助</div>
                <div className={props.surverytype==2?'ai_survery_contentbox_right active':'ai_survery_contentbox_right '} onClick={()=>props.changesurvery(2)}>需提升</div>

                {/* <div className='gosurvery'  onClick={()=>props.closechangetype(1)}>我要反馈</div> */}
            </div>:''}

            {props.showsurvery?<div className='ai_surveryboxlayer'>
                <div className='ai_surverybox'>
                    <div className='ai_surverybox_title'>我要反馈
                    <span className='ai_surverybox_close'  onClick={()=>props.closechangetype(2)}></span>
                    </div>
                    <div className='ai_surverycontent'>
                        <div className='ai_surverycontenttitle'>请把您对西影力学院智能助手的宝贵建议写在这里:</div>
                        <div className='ai_surverycontenttextarea'>
                            <textarea placeholder='请输入文字:' 
                            onChange={props.valueChangetext}
                            value={props.surverycontent}
                            ></textarea>
                        </div>
                    </div>
                    <div className={!isOnlySpaces(props.surverycontent) && props.surverycontent?"ai_textareabtn":"ai_textareabtn ai_textareabtnnotext"} onClick={()=>props.subcontent()}>提交</div>
                </div>
            </div>:""}
        </div >
    )
}

export default Aicontent
