import React from 'react';
import { Route } from 'react-router';
import { Switch, withRouter, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'


import { encrypt, decrypt } from '../utils/crypto.ts'
// HashRouter

//新增列表明
import LoginList from './loginlist' //登录列表页面


import Login from './login' //登录页面
import AutoGraphin from './autographin' //签名页面
import Home from './home' //首页
import Homelist from './homelist' //首页
import HomePlaying from './homeplaying' //直播中心
import HomeBack from './homeback' //课程中心
import HomeFile from './homefile' //文档中心

import IntegralMall from './integralmall' //积分商城


import HomePerson from './homeperson' //个人中心
import WatchHistory from './watchhistory' //观看历史
import LearnRecord from './learnrecord' //学习记录
// import CertifiCate from './certificate' //我的证书
import CertifiCate from './certificateformy' //我的证书
//import CertifiCateData from './certificatedata' //我的证书

import CertifiCateData from './certificate2022' //我的证书

import certificateGroup from './certificategroup' //我的证书
import certificategrouppack from './certificategrouppack' //课程包的证书



import DownRecord from './downrecord' //下载记录
import LearnPlan from './learnplan' //学习计划
import Collect from './collect' //我的收藏
import SubAccount from './subaccount' //子账号管理
import AddAccount from './addaccount' //修改资料
import CourseDetails from './coursedetails' //课程详情
import BroadCast from './broadcast' //视频页面
import SearchPage from './searchpage' //视频页面
import Exchangedata from './exchangedata' //视频页面




import LearningPlan from './learningplan'; //学习列表页
import LearningPlanuser from './learningplanuser'; //学习详情页
import LearningPlanadmin from './learningplanadmin'; //学习管理员查看页
import LearningPlanback from './learningplanback'; //学习查看领导反馈页
import LearningPlanuserlook from './learningplanuserlook'; //学习详情页
import LearningPlanadminuserlist from './learningplanadminuserlist'; //学习详情页
import Diexamachievement from './diexamachievement'; //学习详情页



import Checkcode from './checkcode'; //CS微课核销码
import Diexamdetail from './diexamdetail'; //DI大考试列表页
import Bpmexamdetail from './bpmexamdetail'; //DI大考试列表页
// import Diexam from './diexam'; //DI大考试题目页




import userPost from './userPost'; // 我的发帖
import userNotice from './userNotice'; // 我的发帖
import doctorQuery from './doctorQuery'; // 我的发帖
import talkDetail from './talkDetail'; // 我的发帖
import postDetail from './postDetail'; // 我的发帖
import postDetail_preview from './postDetail_preview'; // 我的发帖草稿预览
import postDetail_admin_preview from './postDetail_admin_preview'; // 后台审核预览




import Contentus from './contentus'; //关于我们


import HomeDoctorInteraction from './homeDoctorInteraction' //互动专区
import homeDoctorReleasePost from './homeDoctorReleasePost' //互动专区 发帖


import groupDetail from './groupDetail'; // 群组详情
import groupDetailinfo from './groupDetailinfo'; // 群组详情
import groupDetaildistributor from './groupDetaildistributor'; // 群组详情
import groupDetailtourtraining from './groupDetailtourtraining'; // 群组详情



import groupManipulat from './groupManipulat'; // 群组控制


import myRegistration from './myregistration'; // 我的报名列表



import scormDetail from './scormdetail'; // 我的报名列表

import invitelist from './invitelist'; // 我的报名列表



import Ai from './ai'; // 我的报名列表
import Aipdf from './aipdf'; // 我的报名列表

import testdemo from './testdemo'; // 我的报名列表

import previewcsorder from './previewcsorder'; // 预览CS订单

import landingservies from './landingservies'; // 预览CS订单




import Shopmallindex from './shopmallindex'; //新Eshop首页
import Shopmalldetail from './shopmalldetail'; //新Eshop首页
// import Shopmallvideo from './shopmallindex/shopmallplay'; //新Eshop首页
// import Shopmalllogin from './shopmallindex/eshoplogin'; //新Eshop首页
// import Shopmallfresh from './shopmallindex/fresh'; //新Eshop首页
// import Shopmallperson from './shopmallindex/person'; //新Eshop首页





function getUrlkey(url) {
  var params = {};
  var urls = url.split("?");                  //console.log('1_分割url:', urls)
  var arr = urls[1].split("&");               //console.log('2_分割urls[1]:', arr)
  for (var i = 0, l = arr.length; i < l; i++) {
    var a = arr[i].split("=");                //console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
    params[a[0]] = a[1];                      //console.log('4_a给params对象赋值:', params)
  }                                           //console.log('5_结果:', params)
  return params;
}
if(window.location.href.indexOf('sourcechannel=')>=0){
  let urls = getUrlkey(window.location.href);
  let sourcechannel=urls['sourcechannel'];
  //console.log(2222,"路由进口")
  localStorage.setItem('sourcechannel',sourcechannel)
}

//校验RSA登录

if(window.location.href.indexOf('userid=')>=0 && window.location.href.indexOf('rsatoken=')>=0 ){
  let urls = getUrlkey(window.location.href);
  let userid=urls['userid'];
  let rsatoken=urls['rsatoken'];
  fetch(
    "https://shl-info.cn/front/user/checkuser?userid=" +
    userid ,{
      headers:{
        'token':rsatoken
      }
    }
  )
  .then(data => {
    return data.json();
  })
  .then(res => {
    console.log(res)
    if (res.status === 1) {
      let name = decrypt(res.name);
      let userid =res.list.id?res.list.id:'';
      localStorage.setItem('userid', userid)
      localStorage.setItem('token', res.token)
      
      localStorage.setItem('isdi', res.list.isdi)

      localStorage.setItem('phone', res.list.phone)
      localStorage.setItem('name', res.list.name)
      localStorage.setItem('email', res.list.email)

      localStorage.setItem('usertype', res.list.usertype)

      localStorage.setItem('headimg', res.pic)
      localStorage.setItem('names', decrypt(res.name))
      localStorage.setItem('name', decrypt(res.name))
      let url = window.location.href
      url = url.replace("?userid="+userid, "");
      url = url.replace("&userid="+userid, "");
      url = url.replace("?rsatoken="+rsatoken, "");
      url = url.replace("&rsatoken="+rsatoken, "");
      console.log(url)
       window.location.href = url
     
    }
  })
}

const Routes = withRouter(({ location }) => (
  
  <Switch location={location}>
    <Route exact path="/loginlist" component={LoginList}></Route>
    {/* <Route exact path="/" component={Login}></Route> */}
    {/* <Route exact path="/" component={Homelist}></Route> */}
    <Route exact path="/" component={LoginList}></Route>
    <PrivateRoute exact path="/autographin" component={AutoGraphin}></PrivateRoute>
    <Route exact path="/home" component={Homelist}></Route>
    <Route exact path="/homelist" component={Homelist}></Route>
    <Route exact path="/homeplaying" component={HomePlaying}></Route>
    <Route path="/homeback" component={HomeBack}></Route>
    <Route exact path="/homefile" component={HomeFile}></Route>
    <Route exact path="/integralmall" component={IntegralMall}></Route>
    <PrivateRoute exact path="/homeperson" component={HomePerson}></PrivateRoute>
    <PrivateRoute exact path="/watchhistory" component={WatchHistory}></PrivateRoute>
    <PrivateRoute exact path="/learnrecord" component={LearnRecord}></PrivateRoute>
    <PrivateRoute exact path="/certificate" component={CertifiCate}></PrivateRoute>
    <PrivateRoute exact path="/certificatedata" component={CertifiCateData}></PrivateRoute>
    <PrivateRoute exact path="/certificategroup" component={certificateGroup}></PrivateRoute>
    <PrivateRoute exact path="/certificategrouppack" component={certificategrouppack}></PrivateRoute>

    

    
    <PrivateRoute exact path="/downrecord" component={DownRecord}></PrivateRoute>
    <PrivateRoute exact path="/learnplan" component={LearnPlan}></PrivateRoute>
    <PrivateRoute exact path="/collect" component={Collect}></PrivateRoute>
    <PrivateRoute exact path="/subaccount" component={SubAccount}></PrivateRoute>
    <Route exact path="/addaccount" component={AddAccount}></Route>
    <Route exact path="/scromcoursedetails" component={CourseDetails}></Route>
    <Route exact path="/coursedetails" component={BroadCast}></Route>
    <Route exact path="/broadcast" component={BroadCast}></Route>
    <Route exact path="/searchpage" component={SearchPage}></Route>
    <PrivateRoute exact path="/diexamdetail" component={Diexamdetail}></PrivateRoute>
    <PrivateRoute exact path="/diexamachievement" component={Diexamachievement}></PrivateRoute>

    
    <PrivateRoute exact path="/bpmexamdetail" component={Bpmexamdetail}></PrivateRoute>

    
    <PrivateRoute exact path="/exchangedata" component={Exchangedata}></PrivateRoute>
    

    {/* <PrivateRoute exact path="/diexam" component={Diexam}></PrivateRoute> */}
    


    
    <Route exact path="/checkcode" component={Checkcode}></Route>

    
    <Route exact path="/contentus" component={Contentus}></Route>

    

    <PrivateRoute exact path="/learningplan" component={LearningPlan}></PrivateRoute>
    <PrivateRoute exact path="/learningplanuser" component={LearningPlanuser}></PrivateRoute>
    <PrivateRoute exact path="/learningplanadmin" component={LearningPlanadmin}></PrivateRoute>

    <PrivateRoute exact path="/learningplanuserlook" component={LearningPlanuserlook}></PrivateRoute>
    <PrivateRoute exact path="/learningplanback" component={LearningPlanback}></PrivateRoute>
    <PrivateRoute exact path="/learningplanadminuserlist" component={LearningPlanadminuserlist}></PrivateRoute>



    {/* 互动专区 */}
    
    <Route exact path="/homeDoctorInteraction" component={HomeDoctorInteraction}></Route>
    <Route exact path="/homeDoctorReleasePost" component={homeDoctorReleasePost}></Route>

    <Route exact path="/userPost" component={userPost}></Route>
    <Route exact path="/userNotice" component={userNotice}></Route>
    <Route exact path="/doctorQuery" component={doctorQuery}></Route>
    <Route exact path="/talkDetail" component={talkDetail}></Route>
    <Route exact path="/postDetail" component={postDetail}></Route>
    <Route exact path="/postDetail_preview" component={postDetail_preview}></Route>

    <Route exact path="/postDetail_admin_preview" component={postDetail_admin_preview}></Route>


    <Route exact path="/groupDetail" component={groupDetail}></Route>
    <Route exact path="/groupDetailinfo" component={groupDetailinfo}></Route>
    <Route exact path="/groupDetaildistributor" component={groupDetaildistributor}></Route>
    <Route exact path="/groupManipulat" component={groupManipulat}></Route>
    
    <Route exact path="/groupDetailtourtraining" component={groupDetailtourtraining}></Route>
    
    <PrivateRoute exact path="/myRegistration" component={myRegistration}></PrivateRoute>

    <Route exact path="/scormdetail" component={scormDetail}></Route>
    <Route exact path="/testdemo" component={testdemo}></Route>


    
    <PrivateRoute exact path="/invitelist" component={invitelist}></PrivateRoute>
    
    <Route exact path="/previewcsorder" component={previewcsorder}></Route>
    <Route exact path="/ai" component={Ai}></Route>

    <Route exact path="/aipdf" component={Aipdf}></Route>

    
    <Route exact path="/landingservies" component={landingservies}></Route>

    
{/* 新商城页面 */}
<Route exact path="/shopmallindex" component={Shopmallindex}></Route>
<Route exact path="/shopmalldetail" component={Shopmalldetail}></Route>
{/* <Route exact path="/shopmallvideo" component={Shopmallvideo}></Route>
<Route exact path="/shopmalllogin" component={Shopmalllogin}></Route>
<Route exact path="/shopmallfresh" component={Shopmallfresh}></Route>
<Route exact path="/shopmallperson" component={Shopmallperson}></Route> */}

    
  </Switch>
))

export default class App extends React.PureComponent {
  

  render() {
    return (
      <BrowserRouter basename="/website">
        <Routes />
      </BrowserRouter>
    );
  }
}
