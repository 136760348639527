import React, { useState, useEffect,useRef } from 'react'

import utils from '../../utils/index'

// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import GroupChat from './components/GroupChat'
import GroupData from './components/GroupData'
import GroupCourse from './components/GroupCourse'
import classnames from 'classnames'

import Contactuslayer from '../../components/contactuslayer'

import { encrypt, decrypt } from '../../utils/crypto'
import './index.css'


import Newtipslayer from './components/Newtipslayer'

const UserPost = (props) => {

  const childRef = useRef(null)
  let userid = localStorage.getItem('userid') 
  let token = localStorage.getItem('token')

  let [showtipslayer, setShowtipslayer] = useState(false)
  let [skipnum, setSkipnum] = useState(1)
  let [issurclick, setissurclick] = useState(false)
  // group: 群组  course: 课程
    let [navSelect, setNavSelect] = useState('group')
    let [groupChatModule, setGroupChatModule] = useState('')

    let [changeref, setchangeref] = useState(false)
    let [isgroupexam, setisgroupexam] = useState(false)

    let [userlayer, setUserlayer] = useState(false)
    let [showchange, setShowchange] = useState(false)

// let [navSelect, setNavSelect] = useState('course')
  
let [cousreinfo, setCousreinfo] = useState({})
let [cousrepack, setCousrepack] = useState({})
let [isgroupexamstate, setIsgroupexamstate] = useState(0)
  
let [isexam, setIsexam] = useState(0)
  
  let [groupinfo, setGroupinfo] = useState({
    hospital:'',
    name:'',
    id:'',
  })
  //学员列表
  let [groupuserinfo_user, setGroupuserinfo_user] = useState([])
  let [groupuserinfo_admin, setGroupuserinfo_admin] = useState([])



  //学习进度
  let [studylist, setStudylist] = useState([])
  //分类ID
  let [groupid, setGroupid] = useState('')
  let [cancreate, setCancreate] = useState(false)
  let [usertypes, setUsertypes] = useState(0)
  let changegroupChatModule = (e)=>{
    setGroupChatModule(e)
  }
  let getGroupStudyList = (ids)=>{
    let query = utils.getUrlParams()

    fetch(
      "https://shl-info.cn/front/Groupchat/getGroupStudyListfortype?userid=" +localStorage.getItem('userid') +"&chattype=2" +"&sorttype="+ids+
      "&chatcode=" +
      query.groupids ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setStudylist(res.data)
        //  this.setState({
        //   studylist:
        //  })
        }

      });
  }

  useEffect(() => {
    console.log(22222)
    console.log('usertypes',usertypes)
  }, [usertypes])
  useEffect(() => {
    let query = utils.getUrlParams()
    if(query.groupids){
      setGroupid(query.groupids)
      fetch(
        "https://shl-info.cn/front/Groupchat/getGroupInfoByIdfortype?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        query.groupids+
        "&isallsurver=" +
        query.isallsurver ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setIsexam(res.data.issurver)
          setGroupinfo(res.data.groupinfo)

          if(res.data.groupinfo.type==2){
            window.location.replace('/website/groupDetailinfo?groupids='+query.groupids);
            return;
          }
          if(res.data.groupinfo.type==4){
            window.location.replace('/website/groupDetailtourtraining?groupids='+query.groupids);
            return;
          }
          let groupuserinfo_admin=[];
          let groupuserinfo_user=[];
          for(var i=0;i<res.data.groupuserinfo.length;i++){
            if(res.data.groupuserinfo[i]['type']!=3){
              groupuserinfo_admin.push(res.data.groupuserinfo[i])
            }else{
              groupuserinfo_user.push(res.data.groupuserinfo[i])
            }
          }
          setGroupuserinfo_user(groupuserinfo_user)
          setGroupuserinfo_admin(groupuserinfo_admin)
        }
      });

      fetch(
        "https://shl-info.cn/front/Groupchat/getGroupStudyListfortype?userid=" +localStorage.getItem('userid') +"&chattype=2" +
        "&chatcode=" +
        query.groupids ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            setStudylist(res.data)
          //  this.setState({
          //   studylist:
          //  })
          }

        });

      let userid = localStorage.getItem('userid')
      let token = localStorage.getItem('token')
    
      if (userid && token) {
        // 验证是否过期
        fetch(
          "https://shl-info.cn/front/user/checkuser?userid=" +
          localStorage.getItem('userid') ,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log(res)
            if(res.list){
              localStorage.setItem('headimg', res.pic)
              localStorage.setItem('names', decrypt(res.name))

              if(res.list.roletype==1 || res.list.roletype==2){
                setCancreate(true)
              }
              
            }
            
          });
      }else{
        setUserlayer(true)
        //props.history.push("/home")
        // props.history.push("/loginlist?isgroup=1")
      }

      //获取用户在群组的身份
      fetch(
        "https://shl-info.cn/front/Groupchat/getUserTypeById?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        query.groupids ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            // this.setState({
            //   usertypes:res.data
            // })
            getuserlookloog();
            // if(!localStorage.getItem("showlayertips")){
            //   setShowtipslayer(true)
            // }
            setUsertypes(res.data)
            if(!res.data){
              //props.history.push('/home');

              getuserinfobycode();
              setUserlayer(true)
              // window.location.href="/website/home"
            }
            localStorage.setItem('grouptypes',res.data)

          }else{
            // props.history.push('/website/home')
            // window.location.href="/website/home"

            setUserlayer(true)
            getuserinfobycode();
          }
  
        });
    


    }else{
      console.log(2323)
    }
  }, [])
  let getuserinfobycode = ()=>{
    console.log(1111)
    getotherinfobyuserid();
  }
  let getuserlookloog = ()=>{
   
    let query = utils.getUrlParams()
    if(query.groupids){
      fetch(
        "https://shl-info.cn/front/Groupchat/getgroupidlooklog?userid=" +
        localStorage.getItem('userid')+"&chatcode="+query.groupids+"&type=2" ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(resinfo => {
          console.log(resinfo)
          if(resinfo.code!=200){
            setShowtipslayer(true)
            setTimeout(()=>{
              console.log("scrollTop")
              document.documentElement.scrollTop = 0
              document.body.scrollTop = 0
            },300)
          }
          // setShowtipslayer(true)
        })
    }

  }

  let getotherinfobyuserid = ()=>{
   
    let query = utils.getUrlParams()
    if(query.groupids){

    fetch(
      "https://shl-info.cn/front/Groupchat/getGroupSaleUserInfo?userid=" +
      localStorage.getItem('userid')+"&chatcode="+query.groupids ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
      .then(data => {
        return data.json();
      })
      .then(resinfo => {
        console.log(resinfo)
        if(resinfo.data && resinfo.data.userid){
          setShowchange(true)
         let  res = resinfo.data
          localStorage.setItem('headimg', res.list.pic)
          localStorage.setItem('names', (res.name))
          localStorage.setItem('name', (res.name))
          localStorage.setItem('isdi', res.list.isdi)


          localStorage.setItem('userid', res.userid)
          localStorage.setItem('token', res.token)
          
          localStorage.setItem('isdi', res.list.isdi)

          localStorage.setItem('iswaitype', res.list.type)

          localStorage.setItem('phone', res.list.phone)
          // localStorage.setItem('name', res.list.name)
          localStorage.setItem('email', res.list.email)

          
          localStorage.setItem('special', res.special)
          console.log(props.history)
          // props.history.push("/loginlist?isgroup=1")

          // let jumpurl  = window.location.href
          // window.location.href=jumpurl+"&islogin=1";
          //  props.history.push("/groupDetailinfo?groupids="+query.groupids+"&islogin=1")
          // this.props.history
        }else{
          // this.setState({
          //   showlayer:true,
          // })
        }
      })
    }
  }

  let changecourse = (arr) => {
   // console.log(arr)
    setCousreinfo(JSON.parse(JSON.stringify(arr)))
  }

  let changecoursepack = (arr) =>{
    setCousrepack(JSON.parse(JSON.stringify(arr)))
    console.log(arr)
    setIsgroupexamstate(arr.isexam)
  }
  // let [onlinelist, setOnlinelist] = useState([])
  let changenotice = () =>{
    let query = utils.getUrlParams()
    if(query.groupids){
      setGroupid(query.groupids)
      fetch(
        "https://shl-info.cn/front/Groupchat/getGroupInfoByIdfortype?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        query.groupids ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          setGroupinfo(res.data.groupinfo)
        }
      });
    }
  }

  let [contactus, setContactus] = useState(false)
  let closelayer = ()=>{
    setContactus(false)
  }
  let closeMask = ()=>{
      let jumpurl  = window.location.href
      window.location.href=jumpurl+"&islogin=1";
    console.log(12312312)
    // props.history.push("/groupManipulat")
  }
  let gologin = ()=>{
    console.log(12312312)
    let email = localStorage.getItem('email');
    let name = localStorage.getItem('name');
    let phone = localStorage.getItem('phone');
    let sourcechannel = localStorage.getItem('sourcechannel');
    localStorage.clear();
    localStorage.setItem('phone', phone);
    localStorage.setItem('name', name);
    localStorage.setItem('email', email);
    if (sourcechannel) {
        localStorage.setItem('sourcechannel', sourcechannel);
    }
    localStorage.setItem('refurl', window.location.href);
    
    props.history.push("/loginlist?groupids="+groupid+"&isgrouplogin=2")
  }
  


  let getOffsetToAncestor = (element, ancestorSelector) => {  
    let offsetTop = 0;  
    let currentElement = element;  
    
    while (currentElement) {  
      offsetTop += currentElement.offsetTop;  
      currentElement = currentElement.offsetParent;  
    
      // 检查当前元素是否匹配祖级元素的选择器  
      if (currentElement && currentElement.matches(ancestorSelector)) {  
        break; // 如果是，则退出循环  
      }  
    }  
    
    return offsetTop;  
  }  
    
  let scrollAncestorToElement = (element, ancestorSelector) => {  
    const ancestorElement = element.closest(ancestorSelector); // 找到最近的祖级元素  
    console.log(element)
    console.log(ancestorSelector)
    if (ancestorElement) {  
      let  offsetTop = getOffsetToAncestor(element, ancestorSelector);  
      offsetTop = offsetTop-250
      ancestorElement.scrollTop = offsetTop; // 滚动祖级元素到目标位置  
    } else {  
      console.log('未找到匹配的祖级元素');  
    }  
  }  
  
  useEffect(() => {
    console.log(22222)
    console.log('cousreinfo',cousreinfo)
    if(cousreinfo.isexam!=1){
      console.log(1111)
      setchangeref(!changeref)
    }
  }, [cousreinfo])

  let changetips = (type) =>{
    console.log(type)
    setSkipnum(type)
    console.log(cousreinfo)
    if(isexam!=1 && type==6){
      console.log(1111)
      let types = type+2
      setSkipnum(types)
    }else if(isgroupexamstate!=1 && type==5){
      console.log(1111)
      let types = type+1
      console.log(types)
      if(isexam !=1 ){
        types = types+2
      }
      setSkipnum(types)
    }else if(type ==3){
      // setShowtipslayer(false)
      setNavSelect('course')
    }else  if(type ==8){
      const targetElement = document.getElementById('groupDatasortbox');  
      const ancestorSelector = '#scrollbargroup'; // 祖级元素的CSS选择器  
      scrollAncestorToElement(targetElement, ancestorSelector);
      // let className= "groupDatasortbox";
      // let classNamescroll= "scrollbar";
      
      // const scrollableContainer =  document.querySelector('.' + classNamescroll);  
          

      // const targetElement = document.getElementById('groupDatasortbox');  
      // const ancestorElementId = 'scrollbargroup'; // 祖级元素的id  
      // const offsetToAncestor = getOffsetToAncestor(targetElement, ancestorElementId);  
      // console.log('元素距离祖级元素的顶部距离:', offsetToAncestor);
      // document.getElementById('scrollbar').scrollTop = offsetToAncestor;

      // 获取可滚动的容器元素  
        // const scrollableContainer =  document.querySelector('.' + classNamescroll);  
          
        // // 获取目标元素  
        // const targetElement = document.getElementById('groupDatasortbox'); 
        //   console.log(targetElement)
        // // 计算目标元素相对于容器顶部的偏移量  
        // const offsetTop = targetElement.offsetTop;  
        //   console.log(offsetTop)
        // // 滚动容器到目标元素的位置  
        // scrollableContainer.scrollTop = offsetTop;


      // var element = document.querySelector('.' + className);  
        
      // if (element) {  
      //     // 确保元素在DOM中  
      //     element.scrollIntoView({  
      //         behavior: 'smooth' // 平滑滚动，某些浏览器可能不支持  
      //     });  
      // } else {  
      //     console.error('没有找到具有class ' + className + ' 的元素');  
      // }  
      console.log(1111)
    }else if(type ==12){
      setShowtipslayer(false)
      localStorage.setItem("showlayertips",true)

      fetch(
        "https://shl-info.cn/front/Groupchat/savegroupidlooklog?userid=" +
        localStorage.getItem('userid')+"&chatcode="+groupid+"&type=2" ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(resinfo => {
          console.log(resinfo)
        })
    }
  }
  console.log(cousreinfo)
  let changeissurclick =()=>{
    setissurclick(false)
  }
  let changecoursetype = () =>{
    setNavSelect('course'); 
    if(childRef.current){
      childRef.current.handleClick()
    };
  }
  let changelist = ()=>{
    console.log(1111)
    getGroupStudyList()
  }
  return (
    <div className='groupDetailPage'>
      {showtipslayer?<Newtipslayer isexam={isexam} cousreinfo={cousreinfo} showtipslayer={showtipslayer} skipnum={skipnum}  groupinfo = {groupinfo} changetips={changetips}></Newtipslayer>:null}
      {userlayer?<div className="common-mask userlayer">
            <div className="common-maskbox " >
                <h2 className="common-masktitle">
                西影力学院
                    {/* <span
                        className="common-maskclose cursor"
                        onClick={() => closeMask()}></span> */}
                </h2>
                <div className="common-masktext-surbox">
                   <p className='userlayertext textcenter'> 请登录经销商账号进入群组学习考试！</p>
                    <div className="masksurbox-btnlist">
                        <span className="changebtn" onClick={()=>gologin()}>确认</span>
                    </div>
                   
                </div>
            </div>
        </div >:null}

      {showchange?<div className="common-mask userlayer">
            <div className="common-maskbox " >
                <h2 className="common-masktitle">
                西影力学院
                    <span
                        className="common-maskclose cursor"
                        onClick={() => closeMask()}></span>
                </h2>
                <div className="common-masktext-surbox">
                   <p className='userlayertext'> 已为您切换为内部账号！</p>
                   
                   
                </div>
            </div>
        </div >:null}
      <Header active='4' userlayer={userlayer}></Header>
      {contactus?<Contactuslayer closelayer={closelayer}></Contactuslayer>:""}
      <div className="pageContent">
        <div className="pageHead">
          <div className="content groupinfo">
            <div className={showtipslayer && skipnum === 9?'contactusicon groupinfo addbox' :'contactusicon groupinfo'} onClick={()=> setContactus(true)}>
              <p>技术热线</p>
            </div>
            <div className="detail">
              <div className="title text-overflow">{groupinfo.name}</div>
              <div className="subTitle text-overflow">{groupinfo.hospital}</div>
            </div>
            <div className="nav">
              <div className={classnames("navItem ",isexam==1&&"groupinfo", navSelect === 'group' && 'active')} onClick={() => {setNavSelect('group');setissurclick(false);}}>群聊</div>
              <div className={classnames("navItem ",isexam==1&&"groupinfo", navSelect === 'course' && groupChatModule!="exam" && 'active',showtipslayer && skipnum === 2 && 'addbox')} onClick={() =>changecoursetype()}><span>课程</span></div>
             {isexam==1? <div className={classnames("navItem groupinfo", navSelect === 'course' && groupChatModule=="exam" && 'active')}  onClick={() => {setNavSelect('course');setissurclick(true);}}><span>考试</span></div> :null}
          
            </div>
          </div>
        </div>

        <div className="pageBody">
          <div className="content"> 
            <div className="pageBodyLeft">
              { navSelect === 'group' ? <GroupChat showtipslayer={showtipslayer} skipnum={skipnum} groupinfo={groupinfo} history={props.history}  groupid={groupid}    usertypes={usertypes} ></GroupChat> : <GroupCourse changelist={changelist} ref={childRef} changegroupChatModule={changegroupChatModule} changeissurclick={changeissurclick} issurclick={issurclick} showtipslayer={showtipslayer} skipnum={skipnum}  history={props.history} groupinfo={groupinfo} changecoursepack={changecoursepack} changecourse={changecourse}  usertypes={usertypes} groupid={groupid}></GroupCourse> }
            </div>
            <div className="pageBodyRight">
              <GroupData  cousreinfo={cousreinfo}  getGroupStudyList={getGroupStudyList} showtipslayer={showtipslayer} skipnum={skipnum} usertypes={usertypes} isexam={isexam} cancreate={cancreate} groupid={groupid} groupinfo={groupinfo} changenotice={changenotice} studylist={studylist} groupuserinfo_user={groupuserinfo_user} groupuserinfo_admin={groupuserinfo_admin}></GroupData>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  )
};

const Demo = ()=>{
  return <>1111</>
}

export default UserPost;
