import React, { useState, useEffect } from 'react'
import './index.css'

const IndexComponent = (props) => {
  console.log(props)
  let gotalklist =(item) =>{
    console.log(item)
    if(item.type==2){
      props.history.push('/groupDetailinfo?groupids='+item.groupcode)
    }else if(item.type==3){
      props.history.push('/groupDetaildistributor?groupids='+item.groupcode)
    }else if(item.type==4){
      props.history.push('/groupDetailtourtraining?groupids='+item.groupcode)
    }else{
      props.history.push('/groupDetail?groupids='+item.groupcode)
    }
  }
  let groupItem = (item) => {
    return (
      <div className={item.type==2||item.type==4?"cursor groupItem groupcolor":item.type==3?"cursor groupItem groupcolorgreen":"cursor groupItem"} onClick={()=>gotalklist(item)}>
        <img src={item.headimg?item.headimg:item.type==2||item.type==4?require('../../../../static/images/group-hotheadimg-color.png'):item.type==3?require('../../../../static/images/group-hotheadimg.png'):require('../../../../static/images/group-hotheadimg-colorgreen.png')} alt="" className="groupItemAvatar" />
        <div className="groupItemContent">
          <div className="groupItemTitle">{item.name}</div>
          <div className="groupItemTips">{item.hospital}</div>
        </div>
        <div className="groupItemPos">
          <div className="groupItemTime">{item.lasttime}</div>
          {item.noreadnum?<div className="groupItemNewNum">{item.noreadnum}</div>:null}
        </div>
      </div>
    )
  }
  
  return (
    <div className="groupList">
      {props.grouplist.map((item,index)=>{
        return groupItem(item)
      })}
      {/* { groupItem() }
      { groupItem() }
      { groupItem() } */}
    </div>
  )
};

export default IndexComponent;
