import React, { Component } from 'react'
import $ from 'jquery'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import MaskBox from '../../components/maskbox'
import GroupSelect from './components/GroupSelect'
import GroupSelectlist from './components/GroupSelectlist'




import CsLiveCode from '../../components/cslivecode'



import { encrypt, decrypt } from '../../utils/crypto.ts'

import './index.css'

class CourseDetails extends Component {
  constructor(props) {
    super(props)
    this.closeMask = this.closeMask.bind(this);
    this.closeMaskuserstate = this.closeMaskuserstate.bind(this);
    this.gotounlock = this.gotounlock.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.submitcode =this.submitcode.bind(this)
    this.submitcodechange =this.submitcodechange.bind(this)
    this.closeCsLive =this.closeCsLive.bind(this)
    this.changeseriesid =this.changeseriesid.bind(this)

    
    this.valueChangetel = this.valueChangetel.bind(this);
    this.valueChangecode = this.valueChangecode.bind(this);


    this.gologin = this.gologin.bind(this);
    this.goreg = this.goreg.bind(this);
    
    this.gobuy =this.gobuy.bind(this)
    


    

    this.state = {
      closelayerstate:false,
      isloadingstate:false,

      starttime: 0,
      userstate: false,
      userid: '',
      userinfo: '',
      thisuserstate: false,
      thisuserstateadd: false,
      maskfalg: false,
      cslayerstate:false,
      cardcode:'',
      seriesid:'',
      userinfo: '',
      islock:0,
      thisshowitem:{},
      thisshowtitle:false,
      cslayertext:'',
      thisshowok:false,
      thisshowokitem:{},
      
      islove: false,
      information: {
        type: 0,//3: 往期课程  2: 正在直播 1：未开始
        src: '',
        starttime: '2019/10/1 9:00',
        title: '',
        thisreg: false,
        tips: [],
        time: '',
        teacher: '',
        location: '',
        student: '',
        value: ''
      },

      grouprole:false,
      cancreate:false,

      isprivate:true,
      

      showtimeoverstate:false,
      starttimenum:100,  //判断提前进入的时间还有多少秒
      istimestate:false,

      grouptalkrole:false,
      grouptalklayer:false,

      isnophone:false,
      addyzm:'',
      addtel:'',
      codeText:'验证码',
      codeerror:false,



    }
  }
  

  gobuy(){
    //console.log(1111,this.state.seriesid,this.state.thisshowitem.id)
    this.props.history.push('/shopmalldetail?typeids='+this.state.thisshowitem.id)
  }
  
  valueChangetel(e) {
    this.setState({
      addtel: e.target.value,
    })
    
  }
  valueChangecode(e) {
    this.setState({
      addyzm: e.target.value,
      codeerror:false,
    })
    
  }



  gotounlock =()=>{
    console.log(this.state.thisshowitem)
    fetch(
      "https://shl-info.cn/front/Monitor/addMonitorData?userid="+localStorage.getItem('userid')+"&intMonitortype=4"+"&pagetype=详情页" +"&seriesid="+this.state.thisshowitem.id +"&source=西影力"   
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
    })
      
    this.setState({
      cslayerstate:true,
    })
  }
  
  goreg (){
    console.log(1111)
    
    localStorage.setItem('refurl', window.location.href)
    this.props.history.push('/loginlist?reg=1&courseid='+this.classid);
  }
  gologin (){
    localStorage.setItem('refurl', window.location.href)
    this.props.history.push('/loginlist?courseid='+this.classid);
    console.log(2222)
  }


  setTick() {
    let time = new Date(this.state.information.starttime).getTime() - new Date().getTime();
    if (time <= 0) {
      time = 0;
      clearInterval(this.timeId);
      if(this.state.istimestate){
        this.setState({
          showtimeoverstate:true,
        })
      }
      return false;
    }
    //计算出相差天数
    let days = Math.floor(time / (24 * 3600 * 1000));
    //计算出小时数
    let leave1 = time % (24 * 3600 * 1000);    //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000));
    //计算相差分钟数
    let leave2 = leave1 % (3600 * 1000);        //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000));
    //计算相差秒数
    let leave3 = leave2 % (60 * 1000);      //计算分钟数后剩余的毫秒数
    let seconds = Math.round(leave3 / 1000);
    let starttimenum = this.state.starttimenum - 1
    // if(starttimenum>=0){
     
      if(starttimenum==0){
       let list =  this.state.information
           list.beforetype = 2
        this.setState({
          //showtimeoverstate:true,
          information:list
        })
      }
    // }
    this.setState({
      starttime: days + '天' + hours + '小时' + minutes + "分钟" + seconds + "秒",
      starttimenum:starttimenum,
    })
  }

  //抛送用户点击观看直播按钮记录
  adduserlooklive = () =>{
    fetch(
      "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          coursecode:this.state.information.coursecode,
          userid:localStorage.getItem('userid'),
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      
    })
  }

  gotoVideo() {

    console.log(this.state.userinfo)
    console.log(this.state.information)
    console.log(this.iscs)
    console.log(this.state.information.type)
    let usertype = this.state.userinfo.type

    let userid = localStorage.getItem('userid');
    console.log(usertype)
    console.log(this.state.information.isout)
    console.log(this.state.information.isvip)
    console.log(this.state.information.isreg)
    console.log(this.state.information.isregover)
    //已判断直接跳走
    // if (this.state.information.isout!=1 && !userid){
    //   this.setState({
    //     thisuserstateadd: true,
    //   })
    // }
    console.log(this.state.information.type)
    window.addreadlogsfiles(this.state.information.title+"-按钮点击-"+this.state.information.type, '')
    this.adduserlooklive()


    //  this.setState({
    //   thisuserstateadd: true,
    //   thisuserstateaddtext: '未登录',
    // })
    // {this.state.information.type !== 3
    //   ? this.state.information.type !== 1
    //     ? (this.state.information.type !== 2 ? "" : "正在直播")
    //     : (this.state.thisreg ? "已报名" : "我要报名")
    //   : "进入课程"}

    if (usertype == 1 && this.iscs!=1 && (this.state.information.isregover == 1 || this.state.information.isvip == 1)) {
      
      console.log(112000000)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }
    if (usertype == 2 && this.iscs!=1 && (this.state.information.isregover == 1 || this.state.information.isvip == 1)) {
      console.log(1111111111)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }
    if ((usertype == 2 || usertype == 5)  && (this.state.information.isvip == 1)  && this.iscs!=1) {
      console.log(222222222)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }


    if (!usertype && usertype !== 0 && this.iscs!=1 && (this.state.information.isregover == 1 || this.state.information.isvip == 1 || this.state.information.isreg == 1)) {
      console.log(33333)
      this.setState({
        thisuserstateadd: true,
      })
      //alert('暂没有权限 ');
      return false;
    }


    if ((usertype == 1 || usertype == 2 || usertype == 3 || usertype == 5) && (this.state.information.isout != 1)  && this.iscs!=1) {
      console.log(44444)
      this.setState({
        thisuserstateadd: true,
      })
      //alert('暂没有权限 ');
      return false;
    }


    if (this.state.information.isvip != 1 && this.state.information.isregover != 1 && this.state.information.isout == 1  && this.iscs==1) {

    } else {
      
      console.log(55555)
      console.log(this.state.thisuserstate)
      if( !localStorage.getItem('userid') && this.state.information.iszhibo==1){
        this.setState({
          thisuserstateadd: true,
        })
        //alert('暂没有权限 ');
        return false;
      }else  if (!this.state.thisuserstate ) {
        this.setState({
          thisuserstateadd: true,
        })
        //alert('暂没有权限 ');
        return false;
      }
    }
    if (!userid && this.state.information.type == 2  && this.iscs!=1 ) {
      console.log('usertype')
      
      console.log(6666)
      //没有登录
      this.setState({
        thisuserstateadd: true,
        thisuserstateaddtext: '未登录',
      })
      //alert('暂没有权限 ');
      return false;
    }
    console.log("测试状态1")
   // return false;

    if (this.state.information.type === 1) {
      if (this.state.thisreg) {
        //return false;
        if(this.state.information.beforetype!=1){
          if(this.state.information.beforetype ==2){
            fetch(
              "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
                method: 'POST',
                body: JSON.stringify({
                  coursecode:this.state.information.coursecode,
                  userid:localStorage.getItem('userid'),
                  clicktype:2,
                  iscert:1,
                }),
                headers:{
                  'content-type': 'application/json',
                  'token':localStorage.getItem('token')
                },
                header: {
                  'content-type': 'application/json',
                  'token':localStorage.getItem('token')
                },
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
                console.log(res)
            })
            this.adduserlooklive()

             //添加新积分
             this.addscorenew()
           }

           if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
            console.log('ismudu')
            //新加判断跳转旧目睹也需要手机号
            if(!encrypt(localStorage.getItem('phone'))){
              console.log(222)
              this.setState({
                isnophone:true,
              })
              return false;
            }
            fetch(
              'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                headers:{
                  'token':localStorage.getItem('token')
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              
            })
            fetch(
              "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
              localStorage.getItem('userid')+"&eventid="+ this.classid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              console.log(res)
              if(res.code==2000){
                let muduurl =res.data
                window.location.href=muduurl;
              }
            });
          } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
            console.log('ismudu')
            fetch(
              'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                headers:{
                  'token':localStorage.getItem('token')
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              
            })
            fetch(
              "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
              localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              console.log(res)
              if(res.code==2000){
                let muduurl =res.data
                window.location.href=muduurl;
              }
            });
          }else if(this.state.information.muduurl!=''  && this.state.information.muduurl!='null'  &&  this.state.information.muduurl!=null){
            window.open(this.state.information.muduurl)
          }else if(this.state.information.coursetype==1){
            window.open(this.state.information.courseurl)
          }else if (this.state.information.zhibotype ==1){
            if(!encrypt(localStorage.getItem('phone'))){
              console.log(222)
              this.setState({
                isnophone:true,
              })
              return false;
            }

            fetch(
              "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
              localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(reszhiqu => {
              if(reszhiqu.data){
               // let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+reszhiqu.data
                let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+reszhiqu.data
                //window.location.href=zhiquurls
                window.open(zhiquurls)
              }
              console.log(reszhiqu)
            });

          }else{

            if(this.state.information.videoinfo['videoid']){
              // this.props.history.push('/broadcast' + this.props.location.search);

              if(this.iscs !=1){
                this.props.history.push('/broadcast' + this.props.location.search);
              }else if((this.state.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
              //}else if((this.state.information.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
                this.props.history.push('/broadcast' + this.props.location.search);
              }else{
                this.gotounlock()
              }
            }
           
          }
         
        }
      } else {
        // 报名
        if (this.state.information.zhibotype ==1){
          if(!encrypt(localStorage.getItem('phone'))){
            console.log(222)
            this.setState({
              isnophone:true,
            })
            return false;
          }
        }
        console.log(this.state.information.title)
        //抛送致趣日志
        fetch(
          "https://shl-info.cn/front/frontc/addzhiqusignlogs?userid=" +
          this.props.userinfo.userid +
          "&coursetitle=" +
          this.state.information.title+
          "&usertel=" +
          this.props.userinfo.info.phone+
          "&types=" +
          this.props.userinfo.info.type
          ,{
            headers:{
              'token':this.props.userinfo.token 
            }
          }
        )

        //return false
        $.ajax({
          type: "GET",
          url:
            "https://shl-info.cn/front/frontc/coursesignup?userid=" +
            this.props.userinfo.userid  +
            "&courseid=" +
            this.classid,
          dataType: "json",
          
          headers:{'token':this.props.userinfo.token},
          cache: false
        }).then(res => {
          if (res && res.status !== 2) {
            
            
             if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
              console.log('ismudu')
              //新加判断跳转旧目睹也需要手机号
              if(!encrypt(localStorage.getItem('phone'))){
                console.log(222)
                this.setState({
                  isnophone:true,
                })
                return false;
              }
              fetch(
                'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                  headers:{
                    'token':localStorage.getItem('token')
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                
              })
              fetch(
                "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
                localStorage.getItem('userid')+"&eventid="+ this.classid,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                console.log(res)
                if(res.code==2000){
                  let muduurl =res.data
                  window.location.href=muduurl;
                }
              });
            } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
              console.log('ismudu')
              fetch(
                'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
                  headers:{
                    'token':localStorage.getItem('token')
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                
              })
              fetch(
                "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
                localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(res => {
                console.log(res)
                if(res.code==2000){
                  let muduurl =res.data
                  window.location.href=muduurl;
                }
              });
            }else if (this.state.information.zhibotype ==1){
              if(!encrypt(localStorage.getItem('phone'))){
                console.log(222)
                this.setState({
                  isnophone:true,
                })
                return false;
              }

              fetch(
                "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
                localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
                  headers:{
                    'token':localStorage.getItem('token') 
                  }
                }
              )
              .then(data => {
                return data.json();
              })
              .then(reszhiqu => {
                if(reszhiqu.data){
                 // let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+reszhiqu.data
                  let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+reszhiqu.data
                  //window.location.href=zhiquurls
                  window.open(zhiquurls)
                }
                console.log(reszhiqu)
              });
            }else{
              this.setState({
                maskfalg: true,
                thisreg: true,
              });
            }
            
          }
        });



      }
    } else {

      if(this.state.information.beforetype ==2){
        fetch(
          "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
            method: 'POST',
            body: JSON.stringify({
              coursecode:this.state.information.coursecode,
              userid:localStorage.getItem('userid'),
              clicktype:2,
              iscert:1,
            }),
            headers:{
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
            header: {
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
            console.log(res)
        })
       }

        //添加新积分
        this.addscorenew()
       
        if(this.state.information.type==3 && !this.state.information.videoinfo['videoid']){
          
          if(this.state.information.isscorm==1 && this.state.information.scormpath){

            if(!localStorage.getItem('userid')){

              this.setState({
                thisuserstateadd: true,
                thisuserstateaddtext: '未登录',
              })
              return 
            }
            // /front/scormcourse/setUserClickScorm
            fetch(
              "https://shl-info.cn/front/scormcourse/setUserClickScorm?userid=" +
              localStorage.getItem('userid')+"&token="+localStorage.getItem('token') +"&courseid="+this.classid,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
              window.location.href="/scorm/index.html?url="+this.state.information.scormpath+"&courseid="+this.classid
            })
            return false;
            //window.location.href="/scorm/index.html?url="+this.state.information.scormpath+"&courseid="+this.classid
          }
          if(this.state.information.coursetype==1 && this.state.information.courseurl){
            window.location.href=this.state.information.courseurl
          }
          


          return;
        }


      console.log(this.state.information.muduurl)
      if(this.state.information.videoinfo['videoid'] && this.state.information.type==3){
        if(this.iscs !=1){
          this.props.history.push('/broadcast' + this.props.location.search);
        }else if((this.state.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
          this.props.history.push('/broadcast' + this.props.location.search);
        }else{
          this.gotounlock()
        }
      }else if(this.state.information.muduurl!=''  && this.state.information.muduurl!='null'  &&  this.state.information.muduurl!=null){
        console.log(1111)
        //window.location.href=this.state.information.muduurl;
        
        window.open(this.state.information.muduurl)
      }else if(this.state.information.coursetype==1){
        //window.location.href=this.state.information.courseurl;
        window.open(this.state.information.courseurl)
      }else if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
        console.log('ismudu',encrypt(localStorage.getItem('phone')))

        //新加判断跳转旧目睹也需要手机号
        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }

        // return false
        

        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+ this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
        console.log('ismudu')
        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      }else if (this.state.information.zhibotype ==1){
        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }
        fetch(
          "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
          localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          if(res.data){
           // let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+res.data
            let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+res.data
            //window.location.href=zhiquurls
            window.open(zhiquurls)
          }
          console.log(res)
        });
        console.log(1234)
      }else{

        if(this.state.information.videoinfo['videoid']){
          if(this.iscs !=1){
            this.props.history.push('/broadcast' + this.props.location.search);
          }else if((this.state.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
            this.props.history.push('/broadcast' + this.props.location.search);
          }else{
            this.gotounlock()
          }
        }

      
        console.log(2222)
        // if(this.iscs !=1){
        //   this.props.history.push('/broadcast' + this.props.location.search);
        // }else if((this.state.islock ==1 || this.state.information.coursepay ==1) && this.iscs ==1){
        //   this.props.history.push('/broadcast' + this.props.location.search);
        // }else{
        //   this.gotounlock()
        // }
      }
      // if(this.classid==1068 || this.classid==1068){
      //   //window.location.href="http://mudu.tv/live/watch?id=me1g016l";
      //   this.props.history.push('/broadcast' + this.props.location.search);
      // }else if(this.classid==1055 || this.classid==1055){
      //   //window.location.href="http://mudu.tv/live/watch?id=obb32vjo";
      //   this.props.history.push('/broadcast' + this.props.location.search);
      // }else if(this.classid==1133 || this.classid==1133){
      //   //window.location.href="http://mudu.tv/live/watch?id=lr6ygxpl";
      //   this.props.history.push('/broadcast' + this.props.location.search);
      // }else if(this.classid==1148 || this.classid==1148){
      //   //window.location.href="http://mudu.tv/live/watch?id=lk20kepl";
      //   this.props.history.push('/broadcast' + this.props.location.search);
      // }else if(this.classid==1136 || this.classid==1136){
      //    //window.location.href="http://mudu.tv/live/watch?id=oyg93eno";
      //   this.props.history.push('/broadcast' + this.props.location.search);
      // }else{
      //   this.props.history.push('/broadcast' + this.props.location.search);
      // }
      //this.props.history.push('/broadcast' + this.props.location.search);
    }
  }
  closeMask() {
    this.setState({
      maskfalg: false
    })
  }
  gotoliveurl = ()=>{
    if(this.state.information.beforetype!=1){
      if(this.state.information.beforetype ==2){
        fetch(
          "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
            method: 'POST',
            body: JSON.stringify({
              coursecode:this.state.information.coursecode,
              userid:localStorage.getItem('userid'),
              clicktype:2,
              iscert:1,
            }),
            headers:{
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
            header: {
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
            console.log(res)
        })
        this.adduserlooklive()
        
        //添加新积分
        this.addscorenew()
       }

       if(this.state.information.beforetype==3 && !this.state.information.videoinfo['videoid']){
        console.log(1)
        return;
      }

      console.log(123)

      console.log(this.state.information.muduurl)
      if(this.state.information.videoinfo['videoid'] && this.state.information.beforetype==3){
        if(this.iscs !=1){
          this.props.history.push('/broadcast' + this.props.location.search);
        }else if((this.state.islock ==1  || this.state.information.coursepay ==1) && this.iscs ==1){
          this.props.history.push('/broadcast' + this.props.location.search);
        }else{
          this.gotounlock()
        }
      }else if(this.state.information.muduurl!=''  && this.state.information.muduurl!='null'  &&  this.state.information.muduurl!=null){
        window.open(this.state.information.muduurl)
      }else if(this.state.information.coursetype==1){
        window.open(this.state.information.courseurl)
      }else if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
        console.log('ismudu')

        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }
        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+ this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
        console.log('ismudu')
        
        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      }else if (this.state.information.zhibotype ==1){
        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }
        fetch(
          "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
          localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          if(res.data){
            //let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+res.data
            let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+res.data
            window.open(zhiquurls)
          }
          console.log(res)
        });
      }else{
        
        console.log(12345)
        console.log(2222)
        if(this.state.information.videoinfo['videoid']){
          if(this.iscs !=1){
            this.props.history.push('/broadcast' + this.props.location.search);
          }else if((this.state.islock ==1  || this.state.information.coursepay ==1) && this.iscs ==1){
            this.props.history.push('/broadcast' + this.props.location.search);
          }else{
            this.gotounlock()
          }
        }
      }
     
    }else{

    }
  }

  closeMaskuserstate() {

    //this.props.history.push('/loginlist');
    this.setState({
      thisuserstateadd: false,
      seriesid:'',
    })
  }
  closelivestate = ()=>{
    this.setState({
      showtimeoverstate:false,
    })
  }

  changeseriesid =(id)=>{
    this.setState({
      seriesid:id
    })
    console.log(id)
  }
  
  submitcodechange(){
    console.log(1111)
    if(this.state.cardcode!=''){
      this.setState({
        thisshowtitle:true,
      })
    }else{
      this.setState({
        cslayertext:"请输入",
      })
    }
  }

  submitcode(){
    //alert(111)
    console.log(33332222211111)
    console.log(this.state.cardcode)
    if(this.state.thisshowtitle && this.state.thisshowok){
      this.setState({
        cslayerstate:false,
        thisshowtitle:false,
        thisshowok:false,
      })
      return false;
    }
    if(!this.state.seriesid){
      this.setState({
        cslayertext:"请选择系列",
        thisshowtitle:false,
        thisshowok:false,
      })
      return false;
    }
    let that=this
    if(this.state.cardcode!=''){
      $.ajax({
        type: "POST",
        url:
          "https://shl-info.cn/front/Cscardcode/buyCourse",
          data: JSON.stringify({
            seriesid: this.state.seriesid,
            cardcode: this.state.cardcode,
            userid: localStorage.getItem('userid'),
          }),
        dataType: "json",
        
        headers:{'token':localStorage.getItem('token')},
        cache: false,
        success: function (res) {
          if(res.code==200){
            that.setState({
              cslayertext:'',
              cslayerstate:true,
              islock:1,
              thisshowok:true,
              thisshowokitem:res
            })
            // let userlist = that.state.csxilielist
            //     userlist[that.state.csxilieids].list[that.state.csshowindex]['islock']=1;
            //     that.setState({
            //       csxilielist:userlist
            //     })
            //     console.log(userlist)
          }else{
              that.setState({
                cslayertext:res.msg,
                thisshowtitle:false,
              })
          }
        }
      })
    }
  }

  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //////console.log('4_a给params对象赋值:', params)
    }                                           //////console.log('5_结果:', params)
    return params;
  }

  componentDidMount() {
    // 获取课程ID

    let that = this
    this.classid = this.props.location.search.split('&')[0].slice(6);
    this.iscs = 0;
    if(window.location.href.indexOf('isaicontent=1')>=0){

      var urls = this.getUrlkey(window.location.href);
      let aiid = urls.aiid;
      fetch(
        "https://shl-info.cn/front/Ai/setAiCourseLog?userid=" +
        localStorage.getItem('userid') +
        "&courseid=" +
        this.classid +
        "&aiid=" +
        aiid +
        "&type=course" ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
      .then(data => {
        return data.json();
      })
      .then(res => {
        console.log(res)
      })
    }
    if(window.location.href.indexOf('iscs=1')>=0){
      this.iscs = 1
    }
    if(window.location.href.indexOf('?')>0){
      var urls = this.getUrlkey(window.location.href);
      console.log(urls)

      let  a = window.location.href.split('&zqauth=')[1] || '';
      
      let  b = a.split('&')[0] || '';
      let oldurl = window.location.href.split('&zqauth=')[0] || '';
      // console.log(a)
      // console.log(b)
      // return ;
      if(urls.zqauth){
        fetch('https://shl-info.cn/front/user/zhiQuLogin?zqauth='+encodeURIComponent(b) +"&changetype=" +
            localStorage.getItem('changetype'))
          .then((data) => {
            return data.json();
          })
          .then((res) => {
            if(res.code==2000 && res.data.userid){
              localStorage.setItem('userid', res.data.userid)
              localStorage.setItem('token', res.data.token)
              localStorage.setItem('isdi', res.data.list.isdi)
              localStorage.setItem('iswaitype', res.data.list.type)
              localStorage.setItem('phone', res.data.list.phone)
              localStorage.setItem('name', res.data.list.name)
              localStorage.setItem('email', res.data.list.email)
              localStorage.setItem('special', res.data.special)
              window.location.href=oldurl
              //return false;
            }else{
             
            }
          })
      }
    }
    console.log(this.iscs)

    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    this.setState({
      userid: userid,
    })
    let thisuserid = userid;
    if (userid && token) {
      // 验证是否过期
      $.ajax({
        type: "GET",
        url: "https://shl-info.cn/front/user/checkuser?userid=" +
          userid ,
        dataType: "json",
        headers:{'token':token},
        success: function (res) {
          console.log(res)
          console.log(22222222222)
          if (res.status === 1) {
            localStorage.setItem('usertype', res.list.usertype)

            localStorage.setItem('headimg', res.pic)
            localStorage.setItem('names', decrypt(res.name))
            localStorage.setItem('name', decrypt(res.name))
            
            if(res.list.roletype==1 || res.list.roletype==2){
              that.setState({
                cancreate:true
              })
            }

            var userlist = res.list;
                        if(userlist.name){
                            userlist.name = decrypt(userlist.name);
                        }
                        if(userlist.email){
                            userlist.email = decrypt(userlist.email);
                        }
                        if(userlist.phone){
                            userlist.phone = decrypt(userlist.phone);
                        }
                        res.list = userlist
            that.setState({
              userstate: true,
              userinfo: res.list,
            })
            // localStorage.setItem("userid", userid);
            // localStorage.setItem("token", res.token);
            // stateProps.change_userinfo({
            //   token: res.token,
            //   pic: res.pic,
            //   userid: userid,
            //   info: res.list ? res.list : stateProps.userinfo.info
            // });
          } else {
            //this.history.push("/");
          }
        }
      });

      
    }


    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/coursedetail?userid=" +
        userid +
        "&courseid=" +
        this.classid+
        "&iscs=" +
        this.iscs+
        "&changetype=" +
        localStorage.getItem('changetype'),
      dataType: "json",
      headers:{'token':token},
      cache: false
    }).then(res => {
      console.log(res)
      that.classidcode= res.coursecode
      this.setState({
        isloadingstate:true,
      })
      if (res && res.status !== 2) {
        if (res.code == 200) {
          this.setState({
            thisuserstate: true,
          })
        }
        console.log(res)
        if(res.isprivate==1){
          this.setState({
            isprivate:true
          })
        }else{
          this.setState({
            isprivate:false
          })
        }
        if(res.starttimenum +1800>0){
          this.setState({
            istimestate:true,
          })
        }
        this.setState(
          {
            information: res,
            thisreg: res.signup,
            islock:res.islock,
            starttimenum:res.starttimenum,
            thisshowitem:res.seriesinfo?res.seriesinfo[0]:'',
          },
          () => {
            this.timeId = setInterval(() => {
              this.setTick();
            }, 1000);
          }
        );
        if (res.islove) {
          this.setState({
            islove: true,
          })
        }
      }
    });

    
      //获取用户群组身份信息
      this.checkuserstate();


  }

  setgolive = () =>{
    // let that=this
    // console.log(this.state.information.starttimenum)
    // setTimeout(function(){
    //   that.setgolive()
    // },1000)
  }

  checkuserstate = ()=>{
    fetch(
      "https://shl-info.cn/front/Groupchat/getUserRoleById?userid=" +
      localStorage.getItem('userid') ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.code==2000){
        //注释添加群组必修
        this.setState({
          grouprole:res.data.ishave,
          grouptalkrole:res.data.isshare
          // grouprole:false
        })
      }else{

      }

    });
  }





  //加入课程
  clickMylove() {
    let usertype = this.state.userinfo.type;
    let userid = localStorage.getItem('userid');
    if (usertype == 1 && (this.state.information.isregover == 1 || this.state.information.isvip == 1)) {
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }
    if (usertype == 2 && (this.state.information.isregover == 1 || this.state.information.isvip == 1)) {
      console.log(1111111111)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }
    if ((usertype == 2 || usertype == 5) && (this.state.information.isvip == 1)) {
      console.log(222222222)
      this.setState({
        thisuserstateadd: true,
      })
      return false;
    }


    if (!usertype && usertype !== 0 && (this.state.information.isregover == 1 || this.state.information.isvip == 1 || this.state.information.isreg == 1)) {
      console.log(44444)
      this.setState({
        thisuserstateadd: true,
      })
      //alert('暂没有权限 ');
      return false;
    }


    if ((usertype == 1 || usertype == 2 || usertype == 3 || usertype == 5) && (this.state.information.isout != 1)) {
      console.log(44444)
      this.setState({
        thisuserstateadd: true,
      })
      //alert('暂没有权限 ');
      return false;
    }


    if (this.state.information.isvip != 1 && this.state.information.isregover != 1 && this.state.information.isout == 1) {

    } else {
      if (!this.state.thisuserstate) {
        this.setState({
          thisuserstateadd: true,
        })
        //alert('暂没有权限 ');
        return false;
      }
    }
    if (!userid && this.state.information.type == 2) {
      console.log('usertype')
      //没有登录
      this.setState({
        thisuserstateadd: true,
        thisuserstateaddtext: '未登录',
      })
      //alert('暂没有权限 ');
      return false;
    }
    if (!userid) {
      console.log('usertype')
      //没有登录
      this.setState({
        thisuserstateadd: true,
        thisuserstateaddtext: '未登录',
      })
      //alert('暂没有权限 ');
      return false;
    }



    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/addlearningplan?userid=" +
        this.props.userinfo.userid +
        "&courseid=" +
        this.classidcode +
        "&type=1"+"&iscs="+this.iscs,
      dataType: "json",
      headers:{'token':this.props.userinfo.token},
    }).then(res => {
      if (res.status === 1) {
        this.setState({
          islove: !this.state.islove
        });
      }
    });
  }
  closeCsLive(){
    console.log(111111)
    this.setState({
      cslayerstate:false,
      seriesid:'',
      thisshowtitle:false,
      thisshowok:false,
    })
  }
  
  valueChange(e) {
    
    this.setState({
      cardcode: e.target.value,
      cslayertext:'',
    })
    
  }
  closenophonebox = () =>{
    this.setState({
      isnophone:false,
    })
  }
  subphone = () =>{
    fetch(
      "https://shl-info.cn/front/user/setUserPhone?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          userid:localStorage.getItem('userid'),
          code:this.state.addyzm,
          phone:this.state.addtel,
          meetingid:this.state.information.zhiqumeetingid,
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
      console.log(res)
      if(res.data && res.code==2000){
        localStorage.setItem('phone', this.state.addtel)
        if (this.state.information.type === 1) {
          this.gotoVideo()
          let that = this
          setTimeout(function(){
            that.setState({
              thisreg:true,
              isnophone:false,
            })
          },500)
         
        }else  if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
          fetch(
            'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
              headers:{
                'token':localStorage.getItem('token')
              }
            }
          )
          .then(data => {
            return data.json();
          })
          .then(res => {
            
          })
          
        //新加判断跳转旧目睹也需要手机号
          if(!encrypt(localStorage.getItem('phone'))){
            console.log(222)
            this.setState({
              isnophone:true,
            })
            return false;
          }
          fetch(
            "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
            localStorage.getItem('userid')+"&eventid="+ this.classid,{
              headers:{
                'token':localStorage.getItem('token') 
              }
            }
          )
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log(res)
            if(res.code==2000){
              let muduurl =res.data
              window.location.href=muduurl;
            }
          });
        }else{
          let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+res.data
          window.location.href=zhiquurls;
        }
      }else{
        this.setState({
          codeerror:true
        })
      }
    })
  }
  getphoneCode = () => {
    console.log(111111)
    if (
      (this.state.codeText !== "验证码" &&
        this.state.codeText !== "重新获取") ||
      !(/^1[3456789]\d{9}$/.test(this.state.addtel))
    ) {
      return false;
    }
    let nums = 90;
    let timer = () => {
      this.setState({
        codeText: nums > 0 ? nums + " s" : "重新获取"
      });
      nums -= 1;
      if (nums >= 0) {
        clearTimeout(this.codetimer)
        this.codetimer = setTimeout(timer, 1000)
      } else {
        nums = 0;
      }
    };
    timer();
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/user/getphonecode?phone=" +
        encodeURIComponent(encrypt(this.state.addtel)) + '&islogin=1',
      dataType: "json",
      cache: false,
      success: res => { }
    });
  };

  componentWillUnmount() {
    clearInterval(this.timeId)
  }
  closelayer = ()=>{
    this.setState({
      closelayerstate:false,
      grouptalklayer:false,
    })
    console.log(1111)
  }
  showselectgroup = ()=>{
    this.setState({
      closelayerstate:true
    })
  }
  showtalkselectgroup = ()=>{
    this.setState({
      grouptalklayer:true
    })
  }

  addscorenew = ()=>{
    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Scoreshop/setScore?userid=" +
        localStorage.getItem('userid') +
        "&usertype=" +
        localStorage.getItem('usertype'),
      data: JSON.stringify({
        userid: localStorage.getItem('userid'),
        type: 2,
        courseid:this.classid,
        attachid:'',
        bbsid:'',
      }),
      dataType: "json",
      headers:{'token':localStorage.getItem('token')},
      cache: false
    })
      .catch(error => {
      })
      .then(res => {
        console.log(res)
        if(res.code==2000){
          this.setState({
            scorelsyerlist:res.data,
            scorelsyerstate:true,
          })
        }
        
        //console.log(res)
      });
  }
  isprivatecourse = ()=>{
    console.log(1111)
    if(!localStorage.getItem("userid")){
      this.setState({
        thisuserstateadd: true,
        thisuserstateaddtext: '未登录',
      })
      return false;
    }

    if(this.state.information.beforetype==1){
      if(!this.state.thisreg){
        fetch(
          "https://shl-info.cn/front/livevideo/applyPrivateCourse",{
            method: 'POST',
            body: JSON.stringify({
              coursecode:this.state.information.coursecode,
              userid:localStorage.getItem('userid'),
            }),
            headers:{
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
            header: {
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          this.setState({
            thisreg:true,
          })
        })
      }
    }else{
      
      if(this.state.information.beforetype ==2){
        fetch(
          "https://shl-info.cn/front/livevideo/setUserCourseClick?userid="+localStorage.getItem('userid'),{
            method: 'POST',
            body: JSON.stringify({
              coursecode:this.state.information.coursecode,
              userid:localStorage.getItem('userid'),
              clicktype:2,
              iscert:1,
            }),
            headers:{
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
            header: {
              'content-type': 'application/json',
              'token':localStorage.getItem('token')
            },
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
            console.log(res)
        })
        this.adduserlooklive()
       }

       
       
      
      if(this.state.information.beforetype==3 && !this.state.information.videoinfo['videoid']){
        console.log(1)
        return;
      }

      if(this.state.information.videoinfo['videoid'] && this.state.information.beforetype==3 ){
        this.props.history.push('/broadcast' + this.props.location.search);
      }else if(this.state.information.muduurl!=''  && this.state.information.muduurl!='null'  &&  this.state.information.muduurl!=null){
        console.log(1111)
        //window.location.href=this.state.information.muduurl;
        window.open(this.state.information.muduurl)
      }else if(this.state.information.coursetype==1){
        //window.location.href=this.state.information.courseurl;
        window.open(this.state.information.courseurl)
      }else if(this.state.information.beforetype ==2 && this.state.information.isoldmudu ==1 &&this.state.information.oldmuduactid){
        console.log('ismudu')
        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }
        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getOldMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+ this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      } else if(this.state.information.beforetype ==2 && this.state.information.ismudu ==1 &&this.state.information.mudueventid){
        console.log('ismudu')
        
        fetch(
          'https://shl-info.cn/front/frontc/coursesignup?userid=' + localStorage.getItem('userid') + '&courseid=' + this.classid,{
            headers:{
              'token':localStorage.getItem('token')
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          
        })
        fetch(
          "https://shl-info.cn/front/linkmudu/getMuduEventUrl?userid=" +
          localStorage.getItem('userid')+"&eventid="+this.state.information.mudueventid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            let muduurl =res.data
            window.location.href=muduurl;
          }
        });
      }else if (this.state.information.zhibotype ==1){
        if(!encrypt(localStorage.getItem('phone'))){
          console.log(222)
          this.setState({
            isnophone:true,
          })
          return false;
        }
        fetch(
          "https://shl-info.cn/front/user/getZhiQuUserInfo?userid=" +
          localStorage.getItem('userid')+"&phone="+encodeURIComponent(encrypt(localStorage.getItem('phone'))) +"&meetingid="+this.state.information.zhiqumeetingid  +"&courseid="+this.classid,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          if(res.data){
            //let zhiquurls = this.state.information.zhiqumeetingurl+"?lanxiaoStr="+res.data
            let zhiquurls = "https://app.ma.scrmtech.com/meetings-api/sapIndex/SapSourceData?pf_uid=13205_"+this.state.information.officialaccountid+"&sid="+this.state.information.zhiqumeetingid+"&source=2&pf_type=3&lanxiaoStr="+res.data
            window.open(zhiquurls)
          }
          console.log(res)
        });
      }else{
        if(this.state.information.videoinfo['videoid']){
          this.props.history.push('/broadcast' + this.props.location.search);
        }
      }
    }
    
   
  }


  gobackgroup = ()=>{
    let groupcode = localStorage.getItem('jumpgroupcode');
    localStorage.setItem('jumpgroupcode','');
    console.log("/groupDetailinfo?groupids="+groupcode)
   this.props.history.push('/groupDetailinfo?groupids=' + groupcode);
  }


  render() {
    // console.log(this.state.userinfo)
    // console.log(this.state.information)
    let usertype = this.state.userinfo.type;
    // console.log(usertype)
    if (this.state.information.isout == 0 || this.state.information.isout == 1) {
      if (this.state.information.isout != 1 && !this.state.userid) {
        console.log(111)
        this.props.history.push('/');
      }
    }
    let labellist = this.state.information.tips.map((item) =>
      <li key={item.id}>
        {item.text}
      </li>
    )
    let time = null;
    if (this.state.information.type === 1) {
      time = (
        <span className="start-time">
          距离课程开始还有{this.state.starttime}
          
        </span>
      )
    }
    //let layertimeove = null
    
    let maskbox = null;

   // console.log(this.state.starttimenum)
    if(this.state.showtimeoverstate ){
      console.log("timeove")
      maskbox = (
        <MaskBox
          info={{
            title: '直播详情',
            text: '现在可以提前进入直播间',
            golivestate:true,
          }}
          gotoliveurl={this.gotoliveurl}
          closeMask={this.closelivestate}>
        </MaskBox>
      )
    }
    
    if (this.state.thisuserstateadd) {
      maskbox = (
        <MaskBox
          info={{
            title: '课程详情',
            
            //text: "抱歉，您目前还没有注册/登录，\n请您点击前往注册/登录",
            goglinsstate:localStorage.getItem('userid') >0 &&localStorage.getItem('userid') ?false:true,
            text: localStorage.getItem('userid') >0 &&localStorage.getItem('userid') ? '请点击右上角切换身份，并登陆/注册，\n查看更多详情':'抱歉，您目前还没有注册/登录，\n 请您点击前往注册/登录',
          }}
          
          goreg= {this.goreg}
          gologin= {this.gologin}
          closeMask={this.closeMaskuserstate}>
        </MaskBox>
      )
    }
    if (this.state.maskfalg) {
      maskbox = (
        <MaskBox
          info={{
            title: '课程详情',
            text: '报名成功'
          }}
          closeMask={this.closeMask}>
        </MaskBox>
      )
    }

    let isnophonetext = null
    if(this.state.isnophone){
      isnophonetext=<div className='nophonebox'>
        <div className='nophoneinputbox'>
          <div className='nophoneinputboxtitle'>请补全您的手机号码
            <span className="common-maskclose cursor" onClick={()=>this.closenophonebox()}></span>
          </div>
          <div className="nophoneinputboxpadding">
          <div class="login-form" >
            <span class="form-name">手机号码</span>
              <input name="tel" type="number" placeholder="请输入" value={this.state.addtel} onChange={this.valueChangetel} />
              <div className="errorcode" style={{bottom:"-22px"}}>*若已注册外部账号，请保持手机号一致。</div>
            </div>
            
           


            <div class="login-form">
              <span class="form-name">手机验证码</span>
              <input name="code" placeholder="请输入"  value={this.state.addyzm}  onChange={this.valueChangecode} />
              <div className={
                  !/^1[3456789]\d{9}$/.test(this.state.addtel)
                    ? "code-btn"
                    : "code-btn active"
                }  onClick={this.getphoneCode}>{this.state.codeText}</div>

              <div className={this.state.codeerror?"errorcode":"none"}>验证码错误</div>
            </div>

            <div class={/^1[3456789]\d{9}$/.test(this.state.addtel) && this.state.addyzm?"login-submit active":"login-submit"}  onClick={()=>this.subphone()}>确认</div>
            </div>
        </div>
      </div>
    }

    return (
      <div className='detailspage'>
        {isnophonetext}
        {/* <Header active={this.props.active}></Header> */}

        {this.classid ? <Header active={this.props.active} typeids={this.classid}></Header> : null}
        <div className="details-banner addnewheight">
        {/* {this.state.information.isscorm==1?<div className="detail-isscormbanner">交互式课程</div>:null} */}
          <img src={this.state.information.srcpic} alt="" />
          <span className="bannerMaks"></span>
          {time}
        </div>
        {this.state.cslayerstate?(
           <CsLiveCode 
           closeCsLive={this.closeCsLive}
           isdetail={true}
           valueChange={this.valueChange}
           cardcode={this.state.cardcode}
           submitcode={this.submitcode}
           
           gobuy={this.gobuy}
           seriesinfo={this.state.information.seriesinfo}
           submitcodechange={this.submitcodechange}
           changeseriesid={this.changeseriesid}
           seriesid={this.state.seriesid}
           thisshowitem={this.state.thisshowitem}
           thisshowtitle={this.state.thisshowtitle}
           name_place="请输入您的兑换码"
           cslayertext={this.state.cslayertext}
           thisshowok={this.state.thisshowok}
           thisshowokitem={this.state.thisshowokitem}
           ></CsLiveCode>
        ):null}


        <div className="course-information">
          <div className="wrap">
            {this.iscs==1 && this.state.information.coursepay !=1?
              (
                <div className="detail-cslist_btnlist">
                    <div className={this.state.islock==1?"none":"cslist_gochange"} onClick={()=>this.gotounlock()}>点击解锁</div>
                  <div className={this.state.islock == 1 ? "cslist_state active" : "cslist_state"}>{this.state.islock == 1 ? "已解锁" : "未解锁"}</div>
                </div>
              )
            :null}
            {this.state.information.isscorm==1?<div className="detail-isscorm">交互式课程{this.state.information.outtime?<span>有效期至：{this.state.information.outtime}</span>:null}</div>:null}
            <h4 className="course-title">{this.state.information.title}
                {/* {this.state.grouprole && !this.state.isprivate? <span className="addbycourse" onClick={()=>this.showselectgroup()}>
                  <img src={require("../../static/images/addbycourse.png")} /> 
                  添加为群组必修</span>:null} */}

                  {this.state.grouprole && !this.state.isprivate? <span className="addbytalkcourse" onClick={()=>this.showtalkselectgroup()}>
                  <img src={require("../../static/images/addbycourse.png")} /> 
                  分享给群组</span>:null}
               
            </h4>
            <ul className="courselabel overflow">
              {labellist}
            </ul>
            <div className="overflow course-textlist">
              <p className="course-time course-text">{this.state.information.time}</p>
             {this.state.information.teacher?<p className="course-teacher course-text">{this.state.information.teacher}{this.state.information.location?"-"+this.state.information.location:""}</p>:null}
             {this.state.information.teachercompany?<p className="course-type course-text">{this.state.information.teachercompany}</p>:""}


             
             {this.state.information.en ?<p className="course-lang course-text">{this.state.information.en}</p>:null}

                
            {this.state.information.isscorm?<div className="course-tips-text icon-computer_pep">电脑端建议使用Chrome浏览器进行学习</div>:null}

            {this.state.information.isscorm?<div className="course-tips-text icon-phone_pep">手机端仅支持安卓手机的Chrome浏览器学习</div>:null}       
             
            </div>
            

            
            <div className="course-value wrap">

              <div className='singup-btn-box addfloat'>
                {this.state.isprivate?
                <div>
                {localStorage.getItem('jumpgroupcode') && this.state.isloadingstate?<div className='gobackgroup' onClick={()=>this.gobackgroup()}>返回群组</div>:null}
                <div className="signup-btn" onClick={() => this.isprivatecourse()}>
                  {this.state.information.beforetype !== 3
                    ? this.state.information.beforetype !== 1
                      ? (this.state.information.beforetype !== 2 ? "" : this.state.showtimeoverstate?"正在直播":this.state.information.iszhibo==1?"进入直播":this.state.information.videoinfo['videoid']||this.state.information.coursetype==1?"进入课程":<span className='gray'>直播结束</span>)
                      : (this.state.thisreg ?this.state.starttimenum<=0?this.state.showtimeoverstate?"正在直播":this.state.information.iszhibo==1?"进入直播":this.state.information.videoinfo['videoid']||this.state.information.coursetype==1?"进入课程":<span className='gray'>直播结束</span>: "已报名" : "我要报名")
                    : this.state.information.videoinfo['videoid']||this.state.information.coursetype==1?"进入课程":<span className='gray'>直播结束</span>}
                </div>
                {!this.state.islove && this.iscs !=1 && localStorage.getItem('userid')  ? (
                    <div onClick={() => this.clickMylove()} className={this.state.information.type !== 3
                      ? this.state.information.type !== 1
                        ? (this.state.information.type !== 2 ? "none" : "none")
                        : (this.state.thisreg ? "none" : "signup-btn")
                      : "signup-btn"} >加入学习计划
                    </div>
                  ) : null}
                </div>
                :
                <div>
                  {localStorage.getItem('jumpgroupcode')  && this.state.isloadingstate?<div className='gobackgroup' onClick={()=>this.gobackgroup()}>返回群组</div>:null}
                  <div className="signup-btn" onClick={() => this.gotoVideo()}>
                    {this.state.information.type !== 3
                      ? this.state.information.type !== 1
                        ? (this.state.information.type !== 2 ? "" : this.state.showtimeoverstate?"正在直播":"进入直播")
                        : (this.state.thisreg ?this.state.starttimenum<=0?this.state.showtimeoverstate?"正在直播":"进入直播": "已报名": "我要报名")
                      : this.state.information.videoinfo['videoid']||this.state.information.coursetype==1|| this.state.information.isscorm==1?"进入课程":<span className='gray'>直播结束</span>}
                  </div>
                  {!this.state.islove && this.iscs !=1 && localStorage.getItem('userid') ? (
                    <div onClick={() => this.clickMylove()} className={this.state.information.type !== 3
                      ? this.state.information.type !== 1
                        ? (this.state.information.type !== 2 ? "none" : "none")
                        : (this.state.thisreg ? "none" : "signup-btn")
                      : "signup-btn"} >加入学习计划
                    </div>
                  ) : null}
                </div>}
                

              </div>


              <h5>课程介绍</h5>
              <div dangerouslySetInnerHTML={{ __html: this.state.information.value }} className='newp'></div>
              {/* <p >{this.state.information.value}</p> */}
            </div>
            <div className='singup-btn-box none'>
              {this.state.isprivate?
              <div>
              <div className="signup-btn" onClick={() => this.isprivatecourse()}>
                {this.state.information.beforetype !== 3
                  ? this.state.information.beforetype !== 1
                    ? (this.state.information.beforetype !== 2 ? "" : this.state.showtimeoverstate?"正在直播":this.state.information.iszhibo==1?"进入直播":this.state.information.videoinfo['videoid']||this.state.information.coursetype==1?"进入课程":<span className='gray'>直播结束</span>)
                    : (this.state.thisreg ?this.state.starttimenum<=0?this.state.showtimeoverstate?"正在直播":this.state.information.iszhibo==1?"进入直播":this.state.information.videoinfo['videoid']||this.state.information.coursetype==1?"进入课程":<span className='gray'>直播结束</span>: "已报名" : "我要报名")
                  : this.state.information.videoinfo['videoid']||this.state.information.coursetype==1?"进入课程":<span className='gray'>直播结束</span>}
              </div>
              {!this.state.islove && this.iscs !=1 && localStorage.getItem('userid')  ? (
                  <div onClick={() => this.clickMylove()} className={this.state.information.type !== 3
                    ? this.state.information.type !== 1
                      ? (this.state.information.type !== 2 ? "none" : "none")
                      : (this.state.thisreg ? "none" : "signup-btn")
                    : "signup-btn"} >加入学习计划
                  </div>
                ) : null}
              </div>
              :
              <div>
                <div className="signup-btn" onClick={() => this.gotoVideo()}>
                  {this.state.information.type !== 3
                    ? this.state.information.type !== 1
                      ? (this.state.information.type !== 2 ? "" : this.state.showtimeoverstate?"正在直播":"进入直播")
                      : (this.state.thisreg ?this.state.starttimenum<=0?this.state.showtimeoverstate?"正在直播":"进入直播": "已报名": "我要报名")
                    : this.state.information.videoinfo['videoid']||this.state.information.coursetype==1|| this.state.information.isscorm==1?"进入课程":<span className='gray'>直播结束</span>}
                </div>
                {!this.state.islove && this.iscs !=1 && localStorage.getItem('userid') ? (
                  <div onClick={() => this.clickMylove()} className={this.state.information.type !== 3
                    ? this.state.information.type !== 1
                      ? (this.state.information.type !== 2 ? "none" : "none")
                      : (this.state.thisreg ? "none" : "signup-btn")
                    : "signup-btn"} >加入学习计划
                  </div>
                ) : null}
              </div>}
              

            </div>
          </div>
        </div>
        {maskbox}
        {this.state.closelayerstate? <GroupSelect cancreate={this.state.cancreate} history={this.props.history} classid={this.classid} closelayer = {()=>this.closelayer()}></GroupSelect>:null}
       
        {this.state.grouptalklayer? <GroupSelectlist cancreate={this.state.cancreate} history={this.props.history} iscs={this.iscs} information ={this.state.information}  classid={this.classid} closelayer = {()=>this.closelayer()}></GroupSelectlist>:null}
       
        <Footer></Footer>
      </div>
    )
  }
}

export default CourseDetails
