import { combineReducers } from 'redux'
import defaultstate from './state'
// 登录状态
function login(state = defaultstate.login, action) {
  return state
}
// 导航记录
function active(state = defaultstate.active, action) {
  switch (action.type) {
    case 'CHANGE_ACTIVE':
      return action.data
    default:
      return state
  }
}
function active_two(state = defaultstate.active_two, action) {
  switch (action.type) {
    case 'CHANGE_ACTIVE_TWO':
      return action.data
    default:
      return state
  }
}
function active_series(state = defaultstate.active_series, action) {
  switch (action.type) {
    case 'CHANGE_ACTIVE_SERIES':
      return action.data
    default:
      return state
  }
}
/* 首页 */
// 首页banner列表
function home_bannerlist(state = defaultstate.home_bannerlist, action) {
  switch (action.type) {
    case 'CHANGE_HOME_BANNERLIST':
      return action.data
    default:
      return state
  }
}
// 首页课程中心
function home_classlist(state = defaultstate.home_classlist, action) {
  switch (action.type) {
    case 'CHANGE_HOME_CLASSLIST':
      return action.data
    default:
      return state
  }
}
// 首页课程中心
function home_infolist(state = defaultstate.home_infolist, action) {
  switch (action.type) {
    case 'CHANGE_HOME_INFOLIST':
      return action.data
    default:
      return state
  }
}

// 首页直播中心
function home_playlist(state = defaultstate.home_playlist, action) {
  switch (action.type) {
    case 'CHANGE_HOME_PLAYLIST':
      return action.data
    default:
      return state
  }
}
// 第二个课程中心
function home_recommend(state = defaultstate.home_recommend, action) {
  switch (action.type) {
    case 'CHANGE_HOME_RECOMMEND':
      return action.data
    default:
      return state
  }
}
// 首页推荐课程
function home_classfour(state = defaultstate.home_classfour, action) {
  switch (action.type) {
    case 'CHANGE_HOME_CLASSFOUR':
      return action.data
    default:
      return state
  }
}


/* 直播中心 */
// banner
// banner
function play_bannerlist(state = defaultstate.play_bannerlist, action) {
  switch (action.type) {
    case 'CHANGE_PLAY_BANNERLIST':
      return action.data
    default:
      return state
  }
}
// 课程预告
function play_developments(state = defaultstate.play_developments, action) {
  switch (action.type) {
    case 'CHANGE_PLAY_DEVELOPMENT':
      return action.data
    default:
      return state
  }
}
// 近期回放
function play_hotclass(state = defaultstate.play_hotclass, action) {
  switch (action.type) {
    case 'CHANGE_PLAY_HOTCLASS':
      return action.data
    default:
      return state
  }
}


/* 课程中心 */
function back_bannerlist(state = defaultstate.back_bannerlist, action) {
  switch (action.type) {
    case 'CHANGE_BACK_BANNERLIST':
      return action.data
    default:
      return state
  }
}


function back_fircourselist(state = defaultstate.back_fircourselist, action) {
  switch (action.type) {
    case 'CHANGE_BACK_FIRCOURSELIST':
      return action.data
    default:
      return state
  }
}
function back_reviewclass(state = defaultstate.back_reviewclass, action) {
  switch (action.type) {
    case 'CHANGE_BACK_REVIEWCLASS':
      return action.data
    default:
      return state
  }
}
function back_hotback(state = defaultstate.back_hotback, action) {
  switch (action.type) {
    case 'CHANGE_BACK_HOTBACK':
      return action.data
    default:
      return state
  }
}
function back_reviewClassArr(state = defaultstate.back_reviewClassArr, action) {
  switch (action.type) {
    case 'CHANGE_REVIEWCLASSARR':
      return action.data
    default:
      return state
  }
}
function back_question(state = defaultstate.back_question, action) {
  switch (action.type) {
    case 'CHANGE_QUESTION':
      return action.data
    default:
      return state
  }
}
// 系列课程列表
function developments(state = defaultstate.developments, action) {
  switch (action.type) {
    case 'CHANGE_DEVELOPMENTS':
      return action.data
    default:
      return state
  }
}
/* 文档中心 */
function file_hordownlist(state = defaultstate.file_hordownlist, action) {
  switch (action.type) {
    case 'CHANGE_HORDOWNLIST':
      return action.data
    default:
      return state
  }
}
function file_typedownlist(state = defaultstate.file_typedownlist, action) {
  console.log(action.type)
  switch (action.type) {
    case 'CHANGE_TYPEDOWNLIST':
      return action.data
    default:
      return state
  }
}
function file_hotdownarr(state = defaultstate.file_hotdownarr, action) {
  switch (action.type) {
    case 'CHANGE_FILE_HOTDOWNARR':
      return action.data
    default:
      return state
  }
}

/* 个人中心 */
// 推荐课程
function person_classlist(state = defaultstate.person_classlist, action) {
  switch (action.type) {
    case 'CHANGE_PERSON_CLASSLIST':
      return action.data
    default:
      return state
  }
}
// 观看历史
function person_history(state = defaultstate.person_history, action) {
  switch (action.type) {
    case 'CHANGE_PERSON_HISTORY':
      return action.data
    default:
      return state
  }
}
// 学习记录
function person_learnrecord(state = defaultstate.person_learnrecord, action) {
  switch (action.type) {
    case 'CHANGE_PERSON_LEARNCORD':
      return action.data
    default:
      return state
  }
}
// 下载记录
function person_downrecord(state = defaultstate.person_downrecord, action) {
  switch (action.type) {
    case 'CHANGE_PERSON_DOWNRECORD':
      return action.data
    default:
      return state
  }
}
// 我的证书
function person_certificate(state = defaultstate.person_certificate, action) {
  switch (action.type) {
    case 'CHANGE_CERTIFICATE':
      return action.data
    default:
      return state
  }
}

// 我的收藏
function person_collect(state = defaultstate.person_collect, action) {
  switch (action.type) {
    case 'CHANGE_PERSON_COLLECT':
      return action.data
    default:
      return state
  }
}
// 定制课程
function person_customized(state = defaultstate.person_customized, action) {
  switch (action.type) {
    case 'CHANGE_CUSTOMIZED':
      return action.data
    default:
      return state
  }
}
// 指向课程
function person_pointclass(state = defaultstate.person_pointclass, action) {
  switch (action.type) {
    case 'CHANGE_POINTCLASS':
      return action.data
    default:
      return state
  }
}
// 子账号管理
function person_accountlist(state = defaultstate.person_accountlist, action) {
  switch (action.type) {
    case 'CHANGE_ACCOUNTLIST':
      return action.data
    default:
      return state
  }
}

/* 统一筛选标签 */
function common_labellist(state = defaultstate.common_labellist, action) {
  switch (action.type) {
    case 'CHANGE_LABELLIST':
      return action.data
    default:
      return state
  }
}
function common_search(state = defaultstate.common_search, action) {
  switch (action.type) {
    case 'CHANGE_COMMON_SEARCH':
      return action.data
    default:
      return state
  }
}
// 用户信息
function userinfo(state = defaultstate.userinfo, action) {
  switch (action.type) {
    case "CHANGE_USERINFO":
      return action.data;
    default:
      return state;
  }
}
// 正则
function verification(state = defaultstate.verification, action) {
  return state
}
const reducers = combineReducers({
  // 登录
  login,
  // 记录导航状态
  active,
  active_two,
  active_series,
  // 首页
  home_bannerlist,
  home_classlist,
  home_infolist,
  home_recommend,
  home_playlist,
  home_classfour,
  // 直播中心
  play_bannerlist,
  play_developments,
  play_hotclass,
  // 课程中心
  back_fircourselist,
  back_bannerlist,
  back_reviewclass,
  back_hotback,
  back_reviewClassArr,
  back_question,
  developments,
  // 文档中心
  file_hordownlist,
  file_typedownlist,
  file_hotdownarr,
  // 个人中心
  person_classlist,
  person_history,
  person_learnrecord,
  person_certificate,
  person_collect,
  person_customized,
  person_pointclass,
  person_accountlist,
  person_downrecord,
  // 筛选标签
  common_labellist,
  common_search,
  userinfo,
  verification,
})

export default reducers
