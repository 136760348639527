import React from 'react'
import { Link } from 'react-router-dom'

import './index.css'

function ShopclassList(props) {
    // console.log(props)
   
    
    return (
        <div className='classlist transform_hover' onClick={()=>props.godetail(props._item)}>
            <div className="shoplistbox_li">
                <div className='classlist-img'>
                    <img src={props._item.bannerurl} className='tranform_img' alt="" />
                </div>
                <h2 className="classlist-title">{props._item.name}</h2>
                <ul className="classlist-label">
                    <li  className="shopmallcoursecard_iconlist_money">¥ {props._item.id==105?1088:props._item.money?props._item.money:140}</li>
                   {props._item.id !=999? <li>
                        视频数量： {props._item.coursecount?props._item.coursecount.isshipin:0}
                    </li>:null}
                </ul>
            </div>
        </div>
    )
}

export default ShopclassList
