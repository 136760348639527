import React, { useState, useEffect } from 'react'
import Search from '../Search'
import './index.css'
import $ from 'jquery'

let uploadtmp = '';
let groupids = '';
const IndexComponent = (props) => {

  let [interfacetmp, setInterface] = useState('')
  let [progress, setProgress] = useState(0)
  groupids = props.groupid;
  useEffect(() => {
    if(props.groupid){
      fetch(
        "https://shl-info.cn/front/Groupchat/getvhalltoken?userid=" +
        localStorage.getItem('userid')  ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)

          let option = {
            appId: "b24f7dc9", // appId
            accountId: localStorage.getItem('userid'), // 第三方用户id
            token: res.token, // token
          }
          console.log("window.VhallUpload")
          // 创建实例句柄
        
          window.VhallUpload.createInstance(option, (res) => {
            console.log(res)
            setInterface( res.interface ); // 创建成功
            uploadtmp = res.interface 
            
          },
          err => {
            console.error(err); // 失败返回错误信息
          });
          
        });

      console.log("uploadtmp")
      
      $("#uploadvideo").change(function(e){

        
        var imgsize=this.files[0];

        if (imgsize && !imgsize.name.match(/\.mp4$/)) {
            $('.uploadlayer').show();
            $('.common-progress').html("请上传.mp4格式的视频文件！")
            setTimeout(function(){

              $('.common-progress').html("0%")
              $('.uploadlayer').hide();
            },2000)
            
            $('#uploadvideo').val(''); // 清空选中的文件
            return false;
        }

      
        $('.uploadlayer').show();
        console.log(imgsize)
        let fileList = [imgsize];  // 文件对象列表 例如：[file, file, file]
        let progress = function(e){
        

          setProgress(parseInt(e.progress*100))
          // $("#jindu").find("span").html(parseInt(e.progress*100)+"%");
          // console.log(e.progress); // 上传进度，0至1，1为上传完成
          // console.log(33333333333); // 上传进度，0至1，1为上传完成
          if(e.progress!=1){
            $('.uploadlayer').show();
            let num = parseInt(e.progress*100)
            if(num>99){
              num=99
            }
            $('.common-progress').html(num+"%")
          }else{
            $('.uploadlayer').show();
            $('.common-progress').html("99%")
          
            // $('.uploadlayer').hide();
          }
        }
        let successok = function(res){
            // $("#uploadvideohide").val(1);
            // console.log(111111); // 上传成功，返回文件对象
            console.log(res); // 上传成功，返回文件对象
            let param = {
            file:res.file,   // 文件对象
            fileName:getRandomString(6)   // 新的文件名
            }
            console.log(param);
            let success = function(res){
                console.log(222222222); 
                $('#videoid').val(res.recordId);
                console.log(props.groupid)
                console.log(groupids)
                uploadvideoid(res.recordId);
              
                $('#uploadvideo').val(''); // 清空选中的文件
                $('.uploadlayer').show();
                $('.common-progress').html("上传完成")
                setTimeout(function(){
                  $('.uploadlayer').hide();
                  $('.common-progress').html("0%")
                },2000)
                
                console.log(res.recordId); // 创建成功，返回点播recordId
            }

            let failure = function(err){
              $('.uploadlayer').show();
              $('.common-progress').html(err.msg)
              setTimeout(function(){
                $('.uploadlayer').hide();
              },2000)
                console.log(err); // 创建失败，返回失败信息
            }
            uploadtmp.createDemand(param,success,failure);
        }
        let failureok = function(err){
          $('.uploadlayer').show();
          $('.common-progress').html(err.msg)
          setTimeout(function(){
            $('.uploadlayer').hide();
          },2000)
            console.log(err); // 上传失败，返回信息
        }
        console.log(uploadtmp)
        uploadtmp.upload(fileList,progress,successok,failureok);
      }); 
    }
  },[props.groupid])
  useEffect(() => {
   

  },[])
  
  let uploadvideoid = (videoid) =>{
    fetch(
      "https://shl-info.cn/front/Groupchat/uploadvideoid?userid=" +
      localStorage.getItem('userid')+"&videoid="+videoid +"&groupid="+props.groupid ,{
        headers:{
          'token':localStorage.getItem('token') 
        }
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
        console.log(res)
    })
  }
  let  getRandomString = (length) =>{
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  return (
    <div className="groupChatupButton" >
      
          上传视频
          <input type="file" name="" id="uploadvideo" accept="video/mp4" />
          <div className="common-mask uploadlayer">
            <div className="common-maskbox ">
                <h2 className="common-masktitle">
                    上传中
                    {/* <span
                        className="common-maskclose cursor"
                        // onClick={() => props.closeMask()}
                        ></span> */}
                </h2>

                <div className="common-masktext">
                  <p className='common-progress'>0%</p>
                </div>
            </div>
           </div>
    </div>
  )
};

export default IndexComponent;
