import $ from 'jquery'

import Header from '../components/header'
// 修改导航记录
export function change_active(data) {
  return { type: 'CHANGE_ACTIVE', data: data }
}
export function change_active_two(data) {
  //console.log(data)
  return { type: 'CHANGE_ACTIVE_TWO', data: data }
}
export function change_active_series(data) {
  console.log(data,44444)
  return { type: 'CHANGE_ACTIVE_SERIES', data: data }
}

// 统一修改
// 修改课程中心内容
export function change_home_data() {
  return ((dispatch, getState) => {

    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    let changetype = localStorage.getItem('changetype')

    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/gethomepagelist?userid=" +
        userid +
        "&changetype=" +
        changetype,
      dataType: "json",
      headers:{'token':token},
      cache: false,
      success: function (res) {
        console.log(res)
        if (res && res.status !== 2) {
          dispatch(change_home_bannerlist(res.bannerlist));
          dispatch(change_home_classlist(res.coursecenterlist));
          dispatch(change_home_playlist(res.livecenterlist));
          dispatch(change_home_recommend(res.recommendcourse));
          dispatch(change_home_classfour(res.hotattachlist));
          dispatch(change_reviewClassArr(res.xilies));
          dispatch(change_infolist(res.xilies));
          
        }
      }
    });
  })
}
// 首页banner
export function change_home_bannerlist(data) {
  return { type: 'CHANGE_HOME_BANNERLIST', data: [...data] }
}
// 课程中心
export function change_home_classlist(data) {
  return { type: 'CHANGE_HOME_CLASSLIST', data: [...data] }
}
// 课程中心
export function change_infolist(data) {
  return { type: 'CHANGE_HOME_INFOLIST', data: [...data] }
}
// 直播中心
export function change_home_playlist(data) {
  return { type: 'CHANGE_HOME_PLAYLIST', data: [...data] }
}
// 文档中心
export function change_home_recommend(data) {
  return { type: 'CHANGE_HOME_RECOMMEND', data: [...data] }
}
// 推荐课程
export function change_home_classfour(data) {
  return { type: 'CHANGE_HOME_CLASSFOUR', data: [...data] }
}

// 统一修改
// 修改课程中心内容

export function change_back_datatest(data) {
  console.log(data)
  var newdata = [];
  return ((dispatch, getState) => {
    dispatch(change_reviewClassArr(newdata));
  })
}
export function change_back_data(data,datatype,datanum) {
  console.log(data,datatype,datanum)
  console.log("data,datatype")
  
  let seachkeys='';
  if(data==6){
    seachkeys=1;
  }else if(data==7){
    seachkeys=2;
  }else if(data==8){
    seachkeys=3;
  }else{
    seachkeys=4;
  }
  console.log('list123')
  return ((dispatch, getState) => {
   
    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    let changetype = localStorage.getItem('changetype')
    let special = localStorage.getItem('special')
    dispatch(change_back_reviewclass([]));
    console.log(localStorage.getItem('userid'))
    $.ajax({
      type: "GET",
      //url: "https://shl-info.cn/front/frontc/gethomelist",
      //https://shl-info.cn/front/frontc/gethomelist
      url: "https://shl-info.cn/front/frontc/gethomelist?userid=" +
        userid +
        "&typeids=" +
        seachkeys +
        "&changetype=" +
        changetype+
        "&special=" +
        special,
      dataType: "json",
      
      headers:{'token':token},
      cache: false,
      success: function (res) {
        console.log(res)
        console.log(1111111)
        console.log(data)
        if (res && res.status !== 2) {
          //dispatch(change_back_bannerlist(res.bannerlist));
          dispatch(change_question(res.questions));
          dispatch(change_back_reviewclass(res.reviewcourselist));
          dispatch(change_back_hotback(res.hotcourselist));
          if (data == 2) {
            if (!res.xilies.disease) {
              res.xilies.disease = []
            }
            dispatch(change_reviewClassArr(res.xilies.disease));
          } else if (data == 3) {
            if (!res.xilies.hospitaldepart) {
              res.xilies.hospitaldepart = []
            }
            dispatch(change_reviewClassArr(res.xilies.hospitaldepart));
          } else if (data == 4) {
            if (!res.xilies.csmini) {
              res.xilies.csmini = []
            }
            dispatch(change_reviewClassArr(res.xilies.csmini));
          } else {
            if (!res.xilies.xilie) {
              res.xilies.xilie = []
            }
            dispatch(change_reviewClassArr(res.xilies.xilie));
            if(res.xilies.xilie.length>0 && datanum){
              for(var i=0;i<res.xilies.xilie.length;i++){
                
                console.log(res.xilies.xilie[i]['id'],datanum)
                if(res.xilies.xilie[i]['id'] == datanum){
                  console.log(res.xilies.xilie[i]['id'],datanum)
                  
                  dispatch(change_active_series(i))
                }
              }
            }
            if(res.xilies.xilie[0]){
              console.log(res.xilies.xilie[0]['id'])
              console.log(333333)
              fetch(
                "https://shl-info.cn/front/diexam/getBannerbySeries?id=" + res.xilies.xilie[0]['id'] +"&typeids="+data
                )
                .then(data => {
                  return data.json();
                })
                .then(res1 => {
                  if(res1.length>0){
                    dispatch(change_back_bannerlist(res1))
                  }
                })
                if(datatype==1){

                }else{

               
                  fetch(
                    "https://shl-info.cn/front/diexam/getCourseListByFirSeriesId/id/"+res.xilies.xilie[0]['id']+"?userid="+ localStorage.getItem('userid') +"&typeid="+data+"&tabtype=1",{
                      headers:{
                        'token':localStorage.getItem('token') 
                      }
                    }
                  )
                  .then(data => {
                    return data.json();
                  })
                  .then(res2 => {
                    // this.setState({
                    //   fircourselist:res.seriescourselist
                    // })
                    dispatch(change_back_fircourselist(res2.seriescourselist))
                    console.log(res)
                  })
                }
            }
          }

          //注销查询用户学习记录

          // $.ajax({
          //   type: "GET",
          //   //url: "https://shl-info.cn/front/frontc/gethomelist",
          //   //https://shl-info.cn/front/frontc/gethomelist
          //   url: "https://shl-info.cn/front/frontc/gethomelist_more?userid=" +
          //     userid +
          //     "&typeids=" +
          //     seachkeys,
          //   dataType: "json",
          //   headers:{'token':token},
          //   cache: false,
          //   success: function (res) {

          //     if (res && res.status !== 2) {
          //       dispatch(change_back_bannerlist(res.bannerlist));
          //       dispatch(change_question(res.questions));
          //       dispatch(change_back_reviewclass(res.reviewcourselist));
          //       dispatch(change_back_hotback(res.hotcourselist));
          //       if (data == 2) {
          //         if (!res.xilies.disease) {
          //           res.xilies.disease = []
          //         }
          //         dispatch(change_reviewClassArr(res.xilies.disease));
          //       } else if (data == 3) {
          //         if (!res.xilies.hospitaldepart) {
          //           res.xilies.hospitaldepart = []
          //         }
          //         dispatch(change_reviewClassArr(res.xilies.hospitaldepart));
          //       } else if (data == 4) {
          //         if (!res.xilies.csmini) {
          //           res.xilies.csmini = []
          //         }
          //         dispatch(change_reviewClassArr(res.xilies.csmini));
          //       } else {
          //         if (!res.xilies.xilie) {
          //           res.xilies.xilie = []
          //         }
          //         dispatch(change_reviewClassArr(res.xilies.xilie));
          //       }

          //     }
          //   }
          // })




        }
      }
    });
  })
}
// 课程中心banner
export function change_back_bannerlist(data) {
  console.log(data)
  return { type: 'CHANGE_BACK_BANNERLIST', data: [...data] }
}
export function change_back_fircourselist(data) {
  console.log(data)
  return { type: 'CHANGE_BACK_FIRCOURSELIST', data: [...data] }
}
// 课程中心精彩回顾
export function change_back_reviewclass(data) {
  return { type: 'CHANGE_BACK_REVIEWCLASS', data: [...data] }
}
// 课程中心专家讲堂
export function change_back_hotback(data) {
  return { type: 'CHANGE_BACK_HOTBACK', data: [...data] }
}
// 课程中心系列课程
export function change_reviewClassArr(data) {
  return { type: 'CHANGE_REVIEWCLASSARR', data: [...data] }
}
// 课程中心常见问题
export function change_question(data) {
  return { type: 'CHANGE_QUESTION', data: [...data] }
}
// 系列课程列表
export function change_developments_data(id, thistypenums) {
  return ((dispatch, getState) => {

    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    dispatch(change_developments([]))
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/seriescoursebyid/id/" +
        id +
        "?userid=" +
        userid +
        "&typeid=" +
        thistypenums ,
      dataType: "json",
      
      headers:{'token':token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_developments(res.seriescourselist));
        }
      }
    });
  })
}
export function change_developments(data) {
  return { type: 'CHANGE_DEVELOPMENTS', data: [...data] }
}

// 统一修改
// 修改直播中心内容
export function change_play_data() {
  return ((dispatch, getState) => {

    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    let changetype = localStorage.getItem('changetype')

    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/getlivelist?userid=" +
        userid +
        "&changetype=" +
        changetype,
      dataType: "json",
      headers:{'token':token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_play_bannerlist(res.bannerlist));
          dispatch(change_play_developments(res.livecourselist));
          dispatch(change_play_hotclass(res.reviewcourselist));
        }
      }
    });
  })
}
// 课程中心banner
export function change_play_bannerlist(data) {
  return { type: 'CHANGE_PLAY_BANNERLIST', data: [...data] }
}
// 课程预告
export function change_play_developments(data) {
  return { type: 'CHANGE_PLAY_DEVELOPMENT', data: [...data] }
}
// 近期回放
export function change_play_hotclass(data) {
  return { type: 'CHANGE_PLAY_HOTCLASS', data: [...data] }
}

// 统一修改
// 修改文档中心
export function change_file_data() {
  return ((dispatch, getState) => {
    let userid = localStorage.getItem('userid')
    let token = localStorage.getItem('token')
    
    let changetype = localStorage.getItem('changetype')
    
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/filecenter?userid=" +
        userid +
        "&changetype=" +
        changetype,
      dataType: "json",
      headers:{'token':token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_file_hordownlist(res.hotattachlist));
          // dispatch(change_file_typedownlist(res.materialtypelist));
          dispatch(change_file_typedownlist(res.materialtypelist));
        }
      }
    });
  })
}
// 最热查看
export function change_file_hordownlist(data) {
  return { type: 'CHANGE_HORDOWNLIST', data: [...data] }
}
// 资料分类
export function change_file_typedownlist(data) {
  return { type: 'CHANGE_TYPEDOWNLIST', data: [...data] }
}
export function change_file_hotdownarr(data) {
  return { type: 'CHANGE_FILE_HOTDOWNARR', data: data }
}

// 个人中心信息获取
// 推荐课程
export function change_person_classlist() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/recommendcourse?userid=" +
        data.userid ,
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_person_class(res.recommendcourse));
        }
      }
    });
  })
}
export function change_person_class(data) {
  return { type: 'CHANGE_PERSON_CLASSLIST', data: [...data] }
}
// 观看历史
export function change_person_historydata() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;


    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/historycourse?userid=" +
        data.userid +
        "&changetype=" +
        localStorage.getItem('changetype'),
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_person_history(res.historycourse));
        }
      }
    });
  })
}
export function change_person_history(data) {
  return { type: 'CHANGE_PERSON_HISTORY', data: [...data] }
}
// 学习记录
export function change_person_learncord_data() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/learningrecord?userid=" +
        data.userid +
        "&changetype=" +
        localStorage.getItem('changetype'),
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_person_learncord(res));
        }
      }
    });
  })
}
export function change_person_learncord(data) {
  return { type: 'CHANGE_PERSON_LEARNCORD', data: { ...data } }
}
// 下载记录
export function change_person_downcord_data() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/userdownlog?userid=" +
        data.userid ,
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_person_downcord(res));
        }
      }
    });
  })
}
export function change_person_downcord(data) {
  return { type: 'CHANGE_PERSON_DOWNRECORD', data: [...data] }
}
// 我的证书
export function change_certificate_list() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/mycertificate?userid=" +
        data.userid +
        "&changetype=" +
        localStorage.getItem('changetype'),
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_certificate(res));
        }
      }
    });
  })
}
export function change_certificate(data) {
  return { type: 'CHANGE_CERTIFICATE', data: [...data] }
}
// 我的收藏
export function change_person_collect_data(id) {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/mycollection?userid=" +
        data.userid ,
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_person_collect(res));
        }
      }
    });
  })
}
export function change_person_collect(data) {
  return { type: 'CHANGE_PERSON_COLLECT', data: [...data] }
}
// 学习计划
export function change_person_learnplan() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/mylearningplan?userid=" +
        data.userid +
        "&changetype=" +
        localStorage.getItem('changetype'),
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_customized(res.customized));
          dispatch(change_pointclass(res.obligatory));
        }
      }
    });
  })
}
export function change_customized(data) {
  return { type: 'CHANGE_CUSTOMIZED', data: [...data] }
}
export function change_pointclass(data) {
  return { type: "CHANGE_POINTCLASS", data: [...data] };
}

// 账号相关/视频直播等相关
// 修改子账号管理数据
// 修改子账号管理数据
export function change_accountlist_data() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/subaccount?userid=" +
        data.userid ,
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_accountlist(res));
        }
      }
    });
  })
}
export function change_accountlist(data) {
  return { type: 'CHANGE_ACCOUNTLIST', data: [...data] }
}

// 修改标签状态
export function change_common_label() {
  return ((dispatch, getState) => {
    let data = getState().userinfo;
    $.ajax({
      type: "GET",
      url:
        "https://shl-info.cn/front/frontc/taglist?userid=" +
        data.userid ,
      dataType: "json",
      headers:{'token':data.token},
      cache: false,
      success: function (res) {
        if (res && res.status !== 2) {
          dispatch(change_labellist(res.taglist));
        }
      }
    });
  })
}
export function change_labellist(data) {
  return { type: 'CHANGE_LABELLIST', data: [...data] }
}
export function change_common_search(data) {
  return { type: 'CHANGE_COMMON_SEARCH', data: data }
}
// 修改用户信息
export function change_userinfo(data) {
  console.log(data)
  return { type: "CHANGE_USERINFO", data: { ...data } };
}
