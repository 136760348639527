import React, { Component } from 'react'
// 组件
import Header from '../../components/header'
import Footer from '../../components/footer'
import PersonImg from '../../components/personimg'
import PersonTitle from '../../components/persontitle'
import HistoryList from '../../components/historylist'
import $ from 'jquery'
import './index.css'
import { encrypt, decrypt } from '../../utils/crypto.ts'

class WatchHistory extends Component {
  constructor(props) {
    super(props)
    // this.autoReturn = this.autoReturn.bind(this);
    this.valueChange = this.valueChange.bind(this);
    
    this.state = {
      iscroll: null,
      grouplist:[],
      seachinput:'',
      cancreate:false,

      talklist:[],
      loadingstate:false,
    }
  }
  valueChange(e){
    this.setState({
      seachinput:e.target.value
    })
    console.log(e.target.value)
  }
  componentDidMount() {
    // this.props.change_active_two('watchhistory')
    // this.props.change_person_historydata()
    let userid = (localStorage.getItem('userid'));
    let token = (localStorage.getItem('token'));
    if (userid && token) {
      // 验证是否过期
      $.ajax({
          type: "GET",
          url: "https://shl-info.cn/front/user/checkuser?userid=" +
              userid ,
          dataType: "json",
          headers:{'token':token},
          success: function (res) {
            if(res.list){
              localStorage.setItem('headimg', res.pic)
              localStorage.setItem('names', decrypt(res.name))
              localStorage.setItem('name', decrypt(res.name))
            }else{
              localStorage.setItem('refurl', window.location.href);
              this.props.history.push('./loginnew')
            }
          }
      });
    
    }else{
      localStorage.setItem('refurl', window.location.href);
      this.props.history.push('./loginlist')
    }
    if (window.location.href.indexOf('?') >= 0) {
      var urls = this.getUrlkey(window.location.href);
      let pdfurl = urls.pdf
      this.getpdflist(pdfurl)


      if(window.location.href.indexOf('isaicontent=1')>=0){
        let aiid = urls.aiid;
        fetch(
          "https://shl-info.cn/front/Ai/setAiCourseLog?userid=" +
          localStorage.getItem('userid') +
          "&aiid=" +
          aiid +
          "&courseid=" +
          pdfurl +
          "&type=document" ,{
            headers:{
              'token':localStorage.getItem('token') 
            }
          }
        )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
        })
      }
    }

   
    $('.aicontentbox').css("height",($(window).height() - 120)+"px")
  }
  componentWillUnmount() {
    
  }
  getUrlkey(url) {
    var params = {};
    var urls = url.split("?");                  //////console.log('1_分割url:', urls)
    var arr = urls[1].split("&");               //////console.log('2_分割urls[1]:', arr)
    for (var i = 0, l = arr.length; i < l; i++) {
      var a = arr[i].split("=");                //////console.log('3_遍历 arr 并分割后赋值给a:', a[0], a[1])
      params[a[0]] = a[1];                      //////console.log('4_a给params对象赋值:', params)
    }                                           //////console.log('5_结果:', params)
    return params;
  }
  getpdflist = (pdf)=>{
    fetch(
      "https://shl-info.cn/front/ai/get_file_preview?userid="+localStorage.getItem('userid'),{
        method: 'POST',
        body: JSON.stringify({
          url:pdf,
          userid:localStorage.getItem('userid'),
        }),
        headers:{
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
        header: {
          'content-type': 'application/json',
          'token':localStorage.getItem('token')
        },
      }
    )
    .then(data => {
      return data.json();
    })
    .then(res => {
        console.log(res)
        if(res.code==2000){
          if(res.data.aliResult && res.data.aliResult.body){
            console.log(res.data.aliResult.body.accessToken)
            console.log(res.data.aliResult.body.webofficeURL)

            var mount = document.querySelector('#pdflist');
            let instance = window.aliyun.config({
                mount,
                url: res.data.aliResult.body.webofficeURL,
                mode: 'simple'
                //     refreshToken: refreshTokenPromise // Token过期自动刷新。
            });
            instance.setToken({
                token: res.data.aliResult.body.accessToken,
                timeout: 25 * 60 * 1000
            });
            // console.log(res.data.aliResult.body.webofficeURL)
          }
        }
    })
  
  }
  render() {
    
    return (
      <div className='ailistbox'>
        <Header active='4'></Header>
        <div className="aicontentbox ifroam"  id="pdflist">
        </div>
      </div>
    )
  }
}

export default WatchHistory
