import React, { useState, useEffect } from 'react'
import GroupAddUser from '../GroupAddUser'
import GroupUserJuris from '../GroupUserJuris'
import GroupNotice from '../GroupNotice'

import './index.css'

const IndexComponent = (props) => {
  

  let [groupDataAddUserOpen, setGroupDataAddUserOpen] = useState(false)
  let [groupDataUserJurisOpen, setGroupDataUserJurisOpen] = useState(false)
  let [groupDataUsertalkOpen, setGroupDataUsertalkOpen] = useState(false)
  let [sorttype, setSorttype] = useState('')

  let [packinfo, setPackinfo] = useState([])
  
  useEffect(() => {
    if(props.groupid){
      fetch(
        "https://shl-info.cn/front/groupchat/getCourseList?userid=" +
        localStorage.getItem('userid') +
        "&chatcode=" +
        props.groupid ,{
          headers:{
            'token':localStorage.getItem('token') 
          }
        }
      )
        .then(data => {
          return data.json();
        })
        .then(res => {
          console.log(res)
          if(res.code==2000){
            setPackinfo(res.data.packinfo)
          }
        });
    }
  }, [props.groupid])

  let groupDataUserItem = (item,index) => {
    return (
      <div className="groupDataUserItem">
        <img src={item.headimgurl?item.headimgurl:require('../../../../static/images/icon-portrait.png')} alt="" className="groupDataUserItemAvatar" />
        <div className="groupDataUserItemName text-overflow">{item.username?item.username:'昵称'}</div>
      </div>
    )
  }
  
  let groupDataUserList = () => {
    return (
      <div className="groupDataModule">
        <div className="groupDataModuleHead">
          <div className="groupDataModuleTitle">
            组内成员
          </div>
          <div className="groupDataModuleFunc">

          { props.usertypes==1 || props.usertypes==2 || props.usertypes==4  ?<img src={require('../../../../static/images/icon51.png')}  alt="" className="groupDataModuleFuncItem" onClick={() => setGroupDataUsertalkOpen(true)} />:null}
            {/* <img src={require('../../../../static/images/icon4.png')}  alt="" className="groupDataModuleFuncItem" onClick={() => setGroupDataAddUserOpen(true)} /> */}
            { props.usertypes==1 || props.usertypes==2 || props.usertypes==4  ?<img src={require('../../../../static/images/icon5.png')}  alt="" className="groupDataModuleFuncItem" onClick={() => setGroupDataUserJurisOpen(true)} />:null}
          </div>
        </div>
        <div className="groupDataModuleContent groupDataUserList">
          {props.groupuserinfo_admin.map((item,index)=>{
            return groupDataUserItem(item,index)
          })}
          {props.groupuserinfo_user.map((item,index)=>{
            return groupDataUserItem(item,index)
          })}
          {/* { groupDataUserItem() }
          { groupDataUserItem() }
          { groupDataUserItem() }
          { groupDataUserItem() }
          { groupDataUserItem() }
          { groupDataUserItem() } */}
        </div>
      </div>
    )
  }

  let groupDataRatePgsItem = (item,index) => {
    // console.log(item)
    let bi=0;
    if(item.coursenum!=0 && item.looknum!=0 ){
      bi=parseInt((item.looknum/item.coursenum) *100);
    }
    return (
      <div className="groupDataRatePgsItem">
        <div className="groupDataRatePgsItemHead">
          <div className="groupDataRatePgsItemTitle groupinfo text-overflow">{item.username}</div>
          <div className="groupDataRatePgsItemSubtitle">
          {item.type==4?"创建者":item.type==1?"组长":item.type==2?"管理员":item.type==3?"组员":''}
          </div>

          {/* <div className="groupDatascore">
         
          {props.isexam?props.isexam==1?  item.isexam==1? item.score+"分":"未考试":"":""}
          </div> */}
          {/* <div className={props.isexam==1?"examstate":'none'}>考试</div> */}
        </div>
        <div className="groupDataRatePgsItemBody">
          <div className="groupDataRatePgsItemExh groupinfo">
            <div className="groupDataRatePgsItemExhProp" style={{width: bi+'%'}}></div>
          </div>
          <div className="groupDataRatePgsItemExhNum">{bi}%</div>

          {/* <div className={props.isexam==1?"examstate":'none'}>{item.isexam==1?item.score:"未考试"}</div> */}
          {/* <div className={props.isexam==1?"examstate":'none'}>{item.isexamover==1?"完成":"未完成"}</div> */}
        </div>
      </div>
    )
  }

  let changesort = ()=>{
    if(sorttype){
      setSorttype('')
      props.getGroupStudyList('')
    }else{
      setSorttype('1')
      props.getGroupStudyList(1)
    }
  }

  let groupDataUserRateProgress = () => {
    return (
      <div className={props.showtipslayer && props.skipnum==8?"groupDataModule ":"groupDataModule"}>
        <div className="groupDataModuleHead">
          <div className="groupDataModuleTitle groupinfo">
            学习进度
            <div className='groupDatasortbox' id='groupDatasortbox' onClick={()=>changesort()}>
              <span className={sorttype==''?'sortup hover':"sortup"}></span>
              <span className={sorttype!=''?'sortdown hover':"sortdown"}></span>
              <span className='sorttext'>排序</span>
            </div>
          </div>
        </div>
        <div className="groupDataModuleContent groupDataRatePgs">
          {props.studylist.map((item,index)=>{
            // console.log(item)
            return groupDataRatePgsItem(item,index)
          })}
         
        </div>
      </div>
    )
  }
  let  returngroup = ()=>{
    
    fetch(
      "https://shl-info.cn/front/groupchat/userOutGroup", {
      method: 'POST',
      body: JSON.stringify({
        "chatcode": props.groupid,
        "userid": localStorage.getItem('userid'),
      }),
      headers:{
        'content-type': 'application/json',
        'token':localStorage.getItem('token')
      },
      header: {
        'content-type': 'application/json',
        'token':localStorage.getItem('token')
      },
    })
      .then(data => {
        return data.json();
      })
      .then(res => {
        if(res.code==2000){
          props.history.push("/groupmanipulat")
        }else{
          // .setState({
          //   ismasknew:true,
          //   masktitle:'退出失败',
          //   masktext:res.msg,
          // })
        }
      })
  }

  let changenotice = ()=>{
      props.changenotice();
  }

  return (
    <div className="groupData">
        <div className="groupDataView scrollbar" id='scrollbargroup'>
          { groupDataUserList() }
          { packinfo.length>0? groupDataUserRateProgress() :null }
        </div>
        {/* {props.usertypes!=4? <div className="returngroup" >
            <span onClick={()=>returngroup()}>退出群组</span>
          </div>:null} */}
        { groupDataAddUserOpen && <GroupAddUser groupid={props.groupid} groupinfo={props.groupinfo} closeFunc={() => setGroupDataAddUserOpen(false)}></GroupAddUser> }
        { groupDataUserJurisOpen && <GroupUserJuris usertypes={props.usertypes} groupid={props.groupid} closeFunc={() => setGroupDataUserJurisOpen(false)}></GroupUserJuris> }

        { groupDataUsertalkOpen && <GroupNotice changenotice={changenotice} groupinfo={props.groupinfo} usertypes={props.usertypes} groupid={props.groupid} closeFunc={() => setGroupDataUsertalkOpen(false)}></GroupNotice> }
        
    </div>
  )
};

export default IndexComponent;
