import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import utils from '../../utils/index'
import { encrypt, decrypt } from '../../utils/crypto.ts'
import $ from 'jquery'
import {
    change_active,
    change_labellist,
    change_active_two,
    change_common_search,
    change_common_label,
    change_userinfo
} from '../../store/action'
import { Link } from 'react-router-dom'
import classnames from 'classnames';

import MaskBox from '../maskbox'
import Speciallayer from '../speciallayer'
import Bbslayer from '../bbslayer'
import Grouplayer from '../grouplayer'
import Cslayer from '../cslayer'
import Cslayerover from '../cslayerover'








import './index.css'

class Header extends Component {
    constructor() {
        super()

        this.closeMask = this.closeMask.bind(this);
        this.state = {
            searchText: '',
            havegroup:false,
            groupinfo:[],
            study: false,
            userid: null,
            thisusertype: false,
            studyadmin: false,
            showfalg: false,
            noticenums: 0,
            maskfalg: false,
            loginstate: false,
            userstate: true,
            showlayerout: false,
            inputSelectModeOpen: false,
            inputSelectModeIndex: 0,
            inputSelectOption: [
                { name: '课程', id: '1' },
                { name: '话题', id: '2' },
                { name: '帖子', id: '3' },
            ],
            specialstate: false,
            canspecialstate: false,
            showcourselist: false,

            isbbslayer: false,
            isbbsinfo: '',

            HomeTipsinfo: '',


            
            cslist:[],
            cslistscroll:'',
            csliselect:'',
            cslistinfo:{},
            showcslayer:false,
            codeinfo:{},

            checkcodeover: false,
            
        }
    }

    getbbsinfo = () => {
        fetch(
            "https://shl-info.cn/front/Hometips/getHomeTips?userid=" +
            localStorage.getItem('userid'), {
            headers: {
                'token': localStorage.getItem('token')
            }
        }
        )
            .then(data => {
                return data.json();
            })
            .then(res => {

                // localStorage.setItem('issignstate', 0)
                if (res.code == 2000) {
                    if (res.data) {
                        this.setState({
                            HomeTipsinfo: res.data,
                            isbbslayer: true,

                        })
                    }
                }

                console.log(res)
            })

    }
    closebbslayer = () => {
        // fetch(
        //     "https://shl-info.cn/front/bbs/setTipsClickLog?userid="+ localStorage.getItem('userid')+"&clicktype=1&articletype="+this.state.isbbsinfo.tipstype+"&articleid="+this.state.isbbsinfo.articleid
        //   )
        //   .then(data => {
        //     return data.json();
        //   })
        //   .then(res => {
        //   })
        localStorage.setItem('issignstate', 0)
        window.addreadlogs('千人千面点击关闭-弹层')
        // window.addreadlogs('医医互动点击关闭-'+this.state.isbbsinfo.tipstype+'-'+this.state.isbbsinfo.articleid)
        this.setState({
            isbbslayer: false,
            maskfalg: false,
            loginstate: false,
            specialstate: true,
        })
    }


    closeMask(i) {
        console.log(i)
        if (this.props.signstate) {
            this.props.todayclick();
        }
        this.setState({
            maskfalg: false,
            loginstate: false,
            specialstate: true,
        })
        if (i == 1) {

            fetch(
                "https://shl-info.cn/front/Hometips/getHomeTips?userid=" +
                localStorage.getItem('userid'), {
                headers: {
                    'token': localStorage.getItem('token')
                }
            }
            )
                .then(data => {
                    return data.json();
                })
                .then(res => {
                    if (res.code == 2000) {
                        if (res.data) {
                            this.setState({
                                HomeTipsinfo: res.data,
                                isbbslayer: true
                            })
                        }
                    }
                    console.log(res)
                })
            //注释掉BBS弹层

            // fetch(
            //     "https://shl-info.cn/front/bbs/getTipsInfo"
            //   )
            //   .then(data => {
            //     return data.json();
            //   })
            //   .then(res => {
            //     if(res.data){
            //       this.setState({
            //         isbbsinfo:res.data,
            //         isbbslayer:true
            //       })
            //     }
            //     console.log(res)
            //   })
        }
    }
    searchchange = e => {
        this.setState({
            searchText: e.target.value
        })
    }
    gologinyes = e => {
        let email = localStorage.getItem('email');
        let name = localStorage.getItem('name');
        let phone = localStorage.getItem('phone');
        let sourcechannel = localStorage.getItem('sourcechannel');
        localStorage.clear();
        localStorage.setItem('phone', phone);
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);
        if (sourcechannel) {
            localStorage.setItem('sourcechannel', sourcechannel);
        }
        localStorage.setItem('refurl', window.location.href);
        this.props.history.push('./loginlist')
    }
    gologinno = e => {
        this.setState({
            showlayerout: false
        })
    }
    gologin = e => {
        let userid = localStorage.getItem('userid');

        if (!userid || userid <= 0) {
            let email = localStorage.getItem('email');
            let name = localStorage.getItem('name');
            let phone = localStorage.getItem('phone');
            let sourcechannel = localStorage.getItem('sourcechannel');
            localStorage.clear();
            localStorage.setItem('phone', phone);
            localStorage.setItem('name', name);
            localStorage.setItem('email', email);
            if (sourcechannel) {
                localStorage.setItem('sourcechannel', sourcechannel);
            }
            localStorage.setItem('refurl', window.location.href);

            if (this.props.typeids) {
                this.props.history.push('/loginlist?courseid=' + this.props.typeids)
            } else if (this.props.isbbs) {
                let bbsid = this.props.bbsid ? this.props.bbsid : '';
                let topicid = this.props.topicid ? this.props.topicid : '';
                if (bbsid) {
                    this.props.history.push('/loginlist?isbbs=1&bbsid=' + bbsid)
                } else if (topicid) {
                    this.props.history.push('/loginlist?isbbs=1&topicid=' + topicid)
                } else {
                    this.props.history.push('/loginlist?isbbs=1')
                }
            } else {
                this.props.history.push('./loginlist')
            }

            // this.props.history.push('./loginlist?')
        } else {
            this.props.history.push('./homeperson')
        }

    }
    // gologin = e => {


    //     let userid = localStorage.getItem('userid');

    //     if (!userid || userid <= 0) {
    //         let email = localStorage.getItem('email');
    //         let name = localStorage.getItem('name');
    //         let phone = localStorage.getItem('phone');
    //         let sourcechannel = localStorage.getItem('sourcechannel');
    //         localStorage.clear();
    //         localStorage.setItem('phone', phone);
    //         localStorage.setItem('name', name);
    //         localStorage.setItem('email', email);
    //         if(sourcechannel){
    //             localStorage.setItem('sourcechannel', sourcechannel);
    //         }
    //         localStorage.setItem('refurl', window.location.href);
    //         this.props.history.push('./loginlist')
    //     } else {
    //         this.setState({
    //             showlayerout: true
    //         })
    //     }


    //     //localStorage.clear();
    //     // this.props.history.push('./loginlist')
    // }
    selectLavel = (parent, id) => {
        let new_labellist = this.props.labellist;
        new_labellist[parent].list[id].active = !new_labellist[parent].list[id].active;
        this.props.change_labellist(new_labellist)
        if (!this.props.searchCollback) {
            this.props.history.push('./searchpage')
        } else {
            this.props.changeLabel(new_labellist);
        }
    }
    searchCollback = (text,type='', e) => {
        console.log(text,type, e)
        if (e && e.nativeEvent.keyCode !== 13) { return false; }
        let { inputSelectOption, inputSelectModeIndex } = this.state
        let mode = inputSelectOption[inputSelectModeIndex].id
        console.log(mode)
        if (mode === '1') {
            // 课程 原逻辑
            console.log(text)
            console.log(3333)
            this.props.change_common_search(text)
            if (!this.props.searchCollback) {
                if(type==1){
                    if(text){
                        this.props.history.push('./searchpage?isai=1')
                    }else{
                        this.props.history.push('./ai')
                    }
                }else{
                    if(text){
                        this.props.history.push('./searchpage?isai=1')
                    }else{
                        this.props.history.push('./ai')
                    }
                }
            } else {
                if(text){
                    this.props.searchCollback(text,type)
                }else{
                    this.props.history.push('./ai')
                }
            }
        } else if (mode === '2') {
            // 话题
            console.log(222)
            this.props.history.push(`./doctorQuery?type=talk&query=${text}`)
        } else if (mode === '3') {
            // 帖子
            console.log(3333)
            this.props.history.push(`./doctorQuery?type=post&query=${text}`)
        }
    }

    showlabel = () => {
        this.setState({
            showfalg: !this.state.showfalg
        })
    }

    change_twonav = (data, names) => {
        window.addreadlogs(names)
        console.log(data)
        let that = this
        if (names == '员工/BP内部课程') {
            localStorage.setItem('changetype', 0);
            if (this.props.ishomeplay) {
                that.props.changedatalist();
                that.props.headerchangeactive();
            }
        }
        if (names == '课程中心') {
            localStorage.setItem('changetype', 1);
            if (this.props.ishomeplay) {
                that.props.changedatalist();
                that.props.headerchangeactive();
            }
        }
        this.props.change_active_two(data)
    }

    componentDidMount() {
        console.log('添加监测代码')
        if(this.props.common_search){
            this.setState({
                searchText: this.props.common_search
            })
        }
        console.log(this.props)
        let query = utils.getUrlParams()
        console.log(query)
        if (query) {
            if (query.type == "talk") {
                this.setState({ inputSelectModeIndex: 1 })
            }
            if (query.type == "post") {
                this.setState({ inputSelectModeIndex: 2 })
            }
        }
        fetch(
            "https://shl-info.cn/front/bbs/userNoticenums/?type=1,2,3,4,5&user_id=" + localStorage.getItem('userid')
        )
            .then(data => {
                return data.json();
            })
            .then(res => {
                console.log(res)

                if (!localStorage.getItem('userid')) {
                    this.setState({
                        noticenums: 0,
                    })
                } else {
                    this.setState({
                        noticenums: res,
                    })
                }

            })
        // this.setState({ inputSelectModeIndex: index})
        if (localStorage.getItem('issignstate') == 1) {
            // this.setState({
            //     loginstate: true,
            // })
            this.getbbsinfo();
            // localStorage.setItem('issignstate', 0);
        } else {
            this.setState({
                specialstate: true,
            })
        }
        console.log(this.props)
        this.props.change_active(this.props.active)
        // 页面滚动到顶部位置
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        if (this.props.labellist.length < 1) {
            this.props.change_common_label()
        }
        let that = this
        let userid = (localStorage.getItem('userid'));
        let token = (localStorage.getItem('token'));
        if (!userid) {
            userid = '';
        }



        var typeids = this.props.typeids;
        if (!typeids) {
            typeids = '';
        }

        if (!userid) {
            if (!localStorage.getItem('visitor_num')) {
                let user_medical_num = "visitor_" + Math.floor(Math.random() * 10000000000);
                localStorage.setItem("visitor_num", user_medical_num)
                userid = user_medical_num
            } else {
                userid = localStorage.getItem('visitor_num')
            }
            this.setState({
                userstate: false,
            })
        }

        //添加监测代码
        fetch(
            "https://shl-info.cn/front/frontc/addlogs?userid=" +
            userid +
            "&urlstr=" +
            encodeURI(window.location.href) +
            "&title=" +
            document.title +
            "&keywords=" +
            '' +
            "&typeids=" +
            typeids +
            "&types=" +
            'read' +
            "&isspecial=" + localStorage.getItem('special') +
            "&sourcechannel=" + localStorage.getItem('sourcechannel')
        )


        console.log('添加监测代码222')

        if (userid && token) {
            // 验证是否过期
            // alert(111)

            $.ajax({
                type: "GET",
                url: "https://shl-info.cn/front/user/checkuser?userid=" +
                    userid
                //+
                // "&token=" +token
                ,
                dataType: "json",
                // beforeSend: function(XMLHttpRequest) {
                //     XMLHttpRequest.setRequestHeader("token",token);
                //     //XMLHttpRequest.setRequestHeader("X-Auth0-Token","Basic "+token);
                // },

                // beforeSend: function (XMLHttpRequest) {
                // 　　XMLHttpRequest.setRequestHeader("X-Auth0-Token","Basic "+token);
                // },
                headers: { 'token': token },

                success: function (res) {
                    console.log(res)
                    console.log(22222222222)
                    if (res.status == 2) {
                        localStorage.setItem('userid', '');
                        localStorage.setItem('token', '');
                    }
                    if (res.status === 1) {
                        localStorage.setItem("userid", userid);
                        localStorage.setItem("token", res.token);
                        localStorage.setItem("local", res.list.local);
                        localStorage.setItem('usertype', res.list.usertype)
                        localStorage.setItem('iswaitype', res.list.type)
                        localStorage.setItem('isdi', res.list.isdi)

                        //去获取是否有CS卡卷
                        that.getcscard();
                        console.log('获取卡卷')
                        if (res.list.type === 0 || res.list.type === 4) {
                            that.setState({
                                showcourselist: true,
                            })
                           
                        }
                        fetch(
                            "https://shl-info.cn/front/groupchat/checkHaveGroupSale?userid=" +
                            userid, {
                            headers: {
                                'token': token
                            }
                        }
                        )
                        .then(data => {
                            return data.json();
                        })
                        .then(resgroup => {
                            console.log(resgroup)
                            if(resgroup.data.havesalegroup==1){
                                that.setState({
                                    havegroup:true,
                                    groupinfo:resgroup.data.grouplist
                                })
                            }
                        })
                        if (res.list.type == 1 || res.list.type == 2 || res.list.type == 3 || res.list.type == 5) {
                            that.setState({
                                thisusertype: true,
                            })

                            //获取用户是否同意个性化推荐（千人千面）(外部用户)
                            fetch(
                                "https://shl-info.cn/front/Userrecommend/getUserRecommendLog?userid=" +
                                userid, {
                                headers: {
                                    'token': token
                                }
                            }
                            )
                                .then(data => {
                                    return data.json();
                                })
                                .then(recommd => {
                                    if (recommd.special == 2) {
                                        that.setState({
                                            canspecialstate: true
                                        })
                                    } else if (recommd.special == 1 && localStorage.getItem('special') != 1) {
                                        //localStorage.setItem('special',recommd.special)

                                        if (localStorage.getItem('special') == 11) {
                                            localStorage.setItem('special', 11)
                                        } else {
                                            localStorage.setItem('special', recommd.special)
                                        }

                                        if (that.props.ishomeplay) {
                                            that.props.changedatalist();
                                        }
                                    }

                                    localStorage.setItem('isopen', recommd.isopen)
                                    if (recommd.special != 1 && recommd.special != 11) {
                                        localStorage.setItem('special', 0)
                                    }

                                })


                        }
                        var userlist = res.list;
                        if (userlist.name) {
                            userlist.name = decrypt(userlist.name);
                        }
                        if (userlist.email) {
                            userlist.email = decrypt(userlist.email);
                        }
                        if (userlist.phone) {
                            userlist.phone = decrypt(userlist.phone);
                        }
                        res.list = userlist

                        that.props.change_userinfo({
                            token: res.token,
                            pic: res.pic,
                            userid: userid,
                            info: res.list
                        });




                    }
                }
            });
        } else {
            that.setState({
                thisusertype: true,
            })
        }


        this.setState({
            userid: userid,
        })
        if (userid && token) {
            fetch(
                "https://shl-info.cn/front/Userstudy/checkUserStudentType?userid=" + userid, {
                headers: {
                    'token': token
                }
            }
            )
                .then(data => {
                    return data.json();
                })
                .then(res => {
                    if (res.status != -1) {
                        this.setState({
                            study: true
                        })
                    }
                })
            //Userstudy/checkUserType
            fetch(
                "https://shl-info.cn/front/Userstudy/checkUserType?userid=" + userid, {
                headers: {
                    'token': token
                }
            }
            )
                .then(data => {
                    return data.json();
                })
                .then(res => {
                    console.log(res);
                    if (res && res.length > 0) {
                        this.setState({
                            studyadmin: true
                        })
                    }
                })
        }
    }

    getcscard = ()=>{
        console.log(11111122222)

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // 月份是从 0 开始计数的，因此要加1
        const day = currentDate.getDate();
        const noewdate = year.toString()+month.toString()+day.toString();
       
        let storday = localStorage.getItem("nowdate");
        console.log()
        if ( storday !=noewdate ) {
            fetch(
              "https://shl-info.cn/front/Cscardcode/getCardUserInfo?userid=" +
              localStorage.getItem('userid') ,{
                headers:{
                  'token':localStorage.getItem('token') 
                }
              }
            )
            .then(data => {
              return data.json();
            })
            .then(res => {
                console.log(res)
              if(res.data){
                this.setState({
                    showcslayer:true,
                    codeinfo:res.data
                  })
                  fetch(
                    "https://shl-info.cn/front/Cscourse/getCourseListtoseries?userid=" +
                    localStorage.getItem('userid')+"&ishopmaillindex=1" ,{
                      headers:{
                        'token':localStorage.getItem('token') 
                      }
                    }
                  )
                  .then(data => {
                    return data.json();
                  })
                  .then(resinfo => {
                    console.log(resinfo)
                    this.setState({
                      cslist:resinfo.data[0]['list'],
                      showcslayer:true,
                      codeinfo:res.data
                    })
                  })
              }
            })
          }
    }
    componentWillUnmount() {

    }
    gojumpdetail = () => {
        console.log(this.state.HomeTipsinfo)
        
        localStorage.setItem('issignstate', 0)
       
        fetch(
            "https://shl-info.cn//front/Hometips/setHomeTipsLog?userid=" + localStorage.getItem('userid') + "&tipsid=" + this.state.HomeTipsinfo.id
        )
            .then(data => {
                return data.json();
            })
            .then(res => {

            })
            let that = this
            this.setState({
                havegroup:false,
            },()=>{
                window.location.href = that.state.HomeTipsinfo.jumplink
            })
    }
    gotopic = () => {

        window.addreadlogs('医医互动点击前往-' + this.state.isbbsinfo.tipstype + '-' + this.state.isbbsinfo.articleid)
        fetch(
            "https://shl-info.cn/front/bbs/setTipsClickLog?userid=" + localStorage.getItem('userid') + "&clicktype=2&articletype=" + this.state.isbbsinfo.tipstype + "&articleid=" + this.state.isbbsinfo.articleid
        )
            .then(data => {
                return data.json();
            })
            .then(res => {
            })

        if (this.state.isbbsinfo.tipstype == 1) {
            this.props.history.push('/talkDetail?id=' + this.state.isbbsinfo.articleid)
        } else {
            this.props.history.push('/postDetail?id=' + this.state.isbbsinfo.articleid)
        }
    }
    
  goai = () =>{
    this.props.history.push('/ai')
  }

    //千人千面点击方法
    specialchange = (type) => {
        let userid = localStorage.getItem('userid')
        let token = localStorage.getItem('token')
        fetch(
            "https://shl-info.cn/front/Userrecommend/addUserRecommendLog?userid=" + userid + "&isspecial=" + type, {
            headers: {
                'token': token
            }
        }
        )
            .then(data => {
                return data.json();
            })
            .then(res => {
                this.setState({
                    canspecialstate: false,
                })
                if (type == 2) {
                    localStorage.setItem('special', 1)
                    if (this.props.ishomeplay) {
                        this.props.changedatalist();
                    }
                    //this.props.change_back_data(this.state.bulist)
                }
            })
    }

    closebbslayergroup = ()=>{
        localStorage.setItem('issignstate', 0)
        this.setState({
            havegroup:false,
        })
    }

    
    gojumpgroup = () => {
        console.log(this.state.groupinfo)
        
        localStorage.setItem('issignstate', 0)
        if(this.state.groupinfo.length==1){
            this.props.history.push('./groupDetailinfo?groupids='+this.state.groupinfo[0]['groupcode'])
        }else{
            this.props.history.push('./groupManipulat')
        }
        // window.location.href = this.state.HomeTipsinfo.jumplink
    }



    
changecslist = (item)=>{
    this.setState({
      csliselect:item.id,
      cslistinfo:item,
    })
  }
  closeuserlayercs = ()=>{
  
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 月份是从 0 开始计数的，因此要加1
    const day = currentDate.getDate();
    const noewdate = year.toString()+month.toString()+day.toString();
   
    localStorage.setItem("nowdate",noewdate);
  
  
    this.setState({
      showcslayer:false,
    })
  }
  gocheckcode = ()=>{
    console.log(this.state.cslistinfo,this.state.codeinfo)
    if(!this.state.csliselect){
      return false;
    }
    let that = this
    $.ajax({
      type: "POST",
      url:
        "https://shl-info.cn/front/Cscardcode/buyCourseForAdd",
        data: JSON.stringify({
          seriesid: that.state.cslistinfo.id,
          cardcode: that.state.codeinfo.cardcode,
          userid: localStorage.getItem('userid'),
        }),
      dataType: "json",
      
      headers:{'token':localStorage.getItem('token')},
      cache: false,
      success: function (res) {
        console.log(res)
        if(res.code==200){
  
          const currentDate = new Date();
          const year = currentDate.getFullYear();
          const month = currentDate.getMonth() + 1; // 月份是从 0 开始计数的，因此要加1
          const day = currentDate.getDate();
          const noewdate = year.toString()+month.toString()+day.toString();
         
          localStorage.setItem("nowdate",noewdate);
  
          
  
          that.setState({
            showcslayer:false,
            checkcodeover:true,
          })
        }
      }
    })
  }
  closejumpcourse = ()=>{
    this.setState({
        checkcodeover:false,
    })
  }
  jumpcourse = ()=>{
    this.props.history.push("/homeback?cscoursestate=1")
  }
  

    render() {
        // 列表渲染
        let listItems = this.props.labellist.map((item, index) => {
            let listli = item.list.map((list, listindex) => {
                return (
                    <li
                        key={listindex}
                        className={list.active ? 'active' : ''}
                        onClick={(e) => this.selectLavel(index, listindex, e)} >
                        {list.name}
                    </li>
                )
            })
            return (
                <div key={index}>
                    <h2 className="select-title">{item.name}</h2>
                    <ul className="label-list">
                        {listli}
                    </ul>
                </div>
            )
        })
        let label = null;
        if (this.state.showfalg) {
            label = (
                <div className="select-list">
                    <div className="wrap">
                        {listItems}
                    </div>
                </div>
            )

        }
        let masklayer = null;
        if (this.state.maskfalg || this.props.signstate) {
            if (this.state.thisusertype) {
                masklayer = null
            } else {
                var newnums = 0;
                if (this.propssignnums >= 2) {
                    newnums = 3;
                } else {
                    newnums = 2;
                }

                masklayer = <MaskBox
                    info={{
                        title: '签到成功',
                        text: '明天继续签到可获' + newnums + '分奖励',
                        textnew: '恭喜获得' + this.props.signscore + '分积分奖励',
                        type: 'sign',
                    }}
                    closeMask={this.closeMask}>
                    &gt;
                </MaskBox>;
            }
        }

        // if ((this.state.maskfalg || this.state.loginstate)) {
        //     // if (this.state.thisusertype) {
        //     //     masklayer = null
        //     // } else {

        //         masklayer = <MaskBox
        //             info={{
        //                 title: '登录完成',
        //                 text: '完成任务赚积分 赢好礼',
        //                 textnew: '',
        //                 type: 'signfirst',
        //             }}
        //             closeMask={()=>this.closeMask(1)}>
        //             &gt;
        //        </MaskBox>;
        //     //}
        // }


        let { inputSelectOption, inputSelectModeIndex, inputSelectModeOpen } = this.state
        let searchModeSelect = () => {
            let searchModeSelectItems = inputSelectOption.map((item, index) => {
                return <div className={classnames("search-mode-select-item", inputSelectModeIndex === index ? 'active' : '')} key={index} onClick={() => this.setState({ inputSelectModeIndex: index, inputSelectModeOpen: false })}>{item.name}</div>
            })
            return (
                <div className="search-mode-select">
                    { searchModeSelectItems}
                </div>
            )
        }

        let Speciallist = null
        if (this.state.specialstate && this.state.canspecialstate) {
            Speciallist = <Speciallayer specialchange={this.specialchange}></Speciallayer>
        }



        let bbslayer = null
        if (this.state.isbbslayer && !this.state.havegroup && !this.props.userlayer) {
            bbslayer = <Bbslayer HomeTipsinfo={this.state.HomeTipsinfo} gojumpdetail={this.gojumpdetail} closelayer={this.closebbslayer}></Bbslayer>
            // bbslayer = <Bbslayer isbbsinfo={this.state.isbbsinfo} gotopic={this.gotopic} closelayer={this.closebbslayer}></Bbslayer>
        }

        if ((this.state.maskfalg || this.state.loginstate)  && !this.state.havegroup  && !this.props.userlayer) {
            // if (this.state.thisusertype) {
            //     masklayer = null
            // } else {

            bbslayer = <Bbslayer HomeTipsinfo={this.state.HomeTipsinfo} gojumpdetail={this.gojumpdetail} closelayer={this.closebbslayer}></Bbslayer>

            //}
        }
        if(this.state.havegroup && localStorage.getItem('issignstate')==1  && !this.props.userlayer){
            bbslayer = <Grouplayer  groupinfolist={this.state.groupinfo} godetail={this.gojumpgroup} closelayer={this.closebbslayergroup}></Grouplayer>
        }
        console.log(this.state.havegroup,this.props.ishome)
        if(this.state.havegroup && this.props.ishome){
            bbslayer = <Grouplayer  groupinfolist={this.state.groupinfo} godetail={this.gojumpgroup} closelayer={this.closebbslayergroup}></Grouplayer>
        }
        
           



        let checkcodelist = "";
         console.log( this.state.showcslayer , this.state.issignstate , this.state.isbbslayer , this.state.havegroup)
        if(this.state.showcslayer  && !this.props.userlayer && !this.state.issignstate && !this.state.isbbslayer && (!this.state.havegroup  || localStorage.getItem('issignstate')==0)){
            checkcodelist = <Cslayer closeuserlayercs= {this.closeuserlayercs} changecslist={this.changecslist} gocheckcode={this.gocheckcode} cslist={this.state.cslist} csliselect={this.state.csliselect}></Cslayer>
        }

        let checkcodeover = "";
        if(this.state.checkcodeover){
            checkcodeover = <Cslayerover jumpcourse={this.jumpcourse} closejumpcourse={this.closejumpcourse} ></Cslayerover>
        
        }


        // masklayer = null;
        return (
            <header id="header">

{this.state.showcourselist && !this.props.isai?<div className="showaiicon" onClick={()=>this.goai()}><img src={require('../../static/images/ai/aigif.gif')} />
          <div className='showtips'></div>
        </div>:null}

        
                <div className={this.state.showlayerout ? "login_layer_is " : "login_layer_is none"}>
                    <div className='login_layer_is_box center'>
                        <h2 className="common-masktitle">
                            是否退出登录
                            {/* <span
                                className="common-maskclose cursor"
                            ></span> */}
                        </h2>
                        <div className='login_layer_is_btnlist'>
                            <span onClick={(e) => this.gologinyes()}>是</span>
                            <span onClick={(e) => this.gologinno()}>否</span>
                        </div>
                    </div>
                </div>
                {Speciallist}
                {masklayer}
                {bbslayer}
                {checkcodelist}
                {checkcodeover}
                <div className='headlogomedical' ><img src={require('../../static/images/logo.png')} alt="西影力学院" /></div>
                <div className="wrap">
                    <ul className="fl home-logo">
                        <li>
                            {/* <img src={require('../../static/images/logo.png')} alt="西影力学院" /> */}
                        </li>
                    </ul>
                    <ul className="fl navlist">
                        <li className={this.props.active === '0' ? 'active' : ''}>
                            <Link onClick={() => this.change_twonav('', '首页')} to="/home">首页</Link>
                        </li>
                        <li className={this.props.active === '1' && localStorage.getItem('changetype') == 1 ? 'active' : ''}>
                            <Link
                                onClick={() => this.change_twonav('project', '课程中心')}
                                to="/homeback">
                                课程中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'series' ? 'active' : null}
                                    onClick={() => this.change_twonav('series', '系列课程')}>
                                    <Link to="/homeback">系列课程</Link></li>
                                {!this.state.thisusertype ? (
                                    <li
                                        className={this.props.active_two === 'review' ? 'active' : null}
                                        onClick={() => this.change_twonav('review', '精彩回顾')}>
                                        <Link to="/homeback">精彩回顾</Link>
                                    </li>
                                ) : (
                                        <li
                                            className={this.props.active_two === 'review' ? 'active' : null}
                                            onClick={() => this.change_twonav('review', '精彩回顾')}>
                                            <Link to="/homeback">精彩回顾</Link>
                                        </li>
                                    )}
                                {!this.state.thisusertype ? (
                                    <li
                                        className={this.props.active_two === 'hotback' ? 'active' : null}
                                        onClick={() => this.change_twonav('hotback', '专家讲堂')}>
                                        <Link to="/homeback">专家讲堂</Link></li>
                                ) : (
                                        <li
                                            className={this.props.active_two === 'hotback' ? 'active' : null}
                                            onClick={() => this.change_twonav('hotback', '专家讲堂')}>
                                            <Link to="/homeback">专家讲堂</Link></li>
                                    )}


                                <li
                                    className={this.props.active_two === 'question' ? 'active' : null}
                                    onClick={() => this.change_twonav('question', '常见问题')}>
                                    <Link to="/homeback">常见问题</Link></li>
                            </ul>
                        </li>
                        <li className={this.props.active === '2' ? 'active' : ''}>
                            <Link
                                onClick={() => this.change_twonav('lasttime', '直播中心')}
                                to="/homeplaying">
                                直播中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'lasttime' ? 'active' : null}
                                    onClick={() => this.change_twonav('lasttime', '近期回放')}>
                                    <Link to="/homeplaying">近期回放</Link></li>
                                <li
                                    className={this.props.active_two === 'classfur' ? 'active' : null}
                                    onClick={() => this.change_twonav('classfur', '课程预告')}>
                                    <Link to="/homeplaying">课程预告</Link></li>
                            </ul>
                        </li>
                        <li className={this.props.active === '3' ? 'active' : ''}>
                            <Link
                                onClick={() => this.change_twonav('hotwatch', '文档中心')}
                                to="/homefile">
                                文档中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'hotwatch' ? 'active' : null}
                                    onClick={() => this.change_twonav('hotwatch', '最热查看')}>
                                    <Link to="/homefile">最热查看</Link></li>
                                <li
                                    className={this.props.active_two === 'typefile' ? 'active' : null}
                                    onClick={() => this.change_twonav('typefile', '资料分类')}>
                                    <Link to="/homefile">资料分类</Link></li>
                            </ul>
                        </li>
                        <li className={this.props.active === '6' ? 'active' : ''}>
                            <Link
                                onClick={() => this.change_twonav('posts', '互动专区')}
                                to="/homedoctorinteraction">
                                互动专区
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'posts' ? 'active' : null}
                                    onClick={() => this.change_twonav('posts', '帖子')}>
                                    <Link to="/homedoctorinteraction">帖子</Link></li>
                                <li
                                    className={this.props.active_two === 'talk' ? 'active' : null}
                                    onClick={() => this.change_twonav('talk', '话题')}>
                                    <Link to="/homedoctorinteraction">话题</Link></li>
                            </ul>
                        </li>

                        <li className={this.props.active === '5' ? 'active none' : 'none'}>
                            <Link onClick={() => this.change_twonav('', '互动专区')} to="/HomeDoctorInteraction">互动专区</Link>
                        </li>

                        {this.state.showcourselist ?
                            <li className={this.props.active === '1' && localStorage.getItem('changetype') != 1 ? 'active yellow' : 'yellow'}>
                                <Link onClick={() => this.change_twonav('project', '员工/BP内部课程')} to="/homeback">员工/BP内部课程</Link>
                            </li>
                            : null}

                        {/* {this.state.userid ?
                            (
                                <li className={this.props.active === '4' ? 'active' : ''}>
                                    <Link
                                        onClick={() => this.change_twonav('homeperson', '个人中心')}
                                        to="/homeperson">
                                        个人中心
                            </Link>
                                    <ul className="navlist-list">
                                        <li
                                            className={this.props.active_two === 'watchhistory' ? 'active' : null}
                                            onClick={() => this.change_twonav('watchhistory', '观看历史')}>
                                            <Link to="/watchhistory">观看历史</Link></li>

                                        <li
                                            className={this.props.active_two === 'learnrecord' ? 'active' : null}
                                            onClick={() => this.change_twonav('learnrecord', '学习记录')}>
                                            <Link to="/learnrecord">学习记录</Link></li>

                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'integralmall' ? 'active' : null}
                                                onClick={() => this.change_twonav('integralmall', '积分商城')}>
                                                <Link to="/integralmall">积分商城</Link></li>
                                        ) : null}
                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'certificate' ? 'active' : null}
                                                onClick={() => this.change_twonav('certificate', '我的证书')}>
                                                <Link to="/certificate">我的证书</Link></li>
                                        ) : null}
                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'downrecord' ? 'active' : null}
                                                onClick={() => this.change_twonav('downrecord', '下载记录')}>
                                                <Link to="/downrecord">下载记录</Link></li>
                                        ) : null}
                                        <li
                                            className={this.props.active_two === 'learnplan' ? 'active' : null}
                                            onClick={() => this.change_twonav('learnplan', '学习计划')}>
                                            <Link to="/learnplan">学习计划</Link></li>
                                        {!this.state.thisusertype ? (
                                            <li
                                                className={this.props.active_two === 'surver' ? 'active' : null}
                                                onClick={() => this.change_twonav('surver', '调研')}>
                                                <a href="https://jinshuju.net/f/fl3vd2">调研</a></li>
                                        ) : null}

                                        {this.state.study ? (
                                            <li
                                                className={this.props.active_two === 'learnplanuser' ? 'active' : null}
                                                onClick={() => this.change_twonav('learnplanuser', '学员计划')}>
                                                <Link to="/learningplan">学员计划</Link></li>
                                        ) : null}

                                        {this.state.studyadmin ? (
                                            <li
                                                className={this.props.active_two === 'learnplanadmin' ? 'active' : null}
                                                onClick={() => this.change_twonav('learnplanadmin', '作业批改')}>
                                                <Link to="/learningplanadminuserlist">作业批改</Link></li>
                                        ) : null}
                                    </ul>
                                </li>
                            )
                            : null} */}
                        {/* <li className={this.props.active === '4' ? 'active' : ''}>
                            <Link
                              onClick={() => this.change_twonav('homeperson')}
                              to="/homeperson">
                                  个人中心
                            </Link>
                            <ul className="navlist-list">
                                <li
                                    className={this.props.active_two === 'watchhistory' ? 'active' : null}
                                    onClick={() => this.change_twonav('watchhistory')}>
                                    <Link to="/watchhistory">观看历史</Link></li>
                                <li
                                    className={this.props.active_two === 'learnrecord' ? 'active' : null}
                                    onClick={() => this.change_twonav('learnrecord')}>
                                    <Link to="/learnrecord">学习记录</Link></li>
                                <li
                                    className={this.props.active_two === 'certificate' ? 'active' : null}
                                    onClick={() => this.change_twonav('certificate')}>
                                    <Link to="/certificate">我的证书</Link></li>
                                <li
                                    className={this.props.active_two === 'downrecord' ? 'active' : null}
                                    onClick={() => this.change_twonav('downrecord')}>
                                    <Link to="/downrecord">下载记录</Link></li>
                                <li
                                    className={this.props.active_two === 'learnplan' ? 'active' : null}
                                    onClick={() => this.change_twonav('learnplan')}>
                                    <Link to="/learnplan">学习计划</Link></li>
                                <li
                                    className={this.props.active_two === 'surver' ? 'active' : null}
                                    onClick={() => this.change_twonav('surver')}>
                                    <a href="https://jinshuju.net/f/fl3vd2">调研</a></li>
                                {this.state.study?(
                                    <li
                                        className={this.props.active_two === 'learnplanuser' ? 'active' : null}
                                        onClick={() => this.change_twonav('learnplanuser')}>
                                        <Link to="/learningplan">学员计划</Link></li>
                                ):null}

                                {this.state.studyadmin ? (
                                    <li
                                        className={this.props.active_two === 'learnplanadmin' ? 'active' : null}
                                        onClick={() => this.change_twonav('learnplanadmin')}>
                                        <Link to="/learningplanadminuserlist">作业批改</Link></li>
                                ) : null} */}

                        {/* <li
                                    className={this.props.active_two === 'collect' ? 'active' : null}
                                    onClick={() => this.change_twonav('collect')}>
                                    <Link to="/collect">我的收藏</Link></li> */}
                        {/* </ul>
                        </li> */}
                    </ul>
                    <div className="search-wrap fl">
                        <div className="search-input">
                            <div className="search-input-mode none" onClick={() => this.setState({ inputSelectModeOpen: true })}>
                                {inputSelectOption[inputSelectModeIndex].name}
                                <img src={require('../../static/images/select_arrow.png')} alt="" className="search-input-mode-icon" />
                            </div>
                            <input
                                id="search"
                                placeholder="搜索课程"
                                defaultValue={this.props.common_search}
                                // value={this.state.searchText?this.state.searchText:this.props.common_search}
                                onChange={this.searchchange}
                                onKeyPress={(e) => this.searchCollback(this.state.searchText,'', e)} />
                            <span
                                className="search-btn search-btn-course cursor"
                                onClick={() => this.searchCollback(this.state.searchText,'')}></span>
                              <span
                                className="search-btn search-btn-ai cursor"
                                onClick={() => this.searchCollback(this.state.searchText,1)}></span>
                        </div>
                        {inputSelectModeOpen ? searchModeSelect() : null}
                    </div>
                    <div
                        className={['labelicon cursor fl', this.state.showfalg ? 'active' : null].join(' ')}
                        onClick={this.showlabel}></div>
                    <div className="personal fr" onClick={(e) => this.gologin()}>
                        <img src={require('../../static/images/personalicon.png')} alt="" />
                        <span className="addusername">{this.props.userinfo.info.name}</span>
                        {/* <span className='addusershen moresamll'>{this.state.userstate?"身份切换":"登录/注册"} </span> */}
                        <span className='addusershen moresamll'>{this.state.userstate ? "已登录" : "未登录"} </span>
                    </div>
                    {/* 
                    <Link to="/userNotice" > */}
                    <div className="gomessage " onClick={() => this.props.history.push('./userNotice')}>
                        <span>{this.state.noticenums}</span>
                    </div>
                    {/* </Link> */}

                </div>
                {label}

            </header>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        labellist: state.common_labellist,
        active_two: state.active_two,
        common_search: state.common_search,
        userinfo: state.userinfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        change_active(data) {
            dispatch(change_active(data))
        },
        change_labellist(data) {
            dispatch(change_labellist(data))
        },
        change_userinfo(data) {
            dispatch(change_userinfo(data))
        },
        change_active_two(data) {
            dispatch(change_active_two(data))
        },
        change_common_search(data) {
            dispatch(change_common_search(data))
        },
        change_common_label() {
            dispatch(change_common_label())
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
