import React from 'react'
import { Link } from 'react-router-dom'

import './index.css'

function ClassList(props) {
    // console.log(props)
    let tips = null;
    if (props._item.tips) {
        tips = props._item.tips.map((item) => {
            return (
                <li key={item.id}>
                    {item.text}
                </li>
            )
        })
    }



    let thisoverstate = null;
    let xuestate = false;
    if (props._item.exam) {
        console.log(props._item.surverid)
        console.log(props._item.exam)
        // courseid: 2
        // isover: 0
        // ispass: 1
        // type: 2222


        if (props._item.surverid && props._item.exam.ispass == 1 && props._item.chaosheng_level >= 1) {
            xuestate = true;
        } else if (!props._item.surverid && props._item.exam.isover == 1 && props._item.chaosheng_level >= 1) {
            xuestate = true;
        } else {
            xuestate = false;
        }
        //     console.log(props._item.exam)
    }
    if (xuestate && localStorage.getItem('local') == 'BP Sale') {
        thisoverstate = (<li >
            <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
            已完成
        </li>)
    }
    var levelstate = null
    if (localStorage.getItem('local') == 'BP Sale') {
        if (props._item.chaosheng_level == 1) {
            levelstate = '一级课程';
        } else if (props._item.chaosheng_level == 2) {
            levelstate = '二级课程';
        } else if (props._item.chaosheng_level == 3) {
            levelstate = '三级课程';
        } else if (props._item.chaosheng_level == 4) {
            levelstate = '四级课程';
        }
    }
    var coursestateplay = null;
    if(props._item.isdi==1){
        if(props._item.islook==1){
            if(props._item.islookover==1){
                coursestateplay = (<li className='viewTwo'>
                    <img src={require('../../static/images/mall/icon_view_2.png')} alt="" />
                    已完成
                </li>)
            }else{
                
                coursestateplay = (<li className='viewOne'>
                    <img src={require('../../static/images/mall/icon_view_1.png')} alt="" />
                    已开始
                </li>)
            }
        }else{
            coursestateplay = (<li className='viewOne'>
                <img src={require('../../static/images/mall/icon_view_1.png')} alt="" />
                未观看
            </li>)
        }
    }

    
    var coursestateanswer = null;
    if(props._item.isdi==1){
        if(props._item.isanswer==1){
            if(props._item.isanswerscore==1 && props._item.islookover==1){
                coursestateanswer = (<li className='completeTwo'>
                    <img src={require('../../static/images/mall/icon_complete_2.png')} alt="" />
                    已通过
                </li>)
            }else{
                
                coursestateanswer = (<li className='completeOne'>
                    <img src={require('../../static/images/mall/icon_complete_1.png')} alt="" />
                    未通过
                </li>)
            }
        }else if(props._item.isanswer==2){
            coursestateanswer = null
        }else{
            coursestateanswer = (<li className='completeOne'>
                <img src={require('../../static/images/mall/icon_complete_1.png')} alt="" />
                未考试
            </li>)
        }
    }

    
    var cloudtalk = null;
    if(props._item.cloudtalk==1){
        cloudtalk = (<li className='completeOne'>
            <img src={require('../../static/images/mall/icon_cloud_classroom.png')} alt="" />
                云讲座
            </li>)
    }

    
    var cloudread = null;
    if(props._item.cloudread==1){
        cloudread = (<li className='completeOne'>
            <img src={require('../../static/images/mall/icon_cloud_reading.png')} alt="" />
                云读片
            </li>)
    }

    
    var csmini = null;
    if(props._item.csmini==1){
        csmini = (<li className='completeOne'>
            <img src={require('../../static/images/mall/icon_cs_micro_lecture.png')} alt="" />
                CS微课
            </li>)
    }
    let  GetDateTime = (time) =>{
        if (time >= 60) {
            let atime=time % 60;
            if(atime<10){
                atime="0"+atime;
            }
        time = parseInt(time / 60) + ":" + atime + "";
        }
        // else {
        // if (time > 3600) {
        // time = parseInt(time / 3600) + "小时" + parseInt(((time % 3600) / 60)) + "分" + time % 60 + "秒";
        // }
        else {
            let atime=time;
            if(atime<10){
                atime="0"+atime;
            }
            time = "00:"+atime + "";
        }
       //}
        return time;
    }

    thisoverstate = null;
    // console.log(props._item)
   // console.log(props.typeids)
    let thistime=0;
    if(props.iscs){
        thistime=GetDateTime(props._item.allvideotime);
    }
    // console.log(props.active_two)
    // console.log(props.type)
    
    return (
        <div className='classlist transform_hover'>
            {props.isiscourse?
            <div className='classlistboxinfo' onClick={()=>props.gonewcourse(props._item)}>
            <div className='classlist-img'>
                {props._item.isscorm==1?<span className="isscrom">交互式课程</span>:null}
                <img src={props._item.src} className='tranform_img' alt="" />
                {props.typeids==1 && props.active_two=='lasttime'?<span className="">观看回放</span>:null}
                {props._item.coursestatus?<span className={props._item.coursestatus?props._item.coursestatus==2?"green":props._item.coursestatus==3?props._item.videoid?"":'gary':'':''}>{props._item.coursestatus?props._item.coursestatus==1?"报名中":props._item.coursestatus==2?"直播中":props._item.coursestatus==3?props._item.videoid?"观看回放":'直播结束':null:null}</span>:null} 


               <b className="classlist-img-right">{props._item.nums}</b>
            </div>
            <h2 className="classlist-title">{props._item.title}</h2>
            <ul className="classlist-label">
               
                <li>
                    <img src={require('../../static/images/dateIcon.png')} alt="" />
                    {props._item.time}
                </li>
                <li>
                    <img src={require('../../static/images/peopleIcon.png')} alt="" />
                    {props._item.name}
                </li>
                
                {props.iscs?  <li>
                    <img src={require('../../static/images/playnumsicon.png')} alt="" />
                    {thistime}
                </li> : null}


                {props.iscs? <li className={props._item.coursepay==1?"coursepaymian":""}>
                    <img src={props._item.coursepay==1?require('../../static/images/healthineers_icon_payfree_2_wirte.png'):require('../../static/images/healthineers_icon_payfree_1.png')} alt="" />
                    {props._item.coursepay==1?"免费观看":"付费观看"}
                </li>:null}
                {props.iscs? <li>
                    <img src={props._item.islook==1?require('../../static/images/mall/icon_view_2.png'):require('../../static/images/mall/icon_view_1.png')} alt="" />
                    {props._item.islook==1?"通过":"未通过"}
                </li>:null}
               

               



                <li className={props._item.chaosheng_level >= 1 ? localStorage.getItem('local') == 'BP Sale' ? " none " : 'none' : "none"}>
                    <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                    {levelstate}
                </li>
                {thisoverstate}
                {tips}
            </ul>
            <ul className="classlist-label newlabel">
                {coursestateplay}
                {coursestateanswer}
                {csmini}
                {cloudread}
                {cloudtalk}
            </ul>
        </div>
            :
            <Link to={props._item.link}>
                <div className='classlist-img'>
                    {props._item.isscorm==1?<span className="isscrom">交互式课程</span>:null}
                    <img src={props._item.src} className='tranform_img' alt="" />
                    {/* <span className="classlist-img-left">111</span> */}
                    {props.typeids==1 && props.active_two=='lasttime'?<span className="">观看回放</span>:null}
                    {props._item.coursestatus?<span className={props._item.coursestatus?props._item.coursestatus==2?"green":props._item.coursestatus==3?props._item.videoid?"":'gary':'':''}>{props._item.coursestatus?props._item.coursestatus==1?"报名中":props._item.coursestatus==2?"直播中":props._item.coursestatus==3?props._item.videoid?"观看回放":'直播结束':null:null}</span>:null} 


                   {/* {props.islive?<b className="classlist-img-right">{props._item.pv}</b>:""} */}
                   <b className="classlist-img-right">{props._item.nums}</b>
                </div>
                <h2 className="classlist-title">{props._item.title}</h2>
                <ul className="classlist-label">
                   
                    <li>
                        <img src={require('../../static/images/dateIcon.png')} alt="" />
                        {props._item.time}
                    </li>
                    <li>
                        <img src={require('../../static/images/peopleIcon.png')} alt="" />
                        {props._item.name}
                    </li>
                    
                    {props.iscs?  <li>
                        <img src={require('../../static/images/playnumsicon.png')} alt="" />
                        {thistime}
                    </li> : null}
                    {/* {props.iscs?  <li>
                        <img src={require('../../static/images/playnumsicon.png')} alt="" />
                        {thistime}
                    </li> : <li>
                   <img src={require('../../static/images/playnumsicon.png')} alt="" />
                   {props.islive && props.active_two=="classfur" ?props._item.signnum:props._item.nums}

               </li>} */}



                    {/* CS微课标签 */}
                    {props.iscs? <li className={props._item.coursepay==1?"coursepaymian":""}>
                        <img src={props._item.coursepay==1?require('../../static/images/healthineers_icon_payfree_2_wirte.png'):require('../../static/images/healthineers_icon_payfree_1.png')} alt="" />
                        {props._item.coursepay==1?"免费观看":"付费观看"}
                    </li>:null}
                    {props.iscs? <li>
                        <img src={props._item.islook==1?require('../../static/images/mall/icon_view_2.png'):require('../../static/images/mall/icon_view_1.png')} alt="" />
                        {props._item.islook==1?"通过":"未通过"}
                    </li>:null}
                   

                   



                    <li className={props._item.chaosheng_level >= 1 ? localStorage.getItem('local') == 'BP Sale' ? " none " : 'none' : "none"}>
                        <img src={require('../../static/images/mall/mall_rank_icon2.png')} alt="" />
                        {levelstate}
                    </li>
                    {thisoverstate}
                    {tips}
                </ul>
                <ul className="classlist-label newlabel">
                    {coursestateplay}
                    {coursestateanswer}
                    {csmini}
                    {cloudread}
                    {cloudtalk}
                </ul>
            </Link>
            }
        </div>
    )
}

export default ClassList
